import React, { useEffect, useState } from 'react'
import { Text } from '@components'
import { Card, Col, Dropdown, Row, Spin, Tooltip } from 'antd'
import verticalDivider from '@images/AdsPreview/verticalDivider.png'
import { bucketBaseURL } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADS_SEARCH_KEYWORD,
  INPUT_VALUES,
  EMPTY_ADS_PREVIEW_DATA,
  TRIGGER_SEARCH,
} from '@utils/constants'
import dayjs from 'dayjs'
import GoogleIcon from '@images/DiscoverAds/SearchBar/GoogleIcon.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import CopyTextIcon from '@images/DiscoverAds/ButtonIcons/CopyText.png'
import CopyTextIconOrange from '@images/DiscoverAds/ButtonIcons/copyTextOrange.svg'
import activeIcon from '@images/AdsPreview/activeIcon.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import AddIcon from '@images/DiscoverAds/ButtonIcons/add.png'
import AddIconOrange from '@images/DiscoverAds/ButtonIcons/addOrange.svg'
import ShareIcon from '@images/DiscoverAds/ButtonIcons/share.png'
import ShareIconOrange from '@images/DiscoverAds/ButtonIcons/shareOrange.svg'
import DownloadIcon from '@images/DiscoverAds/ButtonIcons/Download.png'
import DownloadIconOrange from '@images/DiscoverAds/ButtonIcons/DownloadOrange.svg'
import SavedAdIcon from '@images/DiscoverAds/ButtonIcons/SavedAd.png'
import SavedAdIconOrange from '@images/DiscoverAds/ButtonIcons/SavedAdOrange.svg'
import CreateYourAccount from '@components/CreateYourAccount'
import AdsModal from '@components/UnauthenticatedDiscoverAds/AdsModal'
import accountsTracking from '@services/accountsTracking'
import styles from './adsCard.module.css'

const AdsCard = ({ adsData, selectedData, onShowModal, detailPopupCount, setDetailPopupCount }) => {
  const [sourceName, setSourceName] = useState()
  const [eventName, setEventName] = useState()
  const [pageName, setPageName] = useState()
  const dispatch = useDispatch()
  const [signUpModal, setSignUpModal] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  const selectedChannel = useSelector(state => state?.UnAuthDiscoverAds?.channels)
  const [visible, setVisible] = useState(false)
  const handleVisibleChange = flag => {
    setVisible(flag)
  }
  const showModal = () => {
    setDetailPopupCount(detailPopupCount + 1)
    setIsModalOpen(true)
    onShowModal()
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const saveOptionMenu = <></>

  const moreOptionsMenu = <></>

  const handleOpenModal = () => {
    setSignUpModal(true)
  }

  const handleCloseModal = () => {
    if (currentPath !== '/discover-ads-landing-page') {
      dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
      dispatch({ type: INPUT_VALUES, payload: '' })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    }
    setSignUpModal(false)
  }
  const handleSignUpModal = () => {
    dispatch({ type: TRIGGER_SEARCH, payload: true })
    setSignUpModal(true)
  }
  useEffect(() => {
    setCurrentPath(window.location.pathname)
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <Card
        className={styles.card}
        hoverable
        // eslint-disable-next-line
        onClick={
          detailPopupCount <= 1
            ? () => {
                showModal()
                setSourceName('Clicked Un-Auth Ads Detail Card')
                setPageName('Discover Ads Landing Page')
                setEventName('Sign In Page View')
              }
            : () => {
                handleSignUpModal()
                setSourceName('Clicked Un-Auth Ads Detail Card')
                setPageName('Discover Ads Landing Page')
                setEventName('Sign In Page View')
              }
        }
      >
        {adsData?.updated_at &&
          (() => {
            const updatedAt = new Date(adsData.additional_info.ad_creation_time)
            const currentTime = new Date()
            const diffInHours = (currentTime - updatedAt) / (1000 * 60 * 60)

            return diffInHours < 48 ? <div className={styles.ribbon}>New</div> : null
          })()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F7F8F9',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 8,
              marginLeft: 16,
              height: 48,
              alignItems: 'center',
            }}
          >
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <div style={{ marginTop: -5 }}>
                  <img src={MetaIcon} alt="meta-icon" width="100%" height={16} />
                </div>
              ) : adsData?.origin?.provider === 'google' ? (
                <div style={{ marginTop: -5 }}>
                  <img src={GoogleIcon} alt="google-icon" width={16} />
                </div>
              ) : (
                ''
              )
            }
            <img src={verticalDivider} height={12} alt="divider" />
            {adsData?.additional_info?.ad_creation_time ? (
              <>
                <span className={styles.dateText}>
                  {dayjs(adsData?.additional_info?.ad_creation_time).format('MMM D, YYYY')}
                </span>
              </>
            ) : (
              ''
            )}
            <img src={verticalDivider} height={12} alt="divider" />
            {
              // eslint-disable-next-line
              adsData?.additional_info?.is_active === 'false' ||
              adsData?.additional_info?.is_active === false ? (
                ''
              ) : adsData?.additional_info?.is_active === 'true' ||
                adsData?.additional_info?.is_active === true ? (
                <img src={activeIcon} className={styles.profileIcons} alt="active-icon-pic" />
              ) : (
                ''
              )
            }
            <span className={styles.dateText}>
              {
                // eslint-disable-next-line
                adsData?.additional_info?.is_active === 'true' ||
                adsData?.additional_info?.is_active === true
                  ? 'Active'
                  : adsData?.additional_info?.is_active === 'false' ||
                    adsData?.additional_info?.is_active === false
                  ? 'Ended'
                  : ''
              }
            </span>
          </div>
        </div>

        <div className={styles.profileContainer}>
          {/* eslint-disable-next-line */}
          <div className={styles.profile}>
            {localDate === adsDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <div>
                    <img
                      src={adsData?.additional_info?.page_profile_picture_url}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <div>
                    <img
                      src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            )}

            <div>
              <Text isBold size={16}>
                {adsData?.additional_info?.page_name}
              </Text>
            </div>
          </div>

          <div
            // eslint-disable-next-line
            id={`ads-card-more-options-${adsData._id}`}
          >
            <button
              className={styles.followButton}
              type="button"
              onClick={e => {
                e.stopPropagation()
                const trackingPayload = {
                  eventName: 'Follow Brand Click',
                  pageName: 'Discover Ads Landing Page',
                  userStatus: 'unlogged',
                  sourceName: 'Clicked Follow Button',
                  currentTab: selectedChannel,
                  brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
                }
                accountsTracking(trackingPayload)
                handleSignUpModal()
                setSourceName('Clicked Un-Auth Ads Card Follow Button')
                setPageName('Discover Ads Landing Page')
                setEventName('Sign In Page View')
              }}
            >
              <Text className={styles.addToBoard}>Follow</Text>
            </button>
          </div>
        </div>
        {((Array.isArray(adsData?.title) && adsData.title[0]?.length > 1) ||
          (typeof adsData?.title === 'string' && adsData.title.length > 1)) && (
          <Text
            style={{
              marginTop: 16,
              color: '#67696B',
              fontSize: 12,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
            }}
          >
            {Array.isArray(adsData?.title) ? adsData.title[0] : adsData.title}
          </Text>
        )}
        {localDate === adsDate ? (
          <>
            {
              // eslint-disable-next-line
              selectedChannel === 'Meta' || adsData?.origin?.provider === 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <video
                          style={{ width: '100%', height: '300px' }}
                          src={adsData?.video_url}
                          onPlay={e => e.preventDefault()} // Prevents playing
                        >
                          <track
                            kind="captions"
                            srcLang="en"
                            src="captions_en.vtt"
                            label="English captions"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' || adsData?.origin?.provider !== 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        ) : (
          <>
            {
              // eslint-disable-next-line
              selectedChannel === 'Meta' || adsData?.origin?.provider === 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer}>
                  {
                    // eslint-disable-next-line
                    adsData?.ads_grader_image_url ? (
                      <img
                        src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.ads_grader_video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' || adsData?.origin?.provider !== 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        )}

        {/* <div className={styles.buttonContainer} id="area"> */}
        <Row style={{ marginTop: 20 }} id="area">
          <Col
            xs={24} // Full width for small screens
            lg={12} // Half width for large screens (900px - 1400px)
            xxl={12}
          >
            <Dropdown
              onClick={e => {
                e.stopPropagation()
                const trackingPayload = {
                  eventName: 'Add to Board Click',
                  pageName: 'Discover Ads Landing Page',
                  userStatus: 'Free',
                  sourceName: 'Clicked Un-Auth Add to board Button',
                  currentTab: selectedChannel,
                  brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
                  hasBoards: false,
                }
                accountsTracking(trackingPayload)
                handleSignUpModal()
                setSourceName('Clicked Un-Auth Add to board Button')
                setPageName('Discover Ads Landing Page')
                setEventName('Sign In Page View')
              }}
              overlay={saveOptionMenu}
              trigger={['click']}
              overlayStyle={{ width: 'max-content', maxWidth: '240px', zIndex: 1 }}
            >
              <button className={styles.saveButton} type="button">
                <img src={AddIcon} alt="add-icon" className={styles.copyIcon} />
                <img src={AddIconOrange} alt="add-icon" className={styles.copyIconOrange} />
                <Text className={styles.addToBoard}>Add to board</Text>
              </button>
            </Dropdown>
          </Col>
          {/* <div
            // eslint-disable-next-line
            id={`adsCardTooltip-${adsData?.ad_id}`}
          > */}
          <Col
            xs={24}
            lg={3}
            xxl={3}
            // eslint-disable-next-line
            id={`adsCardTooltip-${adsData?.ad_id}`}
          >
            <Tooltip
              title="View ad"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData.ad_id}`)}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  handleSignUpModal()
                  setSourceName('Clicked Un-Auth View Ad Button')
                  setPageName('Discover Ads Landing Page')
                  setEventName('Sign In Page View')
                }}
              >
                <img src={ShareIcon} alt="share-icon" className={styles.copyIcon} />
                <img
                  src={ShareIconOrange}
                  alt="share-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            <Tooltip
              title="Copy script"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData.ad_id}`)}
              placement="top"
            >
              {/* eslint-disable-next-line */}
              <button
                className={styles.addButton}
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  handleSignUpModal()
                  setSourceName('Clicked Un-Auth Copy Script  Button')
                  setPageName('Discover Ads Landing Page')
                  setEventName('Sign In Page View')
                }}
                id="first"
              >
                <img src={CopyTextIcon} alt="copy-icon" className={styles.copyIcon} />
                <img
                  src={CopyTextIconOrange}
                  alt="copy-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            <Tooltip
              title="Download Thumbnail"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData?.ad_id}`)}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  handleSignUpModal()
                  handleSignUpModal()
                  const trackingPayload = {
                    eventName: 'Download Click',
                    pageName: 'Discover Ads Landing Page',
                    userStatus: 'unlogged',
                    sourceName: 'Clicked Download Button',
                    currentTab: 'All',
                    fileFormat: '.png,.txt',
                  }
                  accountsTracking(trackingPayload)
                  setSourceName('Clicked Un-Auth Download Thumbnail Button')
                  setPageName('Discover Ads Landing Page')
                  setEventName('Sign In Page View')
                }}
                id="first"
              >
                <img src={DownloadIcon} alt="download-icon" className={styles.copyIcon} />
                <img
                  src={DownloadIconOrange}
                  alt="download-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            <button
              type="button"
              className={styles.addButton}
              onClick={e => {
                e.stopPropagation()
                handleSignUpModal()
                setSourceName('Clicked Un-Auth Save Ad Button')
                setPageName('Discover Ads Landing Page')
                setEventName('Sign In Page View')
                const tracking = {
                  eventName: 'Save Ad Success',
                  pageName: 'Discover Ads Landing Page',
                  userStatus: 'unlogged',
                  sourceName: 'Clicked Un-Auth Save Ad Button',
                  currentTab: 'All',
                  brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
                }
                accountsTracking(tracking)
              }}
            >
              <Tooltip
                title="Save Ad"
                color="rgb(32, 32, 32)"
                getPopupContainer={() =>
                  // eslint-disable-next-line
                  document.getElementById(`adsCardTooltip-${adsData?.ad_id}`)
                }
                placement="top"
              >
                <img src={SavedAdIcon} alt="saved-icon" className={styles.copyIcon} />
                <img
                  src={SavedAdIconOrange}
                  alt="saved-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </Tooltip>
            </button>
          </Col>
          {/* </div> */}
        </Row>
        {/* </div> */}
      </Card>
      {isModalOpen && selectedData && (
        <AdsModal
          setSourceName={setSourceName}
          setPageName={setPageName}
          setEventName={setEventName}
          adsData={selectedData}
          modalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleSignUpModal={handleSignUpModal}
        />
      )}
      {signUpModal ? (
        <CreateYourAccount
          isModalVisible={signUpModal}
          onCancel={handleCloseModal}
          sourceName={sourceName}
          pageName={pageName}
          eventName={eventName}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default AdsCard
