import React, { useEffect, useState, useMemo, memo } from 'react'
import { Card, Skeleton, message, Empty, Row, Col } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import copyIcon from '@images/AdsPreview/InsightsCopyOrange.svg'
import { RequestService } from '@services/requests'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { useSelector, useDispatch } from 'react-redux'
import Text from '@components/Text/text'
import { AD_COPY_DATA, FILTERS_CLICK_FLAG } from '@utils/constants'
import { getYAxisConfig } from './yAxisIntervals'
import styles from './styles.module.css'

const AdCopy = memo(() => {
  const dispatch = useDispatch()
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const adCopies = useSelector(state => state?.discoverAds?.adCopyData)
  const [loading, setLoading] = useState(false)
  const selectedDescriptions = useSelector(state => state?.discoverAds?.selectedDescriptions || [])

  // Calculate total count from all descriptions

  // eslint-disable-next-line
  const totalCount = useMemo(() => {
    return adCopies?.descriptions?.reduce((max, copy) => Math.max(max, copy.count || 0), 0) || 0
  }, [adCopies?.descriptions])

  // Create a map to store unique ad copies and assign colors
  const uniqueAdCopies = useMemo(() => {
    if (!adCopies?.weeklyData) return new Set()

    // First, calculate total counts for each ad copy
    const totalCounts = new Map()
    adCopies.weeklyData.forEach(weekItem => {
      if (weekItem.data) {
        Object.values(weekItem.data).forEach(item => {
          // Handle both string and array values
          if (item?.value && item?.count) {
            const value = Array.isArray(item.value) ? item.value[0] : item.value
            if (value) {
              totalCounts.set(value, (totalCounts.get(value) || 0) + item.count)
            }
          }
        })
      }
    })

    // Sort ad copies by count and get top entries
    const sortedAdCopies = Array.from(totalCounts.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 30)
      .map(([adCopy]) => adCopy)

    return new Set(sortedAdCopies)
  }, [adCopies?.weeklyData])

  // Assign colors to ad copies
  const colors = [
    '#eb7953', // Pure Red
    '#00FF00', // Pure Green
    '#0000FF', // Pure Blue
    '#FF4500', // Orange Red
    '#8B008B', // Dark Magenta
    '#FFD700', // Gold
    '#00CED1', // Dark Turquoise
    '#FF1493', // Deep Pink
    '#32CD32', // Lime Green
    '#BA55D3', // Medium Orchid
    '#FF8C00', // Dark Orange
    '#4169E1', // Royal Blue
    '#2E8B57', // Sea Green
    '#DC143C', // Crimson
    '#9370DB', // Medium Purple
    '#20B2AA', // Light Sea Green
    '#CD853F', // Peru
    '#6495ED', // Cornflower Blue
    '#DB7093', // Pale Violet Red
    '#556B2F', // Dark Olive Green
    '#FF69B4', // Hot Pink
    '#4B0082', // Indigo
    '#8FBC8F', // Dark Sea Green
    '#BC8F8F', // Rosy Brown
    '#DAA520', // Goldenrod
    '#008080', // Teal
    '#800000', // Maroon
    '#9932CC', // Dark Orchid
    '#F4A460', // Sandy Brown
    '#2F4F4F', // Dark Slate Gray
  ]
  const CONSTANT_GRAY = '#A9A9A9' // Color for entries beyond 30
  const adCopyColors = useMemo(() => {
    const colorMap = new Map()
    Array.from(uniqueAdCopies).forEach((adCopy, index) => {
      colorMap.set(adCopy, colors[index])
    })
    colorMap.set('Others', '#f16434') // Color for "Others"
    return colorMap
    // eslint-disable-next-line
  }, [uniqueAdCopies])

  const handleCopy = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard')
      })
      .catch(() => {
        message.error('Failed to copy')
      })
  }

  const handleCheckboxChange = (description, checked) => {
    let newSelectedDescriptions
    if (checked) {
      newSelectedDescriptions = [...selectedDescriptions, description]
    } else {
      newSelectedDescriptions = selectedDescriptions.filter(desc => desc !== description)
    }
    dispatch({ type: 'SET_SELECTED_DESCRIPTIONS', payload: newSelectedDescriptions })
    if (newSelectedDescriptions?.length === 0) {
      dispatch({ type: FILTERS_CLICK_FLAG, payload: true })
      // You can also dispatch another action or call a function here
    }
  }

  const getData = async () => {
    if (!specificBrandDetail?.pageId && !specificBrandDetail?.googleId) return

    try {
      setLoading(true)
      const res = await RequestService.get(
        `save/meta-ads-description/?meta_page_id=${specificBrandDetail?.pageId}&google_advertiser_id=${specificBrandDetail?.googleId}`
      )

      dispatch({
        type: AD_COPY_DATA,
        payload: {
          descriptions: res?.data?.result?.data?.json || [],
          weeklyData: res?.data?.result?.data?.weekly_data || [],
          years: res?.data?.result?.data?.years || [],
        },
      })
    } catch (error) {
      message.error('Failed to fetch ad copies data')
      console.error('Error fetching ad copies:', error)
    } finally {
      setLoading(false)
    }
  }

  const processWeeklyData = weeklyData => {
    if (!weeklyData || weeklyData.length === 0) return []

    // Sort data by year and week in descending order
    const sortedData = [...weeklyData].sort((a, b) => {
      if (b.year !== a.year) return b.year - a.year
      return b.week - a.week
    })

    // Convert week numbers to month data
    const monthlyData = new Map()

    sortedData.forEach(weekItem => {
      const { year, week, data } = weekItem
      if (!data) return

      // For week 0, use January 1st of that year
      // For other weeks, calculate the date properly
      const date = new Date(year, 0, 1)
      if (week > 0) {
        date.setDate(date.getDate() + (week - 1) * 7)
      }

      const month = date.toLocaleString('default', { month: 'short' })
      const actualYear = date.getFullYear()
      const monthYearKey = `${month}-${actualYear}`

      // Initialize or update monthly counts
      if (!monthlyData.has(monthYearKey)) {
        monthlyData.set(monthYearKey, {
          month,
          year: actualYear,
          monthLabel: `${month} ${actualYear}`,
          counts: new Map(),
        })
      }

      // Aggregate data for this week into monthly totals
      Object.values(data).forEach(item => {
        if (item?.count) {
          const value = Array.isArray(item.value) ? item.value[0] : item.value
          if (value) {
            const currentMonth = monthlyData.get(monthYearKey)
            currentMonth.counts.set(value, (currentMonth.counts.get(value) || 0) + item.count)
          }
        }
      })
    })

    // Convert to array format and sort by date (oldest to newest)
    return Array.from(monthlyData.entries())
      .sort((a, b) => {
        if (a[1].year !== b[1].year) return a[1].year - b[1].year
        const monthA = new Date(Date.parse(`${a[1].month} 1, ${a[1].year}`))
        const monthB = new Date(Date.parse(`${b[1].month} 1, ${b[1].year}`))
        return monthA - monthB
      })
      .map(([_, monthData]) => ({
        month: monthData.month,
        monthLabel: monthData.monthLabel,
        year: monthData.year,
        ...Object.fromEntries(monthData.counts),
      }))
  }

  // Transform weekly data for the graph
  // eslint-disable-next-line
  const graphData = useMemo(() => {
    return processWeeklyData(adCopies?.weeklyData || [])
  }, [adCopies?.weeklyData])

  // Calculate max value and determine Y-axis scale
  // eslint-disable-next-line
  const monthlySums = useMemo(() => {
    // eslint-disable-next-line
    return graphData.map(monthData => {
      const monthTotal = Object.keys(monthData)
        .filter(key => key !== 'month' && key !== 'monthLabel' && key !== 'year') // Exclude labels
        .reduce((monthSum, key) => monthSum + (monthData[key] || 0), 0) // Sum all counts in the month

      return {
        month: monthData.monthLabel,
        total: monthTotal,
      }
    })
  }, [graphData])
  const highestMonth = useMemo(() => {
    if (monthlySums.length === 0) return null
    return monthlySums.reduce(
      (maxMonth, currentMonth) => (currentMonth.total > maxMonth.total ? currentMonth : maxMonth),
      monthlySums[0]
    ) // Start with the first month as initial max
  }, [monthlySums])
  const yAxisConfig = useMemo(() => {
    if (!graphData.length) return getYAxisConfig(0)
    return getYAxisConfig(highestMonth?.total)
  }, [graphData?.length, highestMonth?.total])

  useEffect(() => {
    if (
      adCopies?.descriptions?.length <= 0 &&
      adCopies?.years?.length <= 0 &&
      adCopies?.weeklyData?.length <= 0
    ) {
      getData()
    }
    // eslint-disable-next-line
  }, [adCopies])

  if (loading) {
    return (
      <div className={styles.container}>
        <div style={{ padding: '20px' }}>
          <Skeleton active paragraph={{ rows: 6 }} />
        </div>
      </div>
    )
  }

  if (!loading && !adCopies?.descriptions?.length && !adCopies?.weeklyData?.length) {
    return (
      <div className={styles.container}>
        <Card>
          <Empty
            description={`No data found for ${specificBrandDetail?.pageName || 'this page'}`}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: '40px 0' }}
          />
        </Card>
      </div>
    )
  }
  const processAdCopies = data => {
    if (!Array.isArray(data?.descriptions)) return []

    const mergedCopies = data?.descriptions?.reduce((acc, copy) => {
      const exactDescription = Array.isArray(copy.description)
        ? copy.description[0]?.trim()
        : copy.description?.trim()

      if (!exactDescription) return acc // Ignore empty or null descriptions

      if (!acc.has(exactDescription)) {
        acc.set(exactDescription, { ...copy, count: copy.count || 0 })
      } else {
        acc.get(exactDescription).count += copy.count || 0
      }

      return acc
    }, new Map())

    return Array.from(mergedCopies.values()).sort((a, b) => (b.count || 0) - (a.count || 0))
  }

  const processedAdCopies = processAdCopies(adCopies)

  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={12}>
        <Col span={12}>
          <Card className={styles.adCopyCard}>
            <div style={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={graphData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 10,
                  }}
                  barSize={15}
                  layout="horizontal"
                  reverseStackOrder={false}
                >
                  <CartesianGrid vertical={false} stroke="#E5E5E5" />
                  <XAxis
                    dataKey="monthLabel"
                    axisLine={false}
                    tickLine={false}
                    tick={{
                      fill: '#666666',
                      fontSize: 12,
                      angle: 45,
                      textAnchor: 'start',
                      dy: 8,
                      dx: 8,
                    }}
                    padding={{ left: 10, right: 10 }}
                    height={100}
                    tickMargin={5}
                    interval={0}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#666666', fontSize: 12 }}
                    width={60}
                  />
                  <Tooltip
                    cursor={{ fill: 'transparent' }}
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        const hasData = payload.some(entry => entry.value > 0)
                        if (!hasData) return null

                        const sortedPayload = [...payload]
                          .filter(entry => entry.value > 0)
                          .sort((a, b) => b.value - a.value)

                        return (
                          <div
                            style={{
                              padding: '12px',
                              background: 'white',
                              border: '1px solid #e8e8e8',
                              borderRadius: '4px',
                              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                              minWidth: '200px',
                              maxWidth: '300px',
                            }}
                          >
                            <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                              {sortedPayload.slice(0, 10).map((entry, index) => (
                                <div
                                  key={entry.dataKey}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom:
                                      index < Math.min(sortedPayload.length, 10) - 1 ? '8px' : '0',
                                    fontSize: '13px',
                                    color: '#333',
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: '1 1 auto',
                                      paddingRight: '16px',
                                      color: entry.color,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '200px',
                                    }}
                                    title={entry.dataKey}
                                  >
                                    {entry.dataKey}
                                  </div>
                                  <div
                                    style={{
                                      flex: '0 0 auto',
                                      minWidth: '40px',
                                      textAlign: 'right',
                                      color: entry.color,
                                      fontWeight: '500',
                                    }}
                                  >
                                    {entry.value}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      }
                      return null
                    }}
                  />
                  {Array.from(uniqueAdCopies).map((adCopy, index) => (
                    <Bar
                      key={adCopy}
                      yAxisId="right"
                      dataKey={adCopy}
                      stackId="stack"
                      fill={adCopyColors.get(adCopy)}
                      radius={[0, 0, 0, 0]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card className={styles.adCopyCard}>
            <div style={{ height: 400 }}>
              <div className={styles.adCopyList}>
                {processedAdCopies.map((copy, index) => (
                  // eslint-disable-next-line
                  <div
                    key={copy.uuid}
                    className={styles.adCopyItem}
                    onClick={e => {
                      if (
                        !e.target.closest(`.${styles.adCopy}`) &&
                        !e.target.closest(`.${styles.copyButton}`)
                      ) {
                        handleCheckboxChange(
                          copy.description,
                          !selectedDescriptions.includes(copy.description)
                        )
                      }
                    }}
                  >
                    <div className={styles.adCopyHeader}>
                      <div className={styles.checkboxContainer}>
                        <div className={styles.checkbox}>
                          <input
                            type="checkbox"
                            checked={selectedDescriptions.includes(copy.description)}
                            onChange={e => handleCheckboxChange(copy.description, e.target.checked)}
                            onClick={e => e.stopPropagation()}
                          />
                        </div>
                        <div
                          className={styles.colorDot}
                          style={{
                            backgroundColor: index < 30 ? colors[index] : CONSTANT_GRAY,
                          }}
                        />
                      </div>
                      <div className={styles.adCopyContainer}>
                        <Text
                          className={styles.adCopy}
                          onClick={() => handleCopy(copy.description)}
                        >
                          {copy.description}
                        </Text>
                        <button
                          type="button"
                          className={styles.copyButton}
                          onClick={e => {
                            e.stopPropagation() // Prevent checkbox toggle
                            handleCopy(copy.description)
                          }}
                        >
                          <img src={copyIcon} alt="copy" className={styles.copyIcon} />
                        </button>
                      </div>
                      <div className={styles.rightSection}>
                        <div className={styles.count}>{copy?.count?.toLocaleString()}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

AdCopy.displayName = 'AdCopy'

export default AdCopy
