import React, { useState, useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { notify } from '@utils/notify'
import axios from 'axios'
import { useRollbar } from '@rollbar/react'
import { SecretService } from '@services/secret'
import { axiosInstance, RequestService } from '@services/requests'
import { updateLocalStorage } from '@utils/helperFunctions'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import accountsTracking from '@services/accountsTracking'
import { TRACKING_PAGE_NAME } from '@utils/constants'
import Authentication from '../../../components/Authentication'
import { updateErrorCustomer, updateSelectedCustomer, updateUserData } from '../../../store/actions'
import styles from './login-route.module.css'

const googleClientId = process?.env?.REACT_APP_GOOGLE_PROJECT_CLIENT_ID
const validationSchema = yup.object().shape({
  email: yup.string().email('Enter valid email.').required('* Required'),
  password: yup.string().required().min(8).label('Password'),
})

const LoginRoute = () => {
  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const dispatch = useDispatch()
  const history = useHistory()
  const trackingPageName = useSelector(state => state?.AccountsTrackingReducer?.trackingPageName)
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const inviteFrom = localStorage.getItem('inviteFrom')

  useEffect(() => {
    const trackingPayload = {
      // eslint-disable-next-line
      pageName: shareBoardLink ? 'Board Share' : inviteFrom ? 'Report Share' : 'Home Page',
      eventName: 'Sign In Page View',
      sourceName: shareBoardLink || inviteFrom ? '' : 'Clicked Login Button',
    }
    accountsTracking(trackingPayload)
    dispatch({
      type: TRACKING_PAGE_NAME,
      // eslint-disable-next-line
      payload: shareBoardLink ? 'Board Share' : inviteFrom ? 'Report Share' : 'Home Page',
    })
    // eslint-disable-next-line
  }, [])
  const rollbar = useRollbar()
  const [isLoading, setIsLoading] = useState(false)
  const [googleUserLoading, setGoogleUserLoading] = useState(false)

  const { t } = useTranslation()

  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      console.error(e.message)
    }
  }

  const isNewUser = datJoined => {
    if (!datJoined) return false
    const now = new Date()
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000)

    return new Date(datJoined) >= fiveMinutesAgo
  }
  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  // Handle regular email login
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true)
      try {
        const data = {
          email: values?.email,
          password: values?.password,
        }
        const res = await axiosInstance.post('/accounts/email-login/', data)
        const dateJoined = res.data?.user?.profile?.date_joined
        const newUser = isNewUser(dateJoined)
        const trackingPayload = {
          pageName: trackingPageName,
          eventName: newUser ? 'Sign Up Success' : 'Sign In Success',
          sourceName: 'Clicked Login Button',
          signInMethod: 'Email',
          userStatus: 'Free',
          authEmail: res.data?.user?.email,
        }
        accountsTracking(trackingPayload)
        if (res.status === 200) {
          const userData = { ...res.data, isGoogleUser: false }
          updateLocalStorage(userData)
          dispatch(updateUserData(userData))
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
          notify(t('notification.loggedInSuccessfully'), 'success')
        }
      } catch (e) {
        if (e?.response?.data?.non_field_errors?.length) {
          notify(t('notification.invalidCredentials'), 'error')
        } else if (e?.response?.status === 500) {
          notify(t('notification.internalServer'), 'error')
        } else {
          notify(t('notification.somethingWentWrong'), 'error')
        }
      } finally {
        setIsLoading(false)
      }
    },
  })

  // Generate report for selected customer
  const retrieveReport = async selectedCustomer => {
    getProfile()
    getCountry()
    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name?.split('/')[1] || '',
      })
      return data
    } catch (error) {
      notify(t('notification.noEnoughReportData'), 'error')
      throw error
    }
  }

  // Handle successful customer selection and report generation
  const handleCustomerSuccess = async (selectedCustomer, isLeadSaved, hasReport) => {
    try {
      if (!hasReport) {
        const report = await retrieveReport(selectedCustomer)
        const reportPayload = {
          customerId: String(selectedCustomer.id),
          parentId: selectedCustomer.resource_name?.split('/')[1] || '',
          uuid: report.unique_id,
          currency: selectedCustomer.currency_code || 'USD',
          customerName: selectedCustomer.descriptive_name,
        }
        const reportToken = SecretService.encode(reportPayload)
        localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        history.push(`/report-generating/${reportToken}`, {
          report,
          //  eslint-disable-next-line
          nextRoute: !isLeadSaved
            ? '/lead-form'
            : shareBoardLink
            ? '/dashboard/boards'
            : '/dashboard/creative-library',
        })
        return
      }

      // If user has report, store the customer and handle navigation
      localStorage.setItem('selectAccount', JSON.stringify(selectedCustomer))

      if (!isLeadSaved) {
        history.push('/lead-form')
      } else {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      }
    } catch (error) {
      console.error('Report generation error:', error)
      throw error
    }
  }

  // Handle fallback when no customers found
  const handleNoCustomersFallback = async leadForm => {
    const sampleReport = {
      id: 123456789,
      level: 1,
      descriptive_name: 'Sample Report',
    }
    dispatch(updateErrorCustomer(true))
    localStorage.setItem('errorCustomer', JSON.stringify(true))
    notify(t('notification.customerDataError'), 'error')

    try {
      const inviterRes = await fetchInvitersList()
      if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
        history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
      } else {
        // eslint-disable-next-line
        if (!leadForm) {
          history.push('/lead-form')
        } else {
          history.push(shareBoardLink ? '/dashboard/boards' : '/dashboard/creative-library')
        }
      }
    } catch (error) {
      console.error('Inviter list fetch error:', error)
      history.push('/lead-form')
    }
  }
  const handleFetchGoogleUserInfo = async (payload, redirectUri) => {
    setGoogleUserLoading(true)
    try {
      const res = await fetchGoogleUserInfo(payload, redirectUri, trackingPageName)
      if (res.status === 200) {
        const userData = { ...res.data, isGoogleUser: true }
        localStorage.setItem('profilePic', userData?.user?.profile?.profile_picture)
        updateLocalStorage(userData)
        dispatch(updateUserData(userData))
        notify(t('notification.loginSuccess'), 'success')

        const customersResponse = await (async () => {
          const customersResponseUnSorted = await fetchCustomersList()
          return customersResponseUnSorted?.data?.length > 0
            ? customersResponseUnSorted.data.sort((a, b) => b.reports_count - a.reports_count)
            : []
        })()

        if (customersResponse?.length > 0) {
          const selectedCustomer = customersResponse[0]
          dispatch(updateSelectedCustomer(selectedCustomer))
          localStorage.setItem('customer', JSON.stringify(selectedCustomer))

          // Pass both is_lead_save and has_report to handleCustomerSuccess
          await handleCustomerSuccess(
            selectedCustomer,
            userData?.user?.is_lead_save,
            userData?.user?.profile?.has_report
          )
        } else {
          await handleNoCustomersFallback(res?.data?.user?.is_lead_save)
        }
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
      }
    } catch (error) {
      console.error('Google login error:', error)
      notify(t('notification.somethingWentWrong'), 'error')
    } finally {
      setGoogleUserLoading(false)
    }
  }

  const exchangeCodeForToken = async (authCode, redirectUri) => {
    handleFetchGoogleUserInfo(authCode, redirectUri)
  }
  const handleGoogleLoginSuccess = () => {
    const redirectUri = `${window.location.origin}/auth/google/callback`
    const scopes = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/adwords',
    ]

    const authParams = new URLSearchParams({
      response_type: 'code',
      access_type: 'offline',
      client_id: googleClientId,
      redirect_uri: redirectUri,
      scope: scopes.join(' '),
      prompt: '',
    })

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${authParams}`
    const authWindow = window.open(authUrl, '_blank', 'width=500,height=600,left=100,top=100')
    const messageHandler = event => {
      if (event.origin === window.location.origin) {
        const { code } = event.data
        if (code) {
          exchangeCodeForToken(code, redirectUri) // Step 3: Get access token
          authWindow?.close() // Close popup after getting the code
          window.removeEventListener('message', messageHandler) // Remove listener after handling
        }
      }
    }
    // Adding the event listener
    window.addEventListener('message', messageHandler)
  }

  // Handle URL parameters
  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const reportId = currentUrl.searchParams.get('reportId')
    const customerId = currentUrl.searchParams.get('customerId')

    if (reportId && customerId) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
  }, [])

  return (
    <div className={styles.loginContainer}>
      {googleUserLoading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <Authentication
          componentType="login"
          formik={formik}
          isLoading={isLoading}
          handleGoogleLogin={handleGoogleLoginSuccess}
          title="Login to Ads Grader"
          buttonText="Login"
        />
      )}
    </div>
  )
}

export default LoginRoute
