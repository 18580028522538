import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import Text from '@components/Text/text'
import { SAVED_DESCRIPTION } from '@utils/constants'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import { useDispatch } from 'react-redux'
import { RequestService, bucketBaseURL } from '@services/requests'
import styles from './modalCard.module.css'

const ModalCard = ({ adsData, handleSignUpModal, setSourceName, setPageName, setEventName }) => {
  const dispatch = useDispatch()
  const [saveData, setSaveData] = useState('')
  const [loader, setLoader] = useState(false)
  const fetchDescription = async () => {
    setLoader(true)
    dispatch({ type: SAVED_DESCRIPTION, payload: [] })
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      image_url: adsData?.image_url,
    }
    try {
      if (!adsData.description && !adsData.title) {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        setSaveData(response?.data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }
  useEffect(() => {
    fetchDescription()
    // eslint-disable-next-line
  }, [adsData])

  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()

  return (
    <>
      {((Array.isArray(adsData?.title) && adsData.title[0]?.length > 1) ||
        (typeof adsData?.title === 'string' && adsData.title.length > 1)) && (
        <Text
          style={{
            marginTop: 16,
            color: '#67696B',
            fontSize: 12,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {Array.isArray(adsData?.title) ? adsData.title[0] : adsData.title}
        </Text>
      )}

      <Card style={{ marginTop: 16 }}>
        {adsDate === localDate ? (
          <>
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url ? (
                      <img
                        className={styles.imageWidth}
                        src={adsData.image_url ?? noPreviewImage}
                        alt="thumbnail"
                      />
                    ) : adsData?.video_url ? (
                      <video
                        style={{ width: '100%', height: '700px' }}
                        controls
                        src={adsData?.video_url}
                      >
                        <track
                          kind="captions"
                          srcLang="en"
                          src="captions_en.vtt"
                          label="English captions"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={noPreviewImage}
                        className={styles.imageWidth}
                        alt="noPreviewImage"
                      />
                    )
                  }
                </>
              ) : // eslint-disable-next-line
              adsData?.origin?.provider === 'google' ? (
                <>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img src={adsData.image_url} className={styles.imageWidth} alt="thumbnail" />
                    ) : adsData?.video_url ? (
                      <iframe
                        className={styles.imageWidth}
                        style={{ height: '700px' }}
                        title="video"
                        src={`https://www.youtube.com/embed/${adsData?.video_url}?mute=0&autoplay=1`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <img
                        src={noPreviewImage}
                        className={styles.imageWidth}
                        alt="noPreviewImage"
                      />
                    )
                  }
                </>
              ) : (
                ''
              )
            }
          </>
        ) : (
          <>
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <>
                  {
                    // eslint-disable-next-line
                    adsData?.ads_grader_image_url ? (
                      <img
                        src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                        className={styles.imageWidth}
                        alt="thumbnail"
                      />
                    ) : adsData?.ads_grader_video_url ? (
                      <video
                        style={{ width: '100%', height: '700px' }}
                        controls
                        src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_video_url}`}
                      >
                        <track
                          kind="captions"
                          srcLang="en"
                          src="captions_en.vtt"
                          label="English captions"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={noPreviewImage}
                        className={styles.imageWidth}
                        alt="noPreviewImage"
                      />
                    )
                  }
                </>
              ) : // eslint-disable-next-line
              adsData?.origin?.provider === 'google' ? (
                <>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img src={adsData.image_url} className={styles.imageWidth} alt="thumbnail" />
                    ) : adsData?.video_url ? (
                      <iframe
                        className={styles.imageWidth}
                        style={{ height: '700px' }}
                        title="video"
                        src={`https://www.youtube.com/embed/${adsData?.video_url}?mute=0&autoplay=1`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <img
                        src={noPreviewImage}
                        className={styles.imageWidth}
                        alt="noPreviewImage"
                      />
                    )
                  }
                </>
              ) : (
                ''
              )
            }
          </>
        )}

        <Text className={styles.description}>
          {
            // eslint-disable-next-line
            adsData?.description ? (
              adsData?.description
            ) : loader ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              saveData?.description
            )
          }
        </Text>
        <div className={styles.buttonContainer}>
          <button
            type="button"
            className={styles.learnButton}
            onClick={() => {
              handleSignUpModal()
              setSourceName('Clicked Visit Landing Page Button')
              setPageName('Un Auth Discover Ads Page')
              setEventName('Sign In Page View')
            }}
          >
            Visit Landing Page
          </button>
        </div>
      </Card>
    </>
  )
}

export default ModalCard
