import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Text from '@components/Text/text'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { RequestService, bucketBaseURL } from '@services/requests'
import styled from 'styled-components'
import {
  FOLLOWED_BRANDS_LIST,
  METRICS_DATA,
  SELECTED_MEDIA_TYPE,
  AD_ACTIVITY_DATA,
  AD_COPY_DATA,
  HEADLINES_DATA,
  LANDING_PAGES_DATA,
  FILTERS_CLICK_FLAG,
  MEDIA_MIX_DATA,
  HOOKS_DATA,
} from '@utils/constants'
import accountsTracking from '@services/accountsTracking'
import { Card, Col, Row, Skeleton, Spin, Tabs } from 'antd'
import InsightsCard from '../FollowedBrandsInsights/InsightsCard'
import brandsDetailStyles from './brandsDetail.module.css'
import AdsCard from '../AdsCard'
import TabContent from './TabContent'
import styles from './styles.module.css'

const BrandsDetail = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('overview')
  const [showButton, setShowButton] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const [nextUrlOriginal, setNextUrlOriginal] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [filteredDiscoverData, setFilteredDiscoverData] = useState([])
  const [originalDiscoverData, setOriginalDiscoverData] = useState([])
  const [boardsList, setBoardsList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const history = useHistory()
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const metricsData = useSelector(state => state?.discoverAds?.metricsData)
  const mediaType = useSelector(state => state?.discoverAds?.mediaType)
  const selectedDescriptions = useSelector(state => state?.discoverAds?.selectedDescriptions || [])
  const selectedHeadlines = useSelector(state => state?.discoverAds?.selectedHeadlines || [])
  const selectedLandingPages = useSelector(state => state?.discoverAds?.selectedLandingPages || [])
  const selectedMediaType = useSelector(state => state?.discoverAds?.selectedMediaType || [])
  const selectedAdsActivity = useSelector(state => state?.discoverAds?.selectedAdsActivity || [])
  const selectedHooks = useSelector(state => state?.discoverAds?.selectedHooks || [])
  const filtersClickFlag = useSelector(state => state?.discoverAds?.filtersClickFlag)

  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      // console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  useEffect(() => {
    if (followedBrandsList.length <= 0) {
      getFollowedBrandsList()
    }
    // eslint-disable-next-line
  }, [followedBrandsList])
  // eslint-disable-next-line

  const processAdsData = response => {
    if (response?.data?.result?.data?.json) {
      dispatch({ type: METRICS_DATA, payload: response.data.result.data.json })
    }
    const adsData = response?.data?.result?.facebook_ads?.data || []
    if (Array.isArray(adsData)) {
      const formattedData = adsData.map(ad => ({
        ...ad,
        is_followed: followedBrandsList.some(
          brand =>
            brand.google_advertiser_id === ad.advertiser_id ||
            brand.meta_page_id === ad.additional_info?.page_id
        ),
      }))
      setFilteredDiscoverData(formattedData)
      setOriginalDiscoverData(formattedData)
      setNextUrlOriginal(response.data?.result?.facebook_ads?.paging?.next || null)
    }
    setHasMore(Boolean(response.data?.result?.facebook_ads?.paging?.next))
    setNextUrl(response.data?.result?.facebook_ads?.paging?.next || null)
  }
  const processAdsDataTemp = response => {
    if (response?.data?.result?.data?.json) {
      dispatch({ type: METRICS_DATA, payload: response.data.result.data.json })
    }
    const adsData = response?.data?.result?.facebook_ads?.data || []
    if (Array.isArray(adsData)) {
      const formattedData = adsData.map(ad => ({
        ...ad,
        is_followed: followedBrandsList.some(
          brand =>
            brand.google_advertiser_id === ad.advertiser_id ||
            brand.meta_page_id === ad.additional_info?.page_id
        ),
      }))
      setFilteredDiscoverData(formattedData)
      // setOriginalDiscoverData(formattedData)
      // setNextUrlOriginal(response.data?.result?.facebook_ads?.paging?.next || null)
    }
    setHasMore(Boolean(response.data?.result?.facebook_ads?.paging?.next))
    setNextUrl(response.data?.result?.facebook_ads?.paging?.next || null)
  }

  const fetchFilteredData = async () => {
    setNextUrl(null)
    try {
      setIsLoading(true)
      const data = {
        meta_page_id: specificBrandDetail?.pageId,
        google_advertiser_id: specificBrandDetail?.googleId,
      }

      if (selectedDescriptions.length > 0) {
        data.description = selectedDescriptions
      }
      if (selectedHeadlines.length > 0) {
        data.title = selectedHeadlines
      }
      if (selectedLandingPages.length > 0) {
        data.ad_link = selectedLandingPages
      }
      if (selectedMediaType.length > 0) {
        data.media_type = selectedMediaType
      }
      if (selectedAdsActivity.length > 0) {
        data.active_filters = selectedAdsActivity
      }
      if (selectedHooks.length > 0) {
        data.hooks = selectedHooks
      }
      const response = await RequestService.post('/save/ads-filter/', data)
      processAdsDataTemp(response)
    } catch (error) {
      console.error('Error fetching filtered data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchBrandsList = async () => {
    setNextUrl(null)
    try {
      setIsLoading(true)
      const response = await RequestService.get(
        `save/list-brands/?meta_page_id=${specificBrandDetail?.pageId}&google_page_id=${
          specificBrandDetail?.googleId
        }${mediaType ? `&media_type=${mediaType}` : ''}`
      )
      processAdsData(response)
      dispatch({ type: FILTERS_CLICK_FLAG, payload: false })
    } catch (error) {
      console.error('Error fetching brands list:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleMoreData = response => {
    const newAdsData = response.data?.result?.facebook_ads?.data || []
    if (Array.isArray(newAdsData)) {
      const formattedData = newAdsData.map(ad => ({
        ...ad,
        is_followed: followedBrandsList.some(
          brand =>
            brand.google_advertiser_id === ad.advertiser_id ||
            brand.meta_page_id === ad.additional_info?.page_id
        ),
      }))
      // setOriginalDiscoverData(prev => [...prev, ...formattedData])
      setFilteredDiscoverData(prev => [...prev, ...formattedData])
    }
    setHasMore(Boolean(response.data?.result?.facebook_ads?.paging?.next))
    setNextUrl(response.data?.result?.facebook_ads?.paging?.next || null)
  }
  const fetchMoreData = async () => {
    if (!nextUrl) return
    try {
      setIsFetchingMore(true)
      const hasFilters =
        selectedDescriptions.length > 0 ||
        selectedHeadlines.length > 0 ||
        selectedLandingPages.length > 0 ||
        selectedMediaType.length > 0 ||
        selectedAdsActivity.length > 0 ||
        selectedHooks.length > 0

      if (hasFilters) {
        const url = new URL(nextUrl)
        const page = url.searchParams.get('page')

        const data = {
          page: page || 1,
          description: selectedDescriptions,
          title: selectedHeadlines,
          ad_link: selectedLandingPages,
          media_type: selectedMediaType,
          active_filters: selectedAdsActivity,
          google_advertiser_id: specificBrandDetail?.googleId,
          meta_page_id: specificBrandDetail?.pageId,
          hooks: selectedHooks,
        }
        const response = await RequestService.post('/save/ads-filter/', data)
        handleMoreData(response)
      } else {
        const response = await RequestService.get(nextUrl)
        handleMoreData(response)
      }
    } catch (error) {
      console.error('Error fetching more data:', error)
    } finally {
      setIsFetchingMore(false)
    }
  }
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const handleShowModal = index => setSelectedIndex(index)
  const handlePrevious = () => {
    setSelectedIndex(prev => (prev > 0 ? prev - 1 : filteredDiscoverData.length - 1))
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
      eventName: 'Ad Details Window View',
      brandName: filteredDiscoverData[selectedIndex]?.additional_info?.page_name,
      sourceName: 'Clicked Previous Icon',
    }
    accountsTracking(trackingPayload)
  }
  const handleNext = () => {
    setSelectedIndex(prev => (prev < filteredDiscoverData.length - 1 ? prev + 1 : 0))
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
      eventName: 'Ad Details Window View',
      brandName: filteredDiscoverData[selectedIndex]?.additional_info?.page_name,
      sourceName: 'Clicked Next Icon',
    }
    accountsTracking(trackingPayload)
  }

  useEffect(() => {
    if (selectedDescriptions.length > 0) {
      fetchFilteredData()
    }
    // eslint-disable-next-line
  }, [selectedDescriptions])

  useEffect(() => {
    if (selectedHeadlines.length > 0) {
      fetchFilteredData()
    }

    // eslint-disable-next-line
  }, [selectedHeadlines])

  useEffect(() => {
    if (selectedLandingPages.length > 0) {
      fetchFilteredData()
    }
    // eslint-disable-next-line
  }, [selectedLandingPages])

  useEffect(() => {
    if (selectedMediaType.length > 0) {
      fetchFilteredData()
    } else if (filtersClickFlag) {
      fetchBrandsList()
    }
    // eslint-disable-next-line
  }, [selectedMediaType, filtersClickFlag])

  useEffect(() => {
    if (selectedHooks.length > 0) {
      fetchFilteredData()
    }
    if (filtersClickFlag) {
      fetchBrandsList()
    }
    // eslint-disable-next-line
  }, [selectedHooks, filtersClickFlag])

  useEffect(() => {
    if (selectedAdsActivity.length > 0) {
      fetchFilteredData()
    }
    // eslint-disable-next-line
  }, [selectedAdsActivity])

  useEffect(() => {
    if (mediaType) {
      fetchFilteredData()
    }
    // eslint-disable-next-line
  }, [mediaType])

  useEffect(() => {
    dispatch({ type: AD_ACTIVITY_DATA, payload: [] })
    dispatch({ type: AD_COPY_DATA, payload: [] })
    dispatch({ type: HEADLINES_DATA, payload: [] })
    dispatch({ type: LANDING_PAGES_DATA, payload: [] })
    dispatch({ type: MEDIA_MIX_DATA, payload: [] })
    dispatch({ type: HOOKS_DATA, payload: [] })

    fetchBrandsList()
    // eslint-disable-next-line
  }, [specificBrandDetail?.pageId])

  const ButtonContainer = styled.span`
    position: fixed;
    bottom: 32px;
    right: 32px;
    transform: translate(50%, 50%);
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 1;
    background: #f16434;
    border-radius: 5rem;
    // transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
    display: 'flex';
    // &:hover {
    //   opacity: 1;
    // }
  `

  const scrollToTop = () => {
    const targetDiv = document.getElementById('report')

    // if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' })
    setShowButton(false)
    // }
  }

  window.addEventListener('wheel', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position?.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  window.addEventListener('keydown', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position?.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })
  const handleTabChange = key => {
    // Reset all filters before changing tab
    if (
      selectedDescriptions.length > 0 ||
      selectedHeadlines.length > 0 ||
      selectedLandingPages.length > 0 ||
      selectedMediaType.length > 0 ||
      selectedAdsActivity.length > 0 ||
      selectedHooks.length > 0
    ) {
      setFilteredDiscoverData(originalDiscoverData)
      setNextUrl(nextUrlOriginal)
    }
    dispatch({ type: 'SET_SELECTED_DESCRIPTIONS', payload: [] })
    dispatch({ type: 'SET_SELECTED_HEADLINES', payload: [] })
    dispatch({ type: 'SET_SELECTED_LANDING_PAGES', payload: [] })
    dispatch({ type: SELECTED_MEDIA_TYPE, payload: [] })
    dispatch({ type: 'SET_SELECTED_MEDIA_TYPE', payload: [] })
    dispatch({ type: 'selected_media_type', payload: [] })
    dispatch({ type: 'SELECTED_ADS_ACTIVITY', payload: [] })
    dispatch({ type: 'SELECTED_HOOKS', payload: [] })
    // Then change the tab
    setActiveTab(key)
  }

  const skeletonArray = Array.from({ length: 15 })

  const renderAdsGrid = () => {
    if (isLoading) {
      return (
        <div style={{ padding: '20px' }}>
          <Row gutter={[16, 16]} justify="center">
            {skeletonArray.map((_, index) => (
              // eslint-disable-next-line
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                <Card style={{ height: 300 }}>
                  <Skeleton active title={false} paragraph={{ rows: 4 }} />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )
    }

    return (
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={filteredDiscoverData.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          isFetchingMore && (
            <div style={{ padding: '20px' }}>
              <Row gutter={[16, 16]} justify="center">
                {skeletonArray.map((_, index) => (
                  // eslint-disable-next-line
                  <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
                    <Card style={{ height: 300 }}>
                      <Skeleton active title={false} paragraph={{ rows: 4 }} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )
        }
        scrollableTarget="scrollableDiv"
        scrollThreshold="35%"
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            750: 2,
            900: 2,
            1400: 4,
            1800: 4,
            2200: 6,
            2500: 7,
          }}
        >
          <Masonry columnsCount={3} gutter="20px">
            {filteredDiscoverData.map((item, index) => (
              <AdsCard
                followBrandsMainFlow
                // eslint-disable-next-line
                key={index}
                adsData={item}
                onShowModal={() => handleShowModal(index)}
                onPrevious={handlePrevious}
                onNext={handleNext}
                selectedData={filteredDiscoverData[selectedIndex]}
                isFirst={selectedIndex === 0}
                isLast={selectedIndex === filteredDiscoverData.length - 1}
                boardsList={boardsList}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </InfiniteScroll>
    )
  }
  const daysAgo = useMemo(() => {
    if (!metricsData?.updated_at) return 'N/A'

    // Parse the ISO date string
    const lastUpdatedDate = new Date(metricsData.updated_at)
    const currentDate = new Date()

    // Calculate the difference in milliseconds
    const diffTime = currentDate.getTime() - lastUpdatedDate.getTime()

    // Convert milliseconds to days
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return diffDays > 0 ? `${diffDays} days ago` : 'Today'
  }, [metricsData?.updated_at])
  return (
    <div style={{ marginTop: 20, marginLeft: 26, marginRight: 26 }}>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Text
          size={14}
          onClick={() => history.push('/dashboard/brands')}
          cursor
          className={brandsDetailStyles.learningTop}
        >
          Brands
        </Text>
        <div className={brandsDetailStyles.slash}> /</div>
        <Text className={brandsDetailStyles.catagory}>{specificBrandDetail?.name}</Text>
      </div>

      <Card className={styles.mainCard}>
        <div className={styles.brandHeader}>
          <div className={styles.brandInfo}>
            {specificBrandDetail.logo && (
              <img
                className={styles.brandLogo}
                src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${specificBrandDetail?.logo}`}
                alt={specificBrandDetail?.name}
              />
            )}

            <div className={styles.brandMeta}>
              <Text size={20} weight={600}>
                {specificBrandDetail?.name}
              </Text>
              {/* <Text size={14} color="#666">
                Not available
              </Text> */}
            </div>
          </div>
          <div>
            <Text size={15} isBold className={styles.activeAds}>
              <span style={{ color: 'green', fontSize: '18px' }}> •</span>{' '}
              {metricsData?.active_count} Active Ads
            </Text>
            <Text size={12} style={{ color: '#666666' }}>
              Updated {daysAgo}
            </Text>
          </div>
        </div>
        {metricsData && (
          <>
            {' '}
            <div className={styles.tabsContainer}>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="Overview" key="overview">
                  <TabContent activeTab={activeTab} />
                </Tabs.TabPane>
                {metricsData?.hooks_count > 0 && (
                  <Tabs.TabPane tab="Hooks" key="hooks">
                    <TabContent activeTab={activeTab} />
                  </Tabs.TabPane>
                )}

                {metricsData.ad_copy_count > 0 && (
                  <Tabs.TabPane tab="Ad Copy" key="adCopy">
                    <TabContent activeTab={activeTab} />
                  </Tabs.TabPane>
                )}
                {metricsData.headline_count > 0 && (
                  <Tabs.TabPane tab="Headlines" key="headlines">
                    <TabContent activeTab={activeTab} />
                  </Tabs.TabPane>
                )}
                {metricsData.landingPages > 0 && (
                  <Tabs.TabPane tab="Landing Pages" key="landingPages">
                    <TabContent activeTab={activeTab} />
                  </Tabs.TabPane>
                )}

                <Tabs.TabPane tab="Media Mix" key="mediaMix">
                  <TabContent activeTab={activeTab} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Active/Inactive" key="adsActivity">
                  <TabContent activeTab={activeTab} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
        )}
      </Card>

      {renderAdsGrid()}

      {filteredDiscoverData.length > 0 && showButton ? (
        <ButtonContainer onClick={scrollToTop}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </ButtonContainer>
      ) : (
        ''
      )}
    </div>
  )
}

export default memo(BrandsDetail)
