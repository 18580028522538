import React, { useRef } from 'react'
import { Select } from 'antd'
import Text from '@components/Text/text'
import accountsTracking from '@services/accountsTracking'

const SearchBoard = ({ handleSignUpModal, setSourceName, setPageName, setEventName, adsData }) => {
  const selectRef = useRef(null)

  return (
    <div style={{ marginTop: 12 }} id="inputContainerModal">
      <Text style={{ fontSize: 14, color: '#67696B' }}>Add this ad to board</Text>
      <div style={{ marginTop: 12 }}>
        <Select
          open={false}
          ref={selectRef}
          placeholder="Select board(s)"
          onClick={() => {
            const trackingPayload = {
              eventName: 'Add to Board Click',
              pageName: 'Un Auth Discover Ads Page',
              userStatus: 'unlogged',
              sourceName: 'Clicked Add to Board Button',
              currentTab: 'All',
              brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
              hasBoards: false,
            }
            accountsTracking(trackingPayload)
            handleSignUpModal()
            setSourceName('Clicked Add to Board Dropdown')
            setPageName('Un Auth Discover Ads Page')
            setEventName('Sign In Page View')
          }}
          style={{ width: '100%' }}
          showSearch={false}
          defaultActiveFirstOption={false} // Prevents automatic hovering on the first option
        />
      </div>
    </div>
  )
}

export default SearchBoard
