// Function to get intervals based on total count
const getIntervals = totalCount => {
  if (totalCount <= 5) {
    return { ticks: [0, 1, 2, 3, 4, 5], domain: [0, 5] }
  }
  // if (totalCount <= 10) {
  //   return { ticks: [0, 2, 4, 6, 8, 10], domain: [0, 10] }
  // }
  if (totalCount <= 15) {
    return { ticks: [0, 3, 6, 9, 12, 15], domain: [0, 15] }
  }
  if (totalCount <= 25) {
    return { ticks: [0, 5, 10, 15, 20, 25], domain: [0, 25] }
  }
  if (totalCount <= 50) {
    return { ticks: [0, 10, 20, 30, 40, 50], domain: [0, 50] }
  }
  if (totalCount <= 75) {
    return { ticks: [0, 15, 30, 45, 60, 75], domain: [0, 75] }
  }
  if (totalCount <= 100) {
    return { ticks: [0, 20, 40, 60, 80, 100], domain: [0, 100] }
  }
  if (totalCount <= 125) {
    return { ticks: [0, 25, 50, 75, 100, 125], domain: [0, 125] }
  }
  if (totalCount <= 150) {
    return { ticks: [0, 30, 60, 90, 120, 150], domain: [0, 150] }
  }
  if (totalCount <= 175) {
    return { ticks: [0, 35, 70, 105, 140, 175], domain: [0, 175] }
  }
  if (totalCount <= 200) {
    return { ticks: [0, 40, 80, 120, 160, 200], domain: [0, 200] }
  }
  if (totalCount <= 225) {
    return { ticks: [0, 45, 90, 135, 180, 225], domain: [0, 225] }
  }
  if (totalCount <= 250) {
    return { ticks: [0, 50, 100, 150, 200, 250], domain: [0, 250] }
  }
  if (totalCount <= 275) {
    return { ticks: [0, 55, 110, 165, 220, 275], domain: [0, 275] }
  }
  if (totalCount <= 300) {
    return { ticks: [0, 60, 120, 180, 240, 300], domain: [0, 300] }
  }
  if (totalCount <= 325) {
    return { ticks: [0, 65, 130, 195, 260, 325], domain: [0, 325] }
  }
  if (totalCount <= 350) {
    return { ticks: [0, 70, 140, 210, 280, 350], domain: [0, 350] }
  }
  if (totalCount <= 375) {
    return { ticks: [0, 75, 150, 225, 300, 375], domain: [0, 375] }
  }
  if (totalCount <= 400) {
    return { ticks: [0, 80, 160, 240, 320, 400], domain: [0, 400] }
  }
  if (totalCount <= 425) {
    return { ticks: [0, 85, 170, 255, 340, 425], domain: [0, 425] }
  }
  if (totalCount <= 450) {
    return { ticks: [0, 90, 180, 270, 360, 450], domain: [0, 450] }
  }
  if (totalCount <= 475) {
    return { ticks: [0, 95, 190, 285, 380, 475], domain: [0, 475] }
  }
  if (totalCount <= 500) {
    return { ticks: [0, 100, 200, 300, 400, 500], domain: [0, 500] }
  }
  // if (totalCount <= 525) {
  //   return { ticks: [0, 105, 210, 315, 420, 525], domain: [0, 525] }
  // }
  if (totalCount <= 550) {
    return { ticks: [0, 110, 220, 330, 440, 550], domain: [0, 550] }
  }
  // if (totalCount <= 575) {
  //   return { ticks: [0, 115, 230, 345, 460, 575], domain: [0, 575] }
  // }
  if (totalCount <= 600) {
    return { ticks: [0, 120, 240, 360, 480, 600], domain: [0, 600] }
  }
  // if (totalCount <= 625) {
  //   return { ticks: [0, 125, 250, 375, 500, 625], domain: [0, 625] }
  // }
  if (totalCount <= 650) {
    return { ticks: [0, 130, 260, 390, 520, 650], domain: [0, 650] }
  }
  if (totalCount <= 700) {
    return { ticks: [0, 140, 280, 420, 560, 700], domain: [0, 700] }
  }
  // if (totalCount <= 725) {
  //   return { ticks: [0, 145, 290, 435, 580, 725], domain: [0, 725] }
  // }
  if (totalCount <= 750) {
    return { ticks: [0, 150, 300, 450, 600, 750], domain: [0, 750] }
  }
  // if (totalCount <= 775) {
  //   return { ticks: [0, 155, 310, 465, 620, 775], domain: [0, 775] }
  // }
  if (totalCount <= 800) {
    return { ticks: [0, 160, 320, 480, 640, 800], domain: [0, 800] }
  }
  // if (totalCount <= 825) {
  //   return { ticks: [0, 165, 330, 495, 660, 825], domain: [0, 825] }
  // }
  if (totalCount <= 850) {
    return { ticks: [0, 170, 340, 510, 680, 850], domain: [0, 850] }
  }
  // if (totalCount <= 875) {
  //   return { ticks: [0, 175, 350, 525, 700, 875], domain: [0, 875] }
  // }
  if (totalCount <= 900) {
    return { ticks: [0, 180, 360, 540, 720, 900], domain: [0, 900] }
  }
  // if (totalCount <= 925) {
  //   return { ticks: [0, 185, 370, 555, 740, 925], domain: [0, 925] }
  // }
  if (totalCount <= 950) {
    return { ticks: [0, 190, 380, 570, 760, 950], domain: [0, 950] }
  }
  // if (totalCount <= 975) {
  //   return { ticks: [0, 195, 390, 585, 780, 975], domain: [0, 975] }
  // }
  if (totalCount <= 1000) {
    return { ticks: [0, 200, 400, 600, 800, 1000], domain: [0, 1000] }
  }
  if (totalCount <= 1025) {
    return { ticks: [0, 205, 410, 615, 820, 1025], domain: [0, 1025] }
  }
  if (totalCount <= 1050) {
    return { ticks: [0, 210, 420, 630, 840, 1050], domain: [0, 1050] }
  }
  if (totalCount <= 1100) {
    return { ticks: [0, 220, 440, 660, 880, 1100], domain: [0, 1100] }
  }
  if (totalCount <= 1150) {
    return { ticks: [0, 230, 460, 690, 920, 1150], domain: [0, 1150] }
  }
  if (totalCount <= 1200) {
    return { ticks: [0, 240, 480, 720, 960, 1200], domain: [0, 1200] }
  }
  if (totalCount <= 1250) {
    return { ticks: [0, 250, 500, 750, 1000, 1250], domain: [0, 1250] }
  }
  if (totalCount <= 1300) {
    return { ticks: [0, 260, 520, 780, 1040, 1300], domain: [0, 1300] }
  }
  if (totalCount <= 1350) {
    return { ticks: [0, 270, 540, 810, 1080, 1350], domain: [0, 1350] }
  }
  if (totalCount <= 1400) {
    return { ticks: [0, 280, 560, 840, 1120, 1400], domain: [0, 1400] }
  }
  if (totalCount <= 1500) {
    return { ticks: [0, 300, 600, 900, 1200, 1500], domain: [0, 1500] }
  }
  if (totalCount <= 1550) {
    return { ticks: [0, 310, 620, 930, 1240, 1550], domain: [0, 1550] }
  }
  if (totalCount <= 1600) {
    return { ticks: [0, 320, 640, 960, 1280, 1600], domain: [0, 1600] }
  }
  if (totalCount <= 1650) {
    return { ticks: [0, 330, 660, 990, 1320, 1650], domain: [0, 1650] }
  }
  if (totalCount <= 1700) {
    return { ticks: [0, 340, 680, 1020, 1360, 1700], domain: [0, 1700] }
  }
  if (totalCount <= 1750) {
    return { ticks: [0, 350, 700, 1050, 1400, 1750], domain: [0, 1750] }
  }
  if (totalCount <= 1800) {
    return { ticks: [0, 360, 720, 1080, 1440, 1800], domain: [0, 1800] }
  }
  if (totalCount <= 1850) {
    return { ticks: [0, 370, 740, 1110, 1480, 1850], domain: [0, 1850] }
  }
  if (totalCount <= 1900) {
    return { ticks: [0, 380, 760, 1140, 1520, 1900], domain: [0, 1900] }
  }
  if (totalCount <= 1950) {
    return { ticks: [0, 390, 780, 1170, 1560, 1950], domain: [0, 1950] }
  }
  if (totalCount <= 2000) {
    return {
      ticks: [0, 400, 800, 1200, 1600, 2000],
      domain: [0, 2000],
    }
  }
  if (totalCount <= 2050) {
    return { ticks: [0, 410, 820, 1230, 1640, 2050], domain: [0, 2050] }
  }
  if (totalCount <= 2100) {
    return { ticks: [0, 420, 840, 1260, 1680, 2100], domain: [0, 2100] }
  }

  if (totalCount <= 2250) {
    return { ticks: [0, 450, 900, 1350, 1800, 2250], domain: [0, 2250] }
  }
  if (totalCount <= 2300) {
    return { ticks: [0, 460, 920, 1380, 1840, 2300], domain: [0, 2300] }
  }
  if (totalCount <= 2350) {
    return { ticks: [0, 470, 940, 1410, 1880, 2350], domain: [0, 2350] }
  }
  if (totalCount <= 2400) {
    return { ticks: [0, 480, 960, 1440, 1920, 2400], domain: [0, 2400] }
  }
  if (totalCount <= 2450) {
    return { ticks: [0, 490, 980, 1470, 1960, 2450], domain: [0, 2450] }
  }
  if (totalCount <= 2500) {
    return { ticks: [0, 500, 1000, 1500, 2000, 2500], domain: [0, 2500] }
  }

  if (totalCount <= 2750) {
    return { ticks: [0, 550, 1100, 1650, 2200, 2750], domain: [0, 2750] }
  }
  if (totalCount <= 3000) {
    return { ticks: [0, 600, 1200, 1800, 2400, 3000], domain: [0, 3000] }
  }
  if (totalCount <= 4000) {
    return { ticks: [0, 800, 1600, 2400, 3200, 4000], domain: [0, 4000] }
  }
  if (totalCount <= 5000) {
    return { ticks: [0, 1000, 2000, 3000, 4000, 5000], domain: [0, 5000] }
  }

  // For values above 5000, round to nearest 1000 and create even intervals
  const roundedMax = Math.ceil(totalCount / 1000) * 1000
  const interval = roundedMax / 5
  return {
    ticks: [0, interval, interval * 2, interval * 3, interval * 4, roundedMax],
    domain: [0, roundedMax],
  }
}

// Default intervals for empty data
const defaultIntervals = {
  ticks: [0, 3, 6, 9, 12, 15],
  domain: [0, 15],
}

export const getYAxisConfig = totalCount => {
  if (!totalCount) return defaultIntervals
  return getIntervals(totalCount)
}
