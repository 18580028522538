import React from 'react'
import { Card, message } from 'antd'
import Text from '@components/Text/text'
import copyIcon from '@images/AdsPreview/InsightsCopyOrange.svg'
import { useSelector } from 'react-redux'
import styles from './styles.module.css'

const InsightsCard = () => {
  const metricsData = useSelector(state => state?.discoverAds?.metricsData)
  const handleCopyMetrics = () => {
    const bullet = '•'
    const metricsText = `
    ${bullet} ${metricsData?.newAds} new ads in last 14 days .
    ${bullet} Lead to ${metricsData?.landingPages} landing pages .
    ${bullet} Creative output is up ${metricsData?.creativeOutputRate}% over last 14 days .
    ${bullet} Lead to ${metricsData?.headline_count} landing pages .
    ${bullet} Lead to ${metricsData?.ad_copy_count} ad copies .
     ${bullet} Lead to ${metricsData?.hooks_count} hooks .

    `.trim()
    navigator.clipboard
      .writeText(metricsText)
      .then(() => {
        message.success('Metrics copied to clipboard')
      })
      .catch(() => {
        message.error('Failed to copy metrics')
      })
  }
  return (
    <Card className={styles.styledCard}>
      <div className={styles.headerContainer}>
        <Text size={18} style={{ fontWeight: '600' }}>
          Metrics
        </Text>
        <button type="button" className={styles.copyButton} onClick={handleCopyMetrics}>
          <img src={copyIcon} alt="copy" />
        </button>
      </div>
      <div className={styles.metricItem}>
        <Text size={15}>
          <span className={styles.metricValue}>{metricsData?.newAds} new ads</span>{' '}
          <span className={styles.grayText}>in last 14 days</span>
        </Text>
      </div>
      <div className={styles.metricItem}>
        <Text size={15}>
          <span className={styles.grayText}>Creative output is up</span>{' '}
          <span className={styles.metricValue}>{metricsData?.creativeOutputRate}%</span>{' '}
          <span className={styles.grayText}>over last 14 days</span>
        </Text>
      </div>
      {metricsData.landingPages > 0 && (
        <div className={styles.metricItem}>
          <Text size={15}>
            <span className={styles.grayText}>Lead to</span>{' '}
            <span className={styles.metricValue}>{metricsData?.landingPages}</span>{' '}
            <span className={styles.grayText}>landing pages</span>
          </Text>
        </div>
      )}

      {metricsData.headline_count > 0 && (
        <div className={styles.metricItem}>
          <Text size={15}>
            <span className={styles.grayText}>Lead to</span>{' '}
            <span className={styles.metricValue}>{metricsData?.headline_count}</span>{' '}
            <span className={styles.grayText}>headlines</span>
          </Text>
        </div>
      )}

      {metricsData.ad_copy_count > 0 && (
        <div className={styles.metricItem}>
          <Text size={15}>
            <span className={styles.grayText}>Lead to</span>{' '}
            <span className={styles.metricValue}>{metricsData?.ad_copy_count}</span>{' '}
            <span className={styles.grayText}>ad copies</span>
          </Text>
        </div>
      )}
      {metricsData.hooks_count > 0 && (
        <div className={styles.metricItem}>
          <Text size={15}>
            <span className={styles.grayText}>Lead to</span>{' '}
            <span className={styles.metricValue}>{metricsData?.hooks_count}</span>{' '}
            <span className={styles.grayText}>Hooks</span>
          </Text>
        </div>
      )}
    </Card>
  )
}

export default InsightsCard
