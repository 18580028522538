import React, { useEffect, useState } from 'react'
import { Button, Col, message, Row, Spin, Tooltip } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Text from '@components/Text/text'
import copyIcon from '@images/DiscoverAds/Modal/DetailsCard/Copy to CSV - Orange.png'
import downloadIcon from '@images/DiscoverAds/Modal/DetailsCard/download.png'
import viewIcon from '@images/DiscoverAds/Modal/DetailsCard/view.png'
import thumbnailIcon from '@images/DiscoverAds/Modal/DetailsCard/thumbnail.png'
import SaveAdIcon from '@images/DiscoverAds/ButtonIcons/SaveAd.png'
import SavedAdIcon from '@images/DiscoverAds/ButtonIcons/SavedAd.png'
import { RequestService, bucketBaseURL } from '@services/requests'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import accountsTracking from '@services/accountsTracking'
import styles from './action.module.css'

const Actions = ({ adsData }) => {
  const savedDescription = useSelector(state => state?.discoverAds?.savedDescription)
  const { currentUrl, previousUrl } = useSelector(state => state.AccountsTrackingReducer)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const [isUnSaveLoading, setIsUnSaveLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = () => {
    const tracking = {
      eventName: 'Copy Script Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Copy Icon',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(tracking)
    const textToCopy = !adsData?.description ? savedDescription.description : adsData?.description
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)
        message.success('Text copied to clipboard!')
        const tracking1 = {
          eventName: 'Copy Script Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Copy Icon',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
          brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
        }
        accountsTracking(tracking1)
        setTimeout(() => setIsCopied(false), 2000)
      })
      .catch(() => {
        message.error('Failed to copy text')
      })
  }

  const handleDownloadFilesForMeta = async () => {
    // 1. Download the text file (description)
    const adText = adsData?.description || 'No description available'
    const textBlob = new Blob([adText], { type: 'text/plain' })
    const textUrl = URL.createObjectURL(textBlob)
    const textLink = document.createElement('a')
    textLink.href = textUrl
    textLink.download = 'description.txt' // File name for the text file
    document.body.appendChild(textLink)
    textLink.click()
    document.body.removeChild(textLink)

    // 2. Check if the image_url is present, if not, check for the video_url
    if (adsData?.ads_grader_image_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData.ads_grader_image_url}`
      const response = await fetch(imageUrl, { cache: 'no-store' })

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'image.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Image downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (!adsData?.ads_grader_image_url && adsData?.image_url) {
      const imageUrl = adsData.image_url
      const response = await fetch(imageUrl, { cache: 'no-store' })

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'image.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Image downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (adsData?.ads_grader_video_url) {
      const videoUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_video_url}`

      message.success('Video download initiated.')
      const response = await fetch(videoUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'video.mp4') // Force download as video.mp4
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Video downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (!adsData?.ads_grader_video_url && adsData.video_url) {
      try {
        const videoUrl = adsData.video_url
        message.info('Video download initiated.')
        const link = document.createElement('a')
        link.href = videoUrl
        link.setAttribute('download', 'video.mp4') // Set the download attribute with the desired file name

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } catch (error) {
        message.error('Failed to download the video. Please try again.')
      }
    } else {
      message.info('No media URL available to download')
    }
  }

  const handleDownloadFilesForGoogle = async () => {
    // 1. Download the text file (description)
    const adText = !adsData?.description ? savedDescription.description : adsData?.description
    const textBlob = new Blob([adText], { type: 'text/plain' })
    const textUrl = URL.createObjectURL(textBlob)
    const textLink = document.createElement('a')
    textLink.href = textUrl
    textLink.download = 'description.txt'
    document.body.appendChild(textLink)
    textLink.click()
    document.body.removeChild(textLink)
    if (adsData?.image_url && !adsData?.video_url) {
      const imageUrl = adsData.image_url
      try {
        const response = await fetch(imageUrl)
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'image.png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Image downloaded successfully')
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } catch (error) {
        // console.error('Failed to download image:', error)
      }
    } else if (adsData?.video_url) {
      const videoUrl = `https://www.youtube.com/watch?v=${adsData?.video_url}`
      try {
        message.success('Video download initiated.')
        const response = await RequestService.get(
          `/ads-preview/download-ads/?video_url=${videoUrl}`,
          {
            responseType: 'blob',
          }
        )
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'video.mp4') // Give it a filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        message.success('Video downloaded successfully')
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } catch (error) {
        message.error('Failed to download video')
      }
    } else {
      // console.info('No media URL available to download')
    }
  }
  const handleDownloadThumbnailForGoogle = async () => {
    const trackingPayload = {
      eventName: 'Download Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Thumbnail Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
      fileFormat: '.png,.txt',
    }
    accountsTracking(trackingPayload)
    try {
      if (adsData?.image_url && !adsData?.video_url) {
        const imageUrl = adsData.image_url
        const response = await fetch(imageUrl, { mode: 'cors' })
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'thumbmail.png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Thumbmail downloaded successfully')
        const trackingPayload1 = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Thumbnail Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload1)
      } else if (adsData?.video_url) {
        const imageUrl = adsData?.image_url
        try {
          // Show message when the video download starts
          const response = await RequestService.get(
            `/ads-preview/download-ads/?video_url=${imageUrl}`,
            {
              responseType: 'blob',
            }
          )
          // Create a blob URL and trigger download
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'thumbnail.png') // Give it a filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          // Show success message when the download is complete
          message.success('Thumbnail downloaded successfully')
          const trackingPayload1 = {
            eventName: 'Download Success',
            pageName: currentUrl,
            userStatus: 'Free',
            sourceName: 'Clicked Thumbnail Button',
            currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
            fileFormat: '.png,.txt',
          }
          accountsTracking(trackingPayload1)
        } catch (error) {
          // Show error message if something goes wrong
          message.error('Failed to download Tthumbnail')
        }
      } else {
        message.info('No media URL available to download')
      }
    } catch (error) {
      message.error(`Failed to download media: ${error.message}`)
    }
  }
  const handleDownloadThumbnailForMeta = async () => {
    const trackingPayload1 = {
      eventName: 'Download Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Thumbnail Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
      fileFormat: '.png,.txt',
    }
    accountsTracking(trackingPayload1)
    if (adsData?.ads_grader_image_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData.ads_grader_image_url}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Thumbnail Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (!adsData?.ads_grader_image_url && adsData?.image_url) {
      const imageUrl = adsData.image_url
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Thumbnail Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (adsData?.ads_grader_video_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbnail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbnail downloaded successfully')

      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Thumbnail Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else {
      message.info('No media URL available to download')
    }
  }
  const checkAdStatus = async () => {
    setIsSaveLoading(true)
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
    }
    try {
      const response = await RequestService.post(`/save/ad-status/`, {
        // eslint-disable-next-line
        ad_id: adsData?._id,
      })
      setIsSaved(response?.data?.is_active)
      setIsSaveLoading(false)
      // message.success('Ad Saved')
    } catch (error) {
      setIsSaveLoading(false)
      console.error('Error checking ad status:', error)
    }
  }
  useEffect(() => {
    checkAdStatus()
    // eslint-disable-next-line
  }, [adsData])
  const handleSaveAds = async () => {
    const trackingPayload = {
      eventName: 'Save Ad Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Saved Ad Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    setIsSaveLoading(true)
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      is_active: true,
    }
    try {
      await RequestService.post('/save/ads/', data)
      await RequestService.post('/ads-preview/competitors-prod/', data)
      setIsSaveLoading(false)
      setIsSaved(true)
      message.success('Ad Saved')
      const tracking = {
        eventName: 'Save Ad Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Saved Ad Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
    } catch (error) {
      setIsSaveLoading(false)
      setIsSaved(false)
      console.error('Error creating board:', error)
    }
    // }
  }
  const handleUnsaveAds = async () => {
    const trackingPayload = {
      eventName: 'Unsave Ad Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Un Save Ad Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    setIsUnSaveLoading(true)
    try {
      // eslint-disable-next-line
      await RequestService.delete(`/un-save/assigned-ads/?ad_id=${adsData?._id}`)
      checkAdStatus()
      message.success('Ad unsaved successfully')
      const tracking = {
        eventName: 'Unsave Ad Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Un Save Ad Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
      setIsUnSaveLoading(false)
    } catch (error) {
      setIsUnSaveLoading(false)
    }
  }
  return (
    <div>
      <Text style={{ fontSize: 14, color: '#67696B', marginTop: 24 }}>Actions</Text>
      <div style={{ marginTop: 12 }}>
        <Row gutter={16} justify="space-between" align="middle">
          {/* <Col xs={24} sm={12} md={6}> */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <div>
              <Button
                className={styles.hoverButton}
                style={{
                  width: '100%',
                  border: '1px solid #DFE3E4',
                  textTransform: 'capitalize',
                  color: '#000000',
                }}
                onClick={handleCopyToClipboard}
                disabled={
                  (!savedDescription?.description && !adsData?.description) ||
                  savedDescription?.description === null
                }
              >
                <img style={{ marginTop: -8, fontSize: 14 }} src={copyIcon} alt="icon" />
                <span style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 12 }}>
                  {isCopied ? 'Copied!' : 'Copy Script'}
                </span>
              </Button>
            </div>
          </Col>
          {/* <Col xs={24} sm={12} md={6}> */}
          <Col xs={24} sm={12} md={8} lg={6}>
            {adsData?.origin?.provider !== 'meta' && adsData?.video_url ? (
              <Tooltip title="Feature under development">
                <div
                  disabled
                  style={{
                    background: '#DFE3E4',
                    border: '1px solid #DFE3E4',
                    textTransform: 'capitalize',
                    color: '#000000',
                    cursor: 'not-allowed',
                    height: '48px',
                    padding: '12px',
                    textAlign: 'center',
                  }}
                >
                  <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
                  <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Download</span>
                </div>
              </Tooltip>
            ) : (
              <Button
                className={styles.hoverButton}
                style={{
                  width: '100%',
                  border: '1px solid #DFE3E4',
                  textTransform: 'capitalize',
                  color: '#000000',
                }}
                onClick={() => {
                  const trackingPayload = {
                    eventName: 'Download Click',
                    pageName: currentUrl,
                    userStatus: 'Free',
                    sourceName: 'Clicked Download Button',
                    currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
                    fileFormat: '.png,.txt',
                  }
                  accountsTracking(trackingPayload)
                  // eslint-disable-next-line
                  adsData?.origin?.provider === 'meta'
                    ? handleDownloadFilesForMeta()
                    : handleDownloadFilesForGoogle()
                }}
              >
                <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
                <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Download</span>
              </Button>
            )}
          </Col>
          {/* <Col xs={24} sm={12} md={6}> */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Button
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                const trackingPayload = {
                  eventName: 'URL External Redirection Click',
                  pageName: currentUrl,
                  userStatus: 'Free',
                  sourceName: 'Clicked Ad Link Button',
                  adsSourceClick: 'View Ad button on Ads Detail Modal',
                }
                accountsTracking(trackingPayload)
                window.open(adsData?.ad_snapshot_url, '_blank')
              }}
            >
              <img style={{ marginTop: -8, fontSize: 14 }} src={viewIcon} alt="icon" />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Ad Link</span>
            </Button>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            {
              // eslint-disable-next-line
              adsData?.media_type === 'text' ? (
                <div
                  disabled
                  style={{
                    background: '#DFE3E4',
                    cursor: 'not-allowed',
                    border: '1px solid #DFE3E4',
                    textTransform: 'capitalize',
                    // color: '#000000',
                    // cursor: 'not-allowed',
                    height: '48px',
                    padding: '12px',
                    textAlign: 'center',
                  }}
                >
                  <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
                  <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Thumbnail</span>
                </div>
              ) : (
                <Button
                  className={styles.hoverButton}
                  style={{
                    width: '100%',
                    border: '1px solid #DFE3E4',
                    textTransform: 'capitalize',
                    color: '#000000',
                  }}
                  onClick={
                    adsData?.origin?.provider === 'meta'
                      ? handleDownloadThumbnailForMeta
                      : handleDownloadThumbnailForGoogle
                  } // Use the new function
                >
                  <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
                  <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Thumbnail</span>
                </Button>
              )
            }
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 12 }}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <>
              {isSaveLoading || isUnSaveLoading ? (
                <div
                  style={{
                    textAlign: 'center',
                    height: '48px', // Match button height
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  {
                    // eslint-disable-next-line
                    isSaved === 'false' || !isSaved ? (
                      <>
                        <Button
                          type="button"
                          className={styles.hoverButton}
                          style={{
                            width: '100%',
                            border: '1px solid #DFE3E4',
                            textTransform: 'capitalize',
                            color: '#000000',
                          }}
                          onClick={handleSaveAds}
                        >
                          {/* <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" /> */}
                          <PlusOutlined style={{ color: '#F16434' }} />
                          <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                            Save Ad
                          </span>
                        </Button>
                      </>
                    ) : isSaved === 'true' || isSaved ? (
                      <>
                        <Button
                          type="button"
                          className={styles.hoverButton}
                          style={{
                            width: '100%',
                            border: '1px solid #DFE3E4',
                            textTransform: 'capitalize',
                            color: '#000000',
                          }}
                          onClick={handleUnsaveAds}
                        >
                          <MinusOutlined style={{ color: '#F16434' }} />

                          <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>
                            Unsave Ad
                          </span>
                        </Button>
                      </>
                    ) : (
                      ''
                    )
                  }
                </>
              )}
            </>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Actions
