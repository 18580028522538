import {
  TRACKING_PAGE_NAME,
  LAST_LOCATION_URL,
  LAST_SECTION_URL,
  SET_URLS,
  TRACKING_LOCATION,
} from '@utils/constants'

const initialState = {
  trackingPageName: '',
  lastLocationUrl: '',
  lastSectionUrl: '',
  currentUrl: null,
  previousUrl: null,
  trackingLocation: '',
}
export const accountsTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRACKING_PAGE_NAME:
      return { ...state, trackingPageName: action.payload }
    case LAST_LOCATION_URL:
      return { ...state, lastLocationUrl: action.payload }
    case LAST_SECTION_URL:
      return { ...state, lastSectionUrl: action.payload }
    case SET_URLS:
      return {
        previousUrl: state.currentUrl, // Move current to previous
        currentUrl: action.payload, // Update current
      }
    case TRACKING_LOCATION:
      return { ...state, trackingLocation: action.payload }

    default:
      return state
  }
}
