import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import BoardsFeature from './boards-feature'

const Boards = () => {
  const [temp, setTemp] = useState()
  return (
    <div style={{ marginTop: 20, marginLeft: 26, marginRight: 26 }}>
      <BoardsFeature />
    </div>
  )
}

export default Boards
