import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { notify } from '@utils/notify'

const PrivateRoute = ({ component: Component, isAuthenticated, route, ...rest }) => {
  const { t } = useTranslation()
  const user = localStorage.getItem('np_ads_grader:session')
  const userObject = JSON.parse(user)
  const isLeadFormCompleted = userObject?.user?.is_lead_save

  if (isAuthenticated && !isLeadFormCompleted && route.path !== '/lead-form') {
    return <Redirect to="/lead-form" />
  }
  if (!isAuthenticated) {
    notify(t('notification.privateRoute'), 'warning')
    return <Redirect to="/login" />
  }
  return Component
}

export default PrivateRoute
