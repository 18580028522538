import { CloseOutlined, RightOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Col, Divider, Modal, Row, Button, Radio, Input, Select, message, Avatar } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Text from '@components/Text/text'
import { INVITED_BOARDS_USERS } from '@utils/constants'
import shareIcon from '@images/AdsPreview/shareIcon.png'
import React, { useEffect, useState } from 'react'
import straightLine from '@images/DiscoverAds/ShareModal/straightLine.svg'
import { RequestService, boardShareBaseURL } from '@services/requests'
import copyIcon from '@images/DiscoverAds/ShareModal/copyIcon.svg'
import accountsTracking from '@services/accountsTracking'
import InvitedUsersModal from '../InvitedUsersModal'
import styles from './shareModal.module.css'

const { Option } = Select

const ShareModal = ({ modalOpen, handleOk, handleCancel, shareBoardData, handleViewAll }) => {
  const dispatch = useDispatch()
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const [invitedUsers, setInvitedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [shareType, setShareType] = useState('public')
  const [isCopied, setIsCopied] = useState(false)
  const [isInvitedOpen, setIsInvitedOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null) // Initially null to show placeholder
  const [emailError, setEmailError] = useState(null)
  const [roleError, setRoleError] = useState(null)

  // Reset fields when share type changes
  const handleShareTypeChange = e => {
    setShareType(e.target.value)
    // Clear form fields on switch between public and private
    setEmail('')
    setRole(null)
    setEmailError(null)
    setRoleError(null)
  }

  const copyLink = boardId => () => {
    const link = `${boardShareBaseURL}/boards/share-board/public/${boardId}`
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setIsCopied(true)
        message.success('Link copied successfully!') // Success message
      })
      .catch(() => {
        message.error('Failed to copy link') // Error message in case of failure
      })
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handleRoleChange = value => {
    setRole(value)
    setRoleError(null) // Clear error on valid selection
  }

  const validateEmail = mail => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(mail)
  }
  // eslint-disable-next-line
  const validateRole = selectedRole => {
    return !!selectedRole // Ensures a role is selected
  }
  const invitedUsersBoard = async () => {
    try {
      const response = await RequestService.get(
        `/create/boards-invitee/?board_id=${shareBoardData.board_id}`
      )
      dispatch({ type: INVITED_BOARDS_USERS, payload: response?.data })
      setInvitedUsers(response.data)
    } catch (error) {
      // console.log('🚀 ~ useEffect ~ error:', error)
    }
  }
  const handleEmailSubmit = async () => {
    // Reset errors
    setEmailError(null)
    setRoleError(null)

    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.')
      return
    }

    // Validate role
    if (!validateRole(role)) {
      setRoleError('Please select a role.')
      return
    }

    // If both are valid, proceed with the submission
    setIsLoading(true) // Start loading
    const data = {
      // eslint-disable-next-line
      email: email,
      board_id: shareBoardData.board_id,
      // eslint-disable-next-line
      role: role,
    }
    const trackingData = {
      eventName: 'Share Board Click',
      userStatus: 'Free',
      pageName: currentUrl,
      sourceName: 'Clicked Share Button in Dropdown',
      hasBoards: true,
      adResultsCountBoard: shareBoardData.ad_count,
      email,
      // eslint-disable-next-line
      role: role === '2' ? 'Admin' : role === '10' ? 'Editor' : role === '9' ? 'Viewer' : '',
    }
    accountsTracking(trackingData)
    try {
      const response = await RequestService.post('/create/boards-invite/', data)
      if (response.status === 200 || response.status === 201) {
        message.success(`Invitation sent to ${email}`)
        setEmail('') // Reset email input
        setRole(null) // Reset role
        invitedUsersBoard()
        const tracking = {
          eventName: 'Share Board Success',
          userStatus: 'Free',
          pageName: currentUrl,
          sourceName: 'Clicked Share Button in Dropdown',
          hasBoards: true,
          adResultsCountBoard: shareBoardData.ad_count,
          email,
          // eslint-disable-next-line
          role: role === '2' ? 'Admin' : role === '10' ? 'Editor' : role === '9' ? 'Viewer' : '',
        }
        accountsTracking(tracking)
      } else {
        message.error('Failed to send the invitation.')
      }
    } catch (error) {
      message.error('Error occurred while sending the invitation.')
    } finally {
      setIsLoading(false) // Stop loading
    }
  }

  useEffect(() => {
    invitedUsersBoard()
    // eslint-disable-next-line
  }, [])
  return (
    <Modal
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      borderRadius="12px 12px 0px 0px"
      width={440}
      style={{ top: '100px' }}
    >
      <div className={styles.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
          aria-label="Close"
        >
          <CloseOutlined className={styles.closeButton} />
        </button>
      </div>
      <Text isBold style={{ textAlign: 'center', marginTop: '17px' }}>
        Share {shareBoardData?.board_name}
      </Text>
      <div style={{ textAlign: 'center' }}>
        <img src={straightLine} alt="straightLine" />
      </div>
      <div style={{ width: '100%', textAlign: 'center', padding: '24px 26px' }}>
        <Text style={{ textAlign: 'center' }}>
          Sharing a board grants access to all items pinned to it, including sub-boards.
        </Text>
      </div>
      <div style={{ padding: '24px', border: '1px solid #D1D7D9' }}>
        <Radio.Group onChange={handleShareTypeChange} value={shareType}>
          <Radio value="private">
            <Text isBold style={{ marginBottom: '8px' }}>
              Private
            </Text>
            <Text size={14} style={{ color: '#67696B' }}>
              Only team members or users with access can view or edit this board and its sub-boards.
            </Text>
          </Radio>
          <Divider />
          <Radio value="public">
            <Text isBold style={{ marginBottom: '8px' }}>
              Public
            </Text>
            <Text size={14} style={{ color: '#67696B' }}>
              Anyone with the link can view this board and its sub-boards.
            </Text>
          </Radio>
        </Radio.Group>
      </div>

      {/* Conditional rendering based on shareType */}
      {shareType === 'public' ? (
        <div
          style={{
            marginTop: '24px',
            border: '1px solid #D1D7D9',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <div style={{ display: 'flex' }}>
            <img src={copyIcon} alt="copyIcon" style={{ marginLeft: '16px' }} />
            <Text isBold style={{ marginLeft: '8px' }}>
              {boardShareBaseURL}/dashbaord/{shareBoardData.board_id}
            </Text>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '24px' }}>
          <Input.Group compact>
            <Input
              placeholder="Enter email to invite"
              value={email}
              onChange={handleEmailChange}
              style={{ width: 'calc(100% - 100px)', height: '48px' }}
            />
            <Select
              value={role}
              onChange={handleRoleChange}
              style={{ width: 100, height: '48px' }}
              placeholder="Select role" // Placeholder now shows
            >
              <Option value="2">Admin</Option>
              <Option value="10">Editor</Option>
              <Option value="9">Viewer</Option>
            </Select>
          </Input.Group>
          {emailError && <Text style={{ color: 'red', marginTop: '8px' }}>{emailError}</Text>}
          {roleError && <Text style={{ color: 'red', marginTop: '8px' }}>{roleError}</Text>}
        </div>
      )}

      <div style={{ marginTop: '24px' }}>
        {shareType === 'public' ? (
          <Button type="primary" block onClick={copyLink(shareBoardData?.board_id)}>
            Copy Link
          </Button>
        ) : (
          <Button
            type="primary"
            block
            onClick={handleEmailSubmit}
            disabled={!email || !role} // Disable button if email or role is missing
          >
            Send Invite
          </Button>
        )}
      </div>
      {invitedUsers?.length > 0 && (
        <>
          <Text size={14} className={styles.invitedMembers}>
            Invited Members
          </Text>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              marginTop: '24px',
            }}
          >
            {invitedUsers?.length > 0 && (
              <div style={{ marginRight: '50px' }}>
                {
                  // eslint-disable-next-line
                  invitedUsers.slice(0, 3).map((user, index) => {
                    return (
                      <Avatar
                        // eslint-disable-next-line
                        key={index}
                        size={40}
                        style={{
                          backgroundColor:
                            // eslint-disable-next-line
                            index === 0 ? '#D1D5DB' : index === 1 ? '#FED7D7' : '#FEB2B2',
                          color: '#FFF',
                          position: index >= 0 ? 'relative' : 'static',
                          right: `${index * 9}px`,
                          border: '2px solid white',
                          fontWeight: 'bolder',
                        }}
                      >
                        {user?.user?.first_name?.charAt(0).toUpperCase() || (
                          <UserOutlined style={{ color: 'white' }} />
                        )}
                      </Avatar>
                    )
                  })
                }
              </div>
            )}

            <div>
              <Text size={14} className={styles.viewAll} onClick={handleViewAll}>
                View All
                <RightOutlined style={{ color: '#f16434', marginLeft: '12px' }} color="#f16434" />
              </Text>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ShareModal
