import { Table, Dropdown, Menu, Modal, Spin, message, Avatar, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  EllipsisOutlined,
  DeleteOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  SPECIFIC_BRAND_DETAIL,
  SEARCH_SUGGESTIONS,
  SEARCH_SUGGESTIONS_FOR_BRANDS,
  MEDIA_TYPE,
} from '@utils/constants'
import { RequestService, bucketBaseURL } from '@services/requests'
import accountsTracking from '@services/accountsTracking'
import Text from '@components/Text/text'
import styles from './BrandsList.module.css'

const BrandsList = ({ brands, setBrands }) => {
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [brandToDelete, setBrandToDelete] = useState(null)

  const navigateToBrandDetails = (record, mediaType) => {
    if (record.adCount <= 0) {
      message.warning('Data/Ads for this brand will be pulled out in 24 hours.')
      return
    }

    // Media type checks with specific messages
    if (mediaType === 'image' && record.images === 0) {
      message.warning('Images for this brand will be pulled out in 24 hours.')
      return
    }

    if (mediaType === 'video' && record.videos === 0) {
      message.warning('Videos for this brand will be pulled out in 24 hours.')
      return
    }

    if (mediaType === 'text' && record.text === 0) {
      message.warning('Text content for this brand will be pulled out in 24 hours.')
      return
    }

    // Proceed with dispatch and navigation if conditions pass
    dispatch({ type: SPECIFIC_BRAND_DETAIL, payload: record })
    dispatch({ type: MEDIA_TYPE, payload: mediaType })
    const trackingPayload = {
      eventName: 'Follow Brand Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Brand From Brands List',
      currentTab: 'All',
      brandName: record.name,
    }
    accountsTracking(trackingPayload)
    history.push('/dashboard/brands/detail')
  }

  const showDeleteBrandModal = brand => {
    const trackingPayload = {
      eventName: 'Unfollow Brand Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked UnFollow Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: brand?.name,
    }
    accountsTracking(trackingPayload)
    setBrandToDelete(brand)
    setDeleteModalVisible(true)
  }
  const fetchSearchSuggestions = async () => {
    try {
      // setLoading(true)
      const response = await RequestService.get('/save/brands/')
      dispatch({
        type: SEARCH_SUGGESTIONS,
        payload: response?.data,
      })
      dispatch({
        type: SEARCH_SUGGESTIONS_FOR_BRANDS,
        payload: [...response?.data.meta_brands, ...response?.data.google_brands],
      })
    } finally {
      // setLoading(false)
    }
  }
  const confirmDeleteBrand = async brandData => {
    try {
      setLoadingDelete(true)
      await RequestService.delete(`/save/follow-brands/?brand_id=${brandToDelete?.id}`)
      setBrands(brands.filter(brand => brand.key !== brandToDelete.key))
      message.success('Unfollow successful')
      fetchSearchSuggestions()
      const tracking = {
        eventName: 'Unfollow Brand Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked UnFollow Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
        brandName: brandData?.name,
      }
      accountsTracking(tracking)
    } finally {
      setLoadingDelete(false)
      setDeleteModalVisible(false)
    }
  }

  const handleCancelDelete = () => {
    setDeleteModalVisible(false)
  }

  const menu = record => (
    <Menu>
      <Menu.Item
        style={{ width: 100 }}
        key="delete"
        onClick={() => showDeleteBrandModal(record)}
        // icon={<DeleteOutlined style={{ color: '#f16434' }} />}
      >
        <span style={{ color: '#f16434', marginTop: 3, fontWeight: 'bold', textAlign: 'center' }}>
          Unfollow
        </span>
      </Menu.Item>
    </Menu>
  )

  const columns = [
    {
      title: () => (
        <div className={styles.title_div}>
          <Text style={{ marginLeft: 20 }} className={styles.title_content}>
            Name
          </Text>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.logo ? (
            <img
              src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${record.logo}`}
              alt={`${record.name} logo`}
              style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 8 }}
            />
          ) : (
            <div style={{ marginRight: 8 }} />
          )}

          <Text
            className={styles.follow_brands_list}
            style={{ padding: '0 1rem 0 0' }}
            onClick={() => navigateToBrandDetails(record, 'All')}
          >
            {text}
          </Text>
        </div>
      ),
    },
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>Ad Count</Text>
        </div>
      ),
      dataIndex: 'adCount',
      key: 'adCount',
      render: (text, record) => (
        <Text
          style={{ color: '#f16434', cursor: 'pointer' }}
          onClick={() => navigateToBrandDetails(record, 'All')}
        >
          {text.toLocaleString()}
        </Text>
      ),
    },

    {
      title: () => (
        <div className={styles.title_div} id="images_tooltip">
          <div className={styles.title_content}>
            <Tooltip
              title="Images Count"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById('images_tooltip')}
              placement="top"
            >
              <FileImageOutlined style={{ color: 'black', fontSize: 19, cursor: 'pointer' }} />
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'images',
      key: 'images',
      render: (text, record) => (
        <Text
          onClick={() => navigateToBrandDetails(record, 'image')}
          style={{ color: '#f16434', cursor: 'pointer' }}
        >
          {text.toLocaleString()}
        </Text>
      ),
    },

    {
      title: () => (
        <div className={styles.title_div} id="video_tooltip">
          <div className={styles.title_content}>
            <Tooltip
              title="Videos Count"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById('video_tooltip')}
              placement="top"
            >
              <VideoCameraOutlined style={{ color: 'black', fontSize: 19, cursor: 'pointer' }} />
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'videos',
      key: 'videos',
      render: (text, record) => (
        <Text
          onClick={() => navigateToBrandDetails(record, 'video')}
          style={{ color: '#f16434', cursor: 'pointer' }}
        >
          {text.toLocaleString()}
        </Text>
      ),
    },
    {
      title: () => (
        <div className={styles.title_div} id="text_tooltip">
          <div className={styles.title_content}>
            <Tooltip
              title="Text Count"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById('text_tooltip')}
              placement="top"
            >
              <FileTextOutlined style={{ color: 'black', fontSize: 19, cursor: 'pointer' }} />
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => (
        <Text
          onClick={() => navigateToBrandDetails(record, 'text')}
          style={{ color: '#f16434', cursor: 'pointer' }}
        >
          {text.toLocaleString()}
        </Text>
      ),
    },

    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>Last Updated At</Text>
        </div>
      ),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (text, record) => (
        <Text
          onClick={() => navigateToBrandDetails(record, 'All')}
          style={{ color: '#808080', cursor: 'pointer' }}
        >
          {text ?? <div style={{ marginLeft: 30 }}>- - -</div>}
        </Text>
      ),
    },
    {
      title: () => (
        <div className={`${styles.title_div} ${styles.title_content}`} style={{ marginTop: 23 }}>
          {/* <Text className={styles.title_content}>Unfollow</Text> */}
        </div>
      ),
      key: 'actions',
      render: (_, record) => (
        <div id="deleteDropdownFollowBrands">
          <Dropdown
            overlay={menu(record)}
            trigger={['click']}
            getPopupContainer={() => document.getElementById('deleteDropdownFollowBrands')}
          >
            <EllipsisOutlined rotate={90} style={{ fontSize: '1.5em', cursor: 'pointer' }} />
          </Dropdown>
        </div>
      ),
    },
  ]

  return (
    <div style={{ marginTop: 24 }}>
      {brands.length > 0 ? (
        <Table
          style={{ border: '1px solid rgb(217, 217, 217)' }}
          columns={columns}
          dataSource={brands}
          id="followBrandsCustomTable"
        />
      ) : (
        <>
          <Text size={24} isBold style={{ marginTop: 70, textAlign: 'center', color: '#f16434' }}>
            No Brands Found.
          </Text>
          <Text size={14} style={{ textAlign: 'center', color: 'gray' }}>
            Search and follow your favourite brands to see
          </Text>
          <Text size={14} style={{ textAlign: 'center', color: 'gray' }}>
            their latest ads appear here.
          </Text>
        </>
      )}

      <Modal
        // title="Confirm Delete"
        visible={isDeleteModalVisible}
        onOk={() => confirmDeleteBrand(brandToDelete)}
        onCancel={handleCancelDelete}
        okText="Unfollow"
        cancelText="Cancel"
        confirmLoading={loadingDelete}
      >
        Are you sure you want to unfollow <strong>{brandToDelete?.name}</strong>?
      </Modal>
    </div>
  )
}

export default BrandsList
