import React, { useState } from 'react'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import { RequestService } from '@services/requests'
import accountsTracking from '@services/accountsTracking'
import Text from '@components/Text/text'
import { Input, Button, Modal } from 'antd'
import { BOARDS_LIST, BOARDS_LIST_COUNT, ACTIVE_KEY } from '@utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setBoardsSearchKeyword, addBoard } from '../../../store/actions'
import styles from './boardsNavbar.module.css'

const BoardsNavbar = () => {
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newBoardName, setNewBoardName] = useState('')
  const boardsListCount = useSelector(state => state?.discoverAds?.sharedAndPrivateBoardsCount)

  const dispatch = useDispatch()

  const handleKeywordSearch = e => {
    const searchString = e.target.value
    dispatch(setBoardsSearchKeyword(searchString)) // Dispatch the search string to the Redux store
  }

  const showModal = () => {
    const trackingPayload = {
      eventName: 'Create a Board Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Create New Board Button',
    }
    accountsTracking(trackingPayload)
    setIsModalVisible(true)
  }

  const handleOk = async () => {
    const data = {
      name: newBoardName,
    }
    try {
      await RequestService.post('/create/boards/', data)
      dispatch(addBoard(newBoardName.trim()))
      setNewBoardName('')
      setIsModalVisible(false)
      dispatch({ type: ACTIVE_KEY, payload: '1' })
      const trackingPayload = {
        eventName: 'Create a Board Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Create Board Button',
        boardName: newBoardName,
      }
      accountsTracking(trackingPayload)
    } catch (error) {
      // console.log('🚀 ~ hanelApiCall ~ error:', error)
    }
    try {
      const response = await RequestService.get('/create/board-ads/')
      dispatch({ type: BOARDS_LIST, payload: response?.data?.boards })
      dispatch({ type: BOARDS_LIST_COUNT, payload: response.data.boards.length })

      // setLoading(false)
    } catch (error) {
      // console.log('🚀 ~ handleOk ~ error:', error)
      // setLoading(false)
    }
  }

  const handleCancel = () => {
    setNewBoardName('')
    setIsModalVisible(false)
  }

  return (
    <div className={styles.navbar}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <h1 className={styles.title}>All Boards</h1>
        <div className={styles.counterContainer}>
          <Text size={14} isBold>
            {boardsListCount}
          </Text>
        </div>
      </div>

      <div className={styles.inputContainer}>
        <Input
          allowClear
          id="keywordSearchInput"
          className={styles.inputText}
          placeholder="Search board"
          prefix={<img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />}
          onChange={handleKeywordSearch}
        />
        <Button type="primary" className={styles.createBoardBtn} onClick={showModal}>
          CREATE NEW BOARD
        </Button>
      </div>

      <Modal
        title="Create New Board"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
        cancelText="Cancel"
      >
        <Input
          placeholder="Enter board name"
          value={newBoardName}
          onChange={e => setNewBoardName(e.target.value)}
        />
      </Modal>
    </div>
  )
}

export default BoardsNavbar
