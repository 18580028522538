import React, { useState } from 'react'
import { bucketBaseURL } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import {
  PAGE_ID_AND_ADVERTISER_ID,
  TRIGGER_SEARCH,
  INPUT_VALUES,
  BRAND_CHOSEN,
  ADS_SEARCH_KEYWORD,
  TRACKING_PAGE_NAME,
} from '@utils/constants'
import accountsTracking from '@services/accountsTracking'
import CreateYourAccount from '@components/CreateYourAccount'
import styles from './BrandTags.module.css'

const BrandTags = ({ image, text, isFirstRow, categryData }) => {
  const [signUpModal, setSignUpModal] = useState(false)
  const apiHitsCounter = useSelector(state => state?.UnAuthDiscoverAds?.apiHitsCounter)
  const dispatch = useDispatch()

  const handleClick = () => {
    if (apiHitsCounter < 1) {
      dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
      dispatch({ type: TRIGGER_SEARCH, payload: true })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
      dispatch({ type: BRAND_CHOSEN, payload: true })
    } else {
      setSignUpModal(true)
      const trackingPayload = {
        pageName: 'Discover Ads Landing Page',
        eventName: 'Sign In Page View',
        sourceName: 'Clicked Brands Tag button',
      }
      accountsTracking(trackingPayload)
      dispatch({
        type: TRACKING_PAGE_NAME,
        payload: 'Home Page',
      })
      dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
    }
  }
  const handleCloseModal = () => {
    setSignUpModal(false)
  }
  return (
    <>
      <div
        style={{
          marginLeft: isFirstRow ? '20px' : '0', // Add margin for the first row
          display: 'flex',
        }}
      >
        <button
          onClick={handleClick}
          type="button"
          className={styles.button}
          style={{ display: 'flex' }}
        >
          {image && (
            <img
              src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${image}`}
              alt={`${image}-logo`}
              width={20}
              height={20}
              style={{ borderRadius: '50%' }} // Ensure the logo is circular
            />
          )}

          <span>{text}</span>
        </button>
      </div>
      {signUpModal ? (
        <CreateYourAccount isModalVisible={signUpModal} onCancel={handleCloseModal} />
      ) : (
        ''
      )}
    </>
  )
}

export default BrandTags
