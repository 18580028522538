import React, { memo, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Progress, Card } from 'antd'
import InsightsCard from '../FollowedBrandsInsights/InsightsCard'
import styles from './TabContent.module.css'
import LandingPages from '../FollowedBrandsInsights/LandingPages'
import Headlines from '../FollowedBrandsInsights/Headlines'
import AdCopy from '../FollowedBrandsInsights/AdCopy'
import Hooks from '../FollowedBrandsInsights/Hooks'
import MediaMix from '../FollowedBrandsInsights/MediaMix'
import AdsActivity from '../FollowedBrandsInsights/AdsActivity'

const TabContent = memo(
  ({ activeTab, videoCount = 0, imageCount = 0, textCount = 0, totalAds = 0 }) => {
    const dispatch = useDispatch()
    const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)

    const mediaMixData = useMemo(() => {
      const total = specificBrandDetail.adCount
      return {
        video: {
          count: videoCount,
          percentage: total ? (specificBrandDetail.videos / total) * 100 : 0,
        },
        image: {
          count: imageCount,
          percentage: total ? (specificBrandDetail.images / total) * 100 : 0,
        },
        text: {
          count: textCount,
          percentage: total ? (specificBrandDetail.text / total) * 100 : 0,
        },
      }
      // eslint-disable-next-line
    }, [videoCount, imageCount, textCount, totalAds])

    const renderMediaMix = () => {
      const mediaData = [
        {
          type: 'Video',
          count: specificBrandDetail.videos,
          percentage: mediaMixData.video.percentage,
          color: '#7B2BFF',
        },
        {
          type: 'Image',
          count: specificBrandDetail.images,
          percentage: mediaMixData.image.percentage,
          color: '#4A9FFF',
        },
        {
          type: 'Text',
          count: specificBrandDetail.text,
          percentage: mediaMixData.text.percentage,
          color: '#FF6B6B',
        },
      ]

      const gradientStyle = `linear-gradient(to right, 
      #7B2BFF 0%, 
      #7B2BFF ${mediaMixData.video.percentage}%, 
      #4A9FFF ${mediaMixData.video.percentage}%, 
      #4A9FFF ${mediaMixData.video.percentage + mediaMixData.image.percentage}%, 
      #FF6B6B ${mediaMixData.video.percentage + mediaMixData.image.percentage}%, 
      #FF6B6B 100%
    )`

      return (
        <Card className={styles.mediaMixCard}>
          <h3 className={styles.cardTitle}>Media Mix</h3>
          <div className={styles.mediaContent}>
            {mediaData.map(item => (
              <div key={item.type} className={styles.mediaItem}>
                <div className={styles.mediaLabel}>
                  <div className={styles.mediaDot} style={{ background: item.color }} />
                  {item.type}
                </div>
                <div className={styles.mediaStats}>
                  <span className={styles.mediaCount}>{item.count}</span>
                  <span className={styles.mediaPercentage}>{item.percentage.toFixed(1)}%</span>
                </div>
              </div>
            ))}
            <div className={styles.progressContainer}>
              <Progress
                percent={100}
                showInfo={false}
                strokeWidth={8}
                strokeColor={gradientStyle}
              />
            </div>
          </div>
        </Card>
      )
    }

    switch (activeTab) {
      case 'overview':
        return (
          <div className={styles.overviewContainer}>
            <InsightsCard />
            {renderMediaMix()}
          </div>
        )
      case 'hooks':
        return <Hooks />
      case 'adCopy':
        return <AdCopy />
      case 'headlines':
        return <Headlines />
      case 'landingPages':
        return <LandingPages />
      case 'mediaMix':
        return <MediaMix />
      case 'adsActivity':
        return <AdsActivity />

      default:
        return null
    }
  }
)

TabContent.displayName = 'TabContent'

export default memo(TabContent)
