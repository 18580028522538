/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState, useMemo, memo, useRef } from 'react'
import { Col, Row, Button, Tabs, Space, Popover, Card, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import useClipboard from 'react-use-clipboard'
import { useLocation, useParams } from 'react-router-dom'
import { ResponsiveFunnel } from '@nivo/funnel'
import NumberFormat from 'react-number-format'
import { FaQuestionCircle } from 'react-icons/fa'
import { sections } from 'pages/dashboard'
import {
  EXTRA_INSIGHTS_CLICK,
  EXTRA_INSIGHTS_DATA,
  LS_KEY,
  PERFORMANCE_SECTION,
  SHARE_WITH_TEAM_SECTION,
} from 'utils/constants'
import classNames from 'classnames/bind'
import ManageUsers from 'components/Tabs/tabContent/manageUsers'
import { SecretService } from '@services/secret'
import { Text, PerformanceCard, Quote, CTACard, CTABanner, InformationCard } from '@components'
import NeilSection from '@images/neil-userful-section.png'
import { AG_AC_TOKEN } from '@utils/constants'
import AlertArrow from '@images/alert-arrow.svg'
import ImprovementCard from '@components/ImprovementCard'
import { fetchInsights } from 'services/fetchInsights'
import { notify } from 'utils/notify'
import ApexChart from 'react-apexcharts'
import { CaretDownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { reportIndex, singleReport } from 'store/actions'
import Footer from '../Footer'
import { Header } from '../../pages/report/sections/Header'
import graphImg from '../../images/neil-face.png'
import {
  getCTR,
  formatCurrency,
  getPercentage,
  doubleDecimals,
  getBenchmarkProp,
} from '../../utils'
import { RequestService, axiosInstance } from '../../services/requests'

import styles from '../../pages/report/report.module.scss'
import { OverallSectionTemporary } from './OverallTemporary'
import CampaignMetricsModal from './CampaignMetricsModal'

const { TabPane } = Tabs

const observerOptions = {
  root: null,
  rootMargin: '0px',
}
const requiredRoles = ['Team Member', 'Billing']

const ReportPageTemporary = ({
  selectedReportData,
  setCurrentSection,
  reportDashboardPage,
  copiedReportName,
  copiedCustomerId,
}) => {
  const lastSectionUrl = useSelector(state => state?.AccountsTrackingReducer?.lastSectionUrl)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  //   const { reportToken } = useParams()

  let reportToken = ''
  const allreports = useSelector(state => state.weeklyReport.allReport)

  const getTokenFromAGACTOKEN = localStorage.getItem(AG_AC_TOKEN)
  const getTokenFromNPAdsGraderSession = JSON.parse(localStorage.getItem(LS_KEY))
  if (getTokenFromAGACTOKEN) {
    reportToken = getTokenFromAGACTOKEN
  } else {
    reportToken = getTokenFromNPAdsGraderSession.access_token
  }
  const customerName = useSelector(state => state?.customer?.descriptive_name)
  const customerId = useSelector(state => state?.customer?.id)

  const reportUniqueId = useSelector(state => state?.reportDashboard)
  // eslint-disable-next-line
  const report_id = selectedReportData?.id
  const extraInsightsButton = useSelector(state => state?.extraInsights?.extraInsightsButton)
  const weeklyReport = useSelector(state => state?.weeklyReport?.weeklyReports)
  const [graphData, setGraphData] = useState(weeklyReport)
  const reportPayload = useMemo(() => SecretService.decode(reportToken), [reportToken])
  const [report, setReport] = useState({ loading: true, error: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)
  const [extraInsights, setExtraInsights] = useState([])
  const [benchmarks, setBenchmarks] = useState([])
  const [copyUpdate, setCopyUpdate] = useState(false)

  const [isCopied, setCopied] = useClipboard(
    `${window.location.origin}/login/?reportId=${reportUniqueId}&customerId=${customerId}`
  )

  const [viewQtd, setViewQtd] = useState(0)
  const [formattedImprovement, setFormattedImprovement] = useState([])

  const dummyWeekly = [
    {
      latestReport: {
        created_at: '2024-07-24T13:26:32.575361Z',
        general_score: 60.1,
        id: 10,
        is_glue_report: false,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-07-17T10:12:45.329580Z',
        general_score: 40.12,
        id: 7937,
        is_glue_report: true,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-07-10T09:11:44.329580Z',
        general_score: 75.5,
        id: 7938,
        is_glue_report: false,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-06-22T08:10:43.329580Z',
        general_score: 85.75,
        id: 7939,
        is_glue_report: true,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-06-15T07:09:42.329580Z',
        general_score: 90.0,
        id: 7940,
        is_glue_report: false,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-06-02T06:08:41.329580Z',
        general_score: 65.33,
        id: 7941,
        is_glue_report: true,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-05-25T05:07:40.329580Z',
        general_score: 70.22,
        id: 7942,
        is_glue_report: false,
        dummy_report: true,
      },
    },
    {
      latestReport: {
        created_at: '2024-05-18T13:26:32.575361Z',
        general_score: 20.12,
        id: 7943,
        is_glue_report: true,
        dummy_report: true,
      },
    },
  ]

  useEffect(() => {
    if (!weeklyReport || weeklyReport.length === 0) {
      // eslint-disable-next-line
      if (report?.data?.customer_id == '123456789') {
        setGraphData(dummyWeekly)
      } else {
        // eslint-disable-next-line
        if (report?.data?.id) {
          setGraphData([{ latestReport: report?.data }])
        }
      }
    } else {
      setGraphData(weeklyReport)
    }
    // eslint-disable-next-line
  }, [weeklyReport, report])

  const retrieveReport = useCallback(async () => {
    try {
      let reportData
      if (pathname.match(/ad-report/)) {
        const { data } = await axiosInstance.get(`/reports/${reportPayload.uuid}/`)

        reportData = data
      } else {
        const { data } = await RequestService.post('/reports/', {
          customer_id: reportPayload.customerId,
          parent_customer_id: reportPayload.parentId,
        })
        reportData = data
      }
      setReport({ loading: false, error: false, data: reportData })
    } catch (e) {
      setReport({ loading: false, error: e, data: {} })
    }
  }, [reportPayload, pathname])
  const openEmail = () => {
    const email = 'marketing@npaccel.com'
    const subject = 'Case Study'
    const body = ''
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`,
      '_blank'
    )
  }
  const getBenchmarks = useCallback(async () => {
    try {
      let benchmarksData
      if (pathname.match(/ad-report/)) {
        const { data } = await axiosInstance.get('/benchmarks/')

        benchmarksData = data
      } else {
        const { data } = await RequestService.get('/benchmarks/')

        benchmarksData = data
      }

      setBenchmarks(benchmarksData)
    } catch (e) {
      setBenchmarks([])
    }
  }, [pathname])
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    // eslint-disable-next-line
  }, [report_id])
  const onTabClick = key => {
    if (key === '5') {
      if (extraInsightsButton) {
        setIsLoading(true)
        dispatch({ type: EXTRA_INSIGHTS_DATA, payload: [] })
        fetchInsights(report_id)
          .then(res => {
            dispatch({ type: EXTRA_INSIGHTS_DATA, payload: res?.data })
            setIsLoading(false)
            if (res?.data?.customer_id) {
              dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: false })
            }
          })
          .catch(error => {
            notify(error, 'error')
            setIsLoading(false)
          })
      }
      showModal()
    }
  }
  // const handleIntersection = entries => {
  //   let detectedEl = null
  //   entries.forEach(entry => {
  //     if (entry.isIntersecting) {
  //       detectedEl = entry.target.id
  //     }
  //   })
  //   if (detectedEl) {
  //     setCurrentSection(detectedEl)
  //   } else {
  //     setCurrentSection(null)
  //   }
  // }

  // useEffect(() => {
  //   const observer = new IntersectionObserver(handleIntersection, observerOptions)
  //   sections.forEach(item => {
  //     const element = document.getElementById(item.key)
  //     if (element) {
  //       observer.observe(element)
  //     }
  //   })

  //   return () => {
  //     observer.disconnect()
  //   }
  //   // eslint-disable-next-line
  // }, [selectedReportData])

  const newFormateImprovement = array => {
    const result = []
    let subArray = []

    for (let i = 0; i < array.length; i += 1) {
      subArray.push(array[i])

      if (subArray.length === 4 || i === array.length - 1) {
        result.push(subArray)
        subArray = []
      }
    }

    return result
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  useEffect(() => {
    if (typeof report.data.recommendations === 'object' && report.data.recommendations !== null)
      setFormattedImprovement(newFormateImprovement(report.data.recommendations))
  }, [report.data.recommendations])
  const handleImprovementMore = () => {
    setViewQtd(viewQtd + 1)
  }
  useEffect(() => {
    if (selectedReportData) {
      setReport(pre => ({ ...pre, data: selectedReportData }))
    }
  }, [selectedReportData])

  // eslint-disable-next-line
  const handleOpenCtaUrl = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      let url = `${process.env.REACT_APP_CTA_URL}/?`
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
      }

      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log('🚀 ~ getBenchmarks ~ e:', e)
    }
  }

  //   if (report.loading && !report.error)
  //     return (
  //       <Header>
  //         <>
  //           <Text size={40} className={styles.templateTitle} color="white">
  //             {t('report.loadingReportTitle')}
  //           </Text>

  //           <Row>
  //             <Col
  //               xs={{ span: 20, offset: 2 }}
  //               md={{ span: 12, offset: 6 }}
  //               xl={{ span: 8, offset: 8 }}
  //             >
  //               <Card className={styles.templateCard}>
  //                 <div className={styles.loadingCard}>
  //                   <Spin />
  //                 </div>
  //               </Card>
  //             </Col>
  //           </Row>
  //         </>
  //       </Header>
  //     )

  //   if (!report.loading && report.error)
  //     return (
  //       <div className={styles.pageWrapper}>
  //         <Header>
  //           <div className={styles.templateTitle}>
  //             <Text size={40} color="white">
  //               <FiAlertTriangle />
  //               <br />
  //               {t('report.generateReportError')}
  //             </Text>
  //             <Text size={11} color="white">
  //               {report.error.message === 'Unauthorized'
  //                 ? 'Session expired please login again'
  //                 : report.error.message}
  //             </Text>
  //           </div>
  //         </Header>
  //       </div>
  //     )

  //   if (!report.data.overall_spend || report.data.overall_spend < 1) {
  //     return (
  //       <div className={styles.pageWrapper}>
  //         <Header>
  //           <div style={{ width: '60%', margin: '0 auto' }}>
  //             <div className={styles.templateTitle}>
  //               <Text size={28} color="white">
  //                 {t('report.noOverallSpendError.text1')}
  //               </Text>
  //               <Text size={14} color="white">
  //                 {t('report.noOverallSpendError.text2')}
  //               </Text>
  //             </div>
  //           </div>
  //           <Row>
  //             <Col
  //               xs={{ span: 20, offset: 2 }}
  //               md={{ span: 12, offset: 6 }}
  //               xl={{ span: 8, offset: 8 }}
  //             >
  //               <Card className={styles.templateCard}>
  //                 <div className={styles.loadingCard}>
  //                   <Text size={11} color="#202020">
  //                     {t('report.noOverallSpendError.text3')}
  //                   </Text>
  //                   <br />
  //                   <a
  //                     target="_blank"
  //                     href="/report-demo"
  //                     className="ant-btn ant-btn-primary ant-btn-block"
  //                   >
  //                     See Demo Report
  //                   </a>
  //                 </div>
  //               </Card>
  //             </Col>
  //           </Row>
  //         </Header>
  //       </div>
  //     )
  //   }
  // console.log('report................', report)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false)
  const [popoverDisabled, setPopoverDisabled] = useState(false)
  const reportCurrency = useSelector(state => state?.customer?.currency_code)

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }
  const togglePopover2 = () => {
    setIsPopoverOpen2(!isPopoverOpen2)
  }
  const a = requiredRoles?.includes(JSON.parse(localStorage.getItem('role')))
  const userRole = useSelector(state => state?.role?.role)
  useEffect(() => {
    if (a) {
      setPopoverDisabled(true)
    }
    // eslint-disable-next-line
  }, [userRole])
  const CopyUrl = () => {
    setCopied(true)
    setCopyUpdate(true)
  }
  useEffect(() => {
    setCopyUpdate(false)
  }, [report])

  const [startIndex, setStartIndex] = useState(0)
  const itemsPerPage = 8

  const generateForecastData = existingData => {
    if (existingData.length > 0 && startIndex === 0) {
      const lastValue = existingData[existingData.length - 1]
      const forecastData = []
      for (let i = 0; i < existingData.length - 1; i += 1) {
        forecastData.push(null)
      }
      forecastData.push(lastValue)
      for (let i = 0; i < 7; i += 1) {
        if (i === 6) {
          forecastData.push(100)
        } else {
          forecastData.push(null)
        }
      }
      return forecastData
    }
    return []
  }
  const [prevBtnTooltip, setPrevBtnTooltip] = useState(false)
  useEffect(() => {
    if (startIndex !== 0) {
      setPrevBtnTooltip(true)
    } else if (startIndex === 0) {
      setPrevBtnTooltip(false)
    }
  }, [startIndex])
  const [dataPoint, setDataPoint] = useState(true)
  const [reportButtonClicked, setReportButtonClicked] = useState(false)
  const [firstRendedTime, setFrstRendedTime] = useState()
  const [reportSplit, setReportSplit] = useState()
  const [reportDiff, setReportDiff] = useState(0)
  const [reportTime, setReportTime] = useState([])
  const [showMountedTooltip, setShowMountedTooltip] = useState(false)
  const [scores, setScores] = useState([])
  const [createdAt, setCreatedAt] = useState([])
  const [firstRendedDate, setFirstRendedDate] = useState()
  const [hoveredValueForOuterDiv, setHoveredValueForOuterDiv] = useState(0)
  const [glueReport, setGlueReport] = useState([])
  const [slicedGlueReport, setSllcedGlueReport] = useState([])
  const [slicedId, setSlicedId] = useState([])
  const reportCurrentId = useSelector(state => state?.weeklyReport?.id)
  const [reportSelect, setReportSelect] = useState(reportCurrentId)
  const [reportId, setReportId] = useState([])
  const chartDataRef = useRef({
    xAxis: 0,
    yAxis: 0,
    hoveredDate: 0,
    hoveredValue: 0,
    time: 0,
    glueSlice: 0,
    reportEventSingleId: 0,
  })
  useEffect(() => {
    if (chartDataRef.current.reportEventSingleId) {
      const singleReportie = allreports?.find(
        item => item.id === chartDataRef.current.reportEventSingleId
      )
      setReportSelect(singleReportie?.id)
    }

    // eslint-disable-next-line
  }, [reportSelect])
  useEffect(() => {
    if (graphData?.length > 0) {
      const newScores = []
      const time = []
      const createdAts = []
      const glueReports = []
      const innerId = []
      for (let index = 0; index < graphData?.length; index += 1) {
        newScores.push(graphData[index]?.latestReport?.general_score)
        const formattedDate = dayjs(graphData[index]?.latestReport?.created_at)?.format(
          'MMM DD YYYY'
        )
        createdAts.push(formattedDate)

        const formattedTime = dayjs(graphData[index]?.latestReport.created_at)?.format('h:mm A')
        time.push(formattedTime)
        const glue = graphData[index]?.latestReport?.is_glue_report
        glueReports.push(glue)
        const id = graphData[index]?.latestReport?.id
        innerId.push(id)
      }
      setSlicedId(innerId)
      setGlueReport(glueReports)
      setScores(newScores)
      setReportTime(time)
      setCreatedAt(createdAts)
    }
  }, [graphData])
  const [onlyDate, setOnlyDate] = useState(0)
  useEffect(() => {
    if (report) {
      const date = dayjs(report?.data?.created_at).format('MMM DD YYYY')
      setFirstRendedDate(date)
      const onlyDateDiv = dayjs(report?.data?.created_at).format('MMM DD')
      setOnlyDate(onlyDateDiv)

      const time = dayjs(report?.data?.created_at).format('h:mm A')
      setFrstRendedTime(time)
    }
  }, [report])

  const endIndex = startIndex + itemsPerPage

  useEffect(() => {
    const slice = reportTime.reverse()
    setReportSplit(slice)
    const glueSlice = glueReport.reverse()
    setSllcedGlueReport(glueSlice)
    const getIdreport = slicedId.reverse()
    setReportId(getIdreport)
  }, [scores, reportTime, glueReport, slicedId])
  const [mountX, setMountX] = useState(0)
  const [mountY, setMountY] = useState(0)
  const openCtaUrl = useCallback(() => {
    window.open('https://advanced.npdigital.com/consulting-ads/', '_blank')
  }, [])
  // eslint-disable-next-line
  const [test, setTest] = useState({})
  const chartOptions = {
    chart: {
      events: {
        // eslint-disable-next-line
        mouseMove: function (event, chartContext, opts) {
          if (graphData.length > 1 && weeklyReport.length !== 0) {
            setDataPoint(false)
            setShowMountedTooltip(true)
          } else if (graphData[0]?.latestReport?.id === 10) {
            setDataPoint(false)
            setShowMountedTooltip(true)
          }
        },
        // eslint-disable-next-line
        mounted: function (chartContext, config) {
          setDataPoint(false)
          const chartElement = chartContext?.el
          const seriesPoints = chartElement?.querySelectorAll('.apexcharts-series-markers circle')
          const lastSeriesPoint = seriesPoints[seriesPoints.length - 8]

          if (lastSeriesPoint) {
            const chartRect = chartElement?.getBoundingClientRect()
            const pointRect = lastSeriesPoint.getBoundingClientRect()
            const x = pointRect?.left - chartRect?.left
            setMountX(x)
            setMountY(chartRect.top)
          }
        },
      },

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: 2,
      lineCap: 'butt',
      colors: report.data.general_score <= 99 ? ['#FF5E29', '#7BAAF7'] : ['#FF5E29', '#00A862'],
      dashArray: [0, 5],
    },

    xaxis: {
      categories: createdAt.slice(startIndex, endIndex).reverse(),
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: '#636363',
          fontFamily: '"geomanist-regular", sans-serif',
          fontSize: '11px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: val => `${val}%`,
        style: {
          colors: '#636363',
          fontFamily: '"geomanist-regular", sans-serif',
          fontSize: '11px',
        },
      },
      min: 0,
      max: 100,
      stepSize: 25,
      tickAmount: 4,
    },
    series: [
      {
        name: 'Ads Grader Performance',
        data: scores?.slice(startIndex, endIndex).reverse(),
        type: 'area',
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: 'Forecast',
        data: generateForecastData(scores?.slice(startIndex, endIndex).reverse()),
        type: 'area',
        dataLabels: {
          enabled: false,
        },
      },
    ],

    tooltip: {
      enabled: true,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const dataPoints = w?.globals?.dom?.baseEl?.querySelectorAll('.apexcharts-marker')[
          dataPointIndex
        ]
        const hoveredDate = w?.globals?.categoryLabels[dataPointIndex]
        const hoveredValue = w?.config?.series[0]?.data[dataPointIndex]
        const time = reportSplit[dataPointIndex]
        const glueSlice = slicedGlueReport[dataPointIndex]
        const reportEventSingleId = reportId[dataPointIndex]

        chartDataRef.current = {
          xAxis: dataPoints?.cx?.baseVal?.value + 1,
          yAxis: dataPoints?.cy?.baseVal?.value + 1,
          hoveredDate,
          hoveredValue,
          time,
          glueSlice,
          reportEventSingleId,
        }
        setHoveredValueForOuterDiv(hoveredDate)

        return ''
      },
    },

    markers: {
      size: 5,
      colors:
        report?.data?.general_score <= 99 ? ['#FF5E29', 'transparent'] : ['#FF5E29', 'transparent'],
      strokeColors: '#fff',
      strokeWidth: 1,
      hover: {
        size: 10,
        sizeOffset: 0,
      },
    },

    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      colors: report?.data?.general_score <= 99 ? ['#FFCFBF', '#7BAAF7'] : ['#FF8C00', '#85ECC1'],
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: [0.8],
        opacityTo: 0.1,
        stops: [-46.74, 100.41],
      },
    },
  }

  const [effectExecuted, setEffectExecuted] = useState(false)

  const [tempVal, setTempVal] = useState(0)
  useEffect(() => {
    const reportLength = Object.keys(report.data).length
    if (!effectExecuted) {
      if (reportLength > 0) {
        const generalReportScore = report?.data?.general_score?.toFixed(0)
        const weeklyfirstIndexReportScore = graphData[1]?.latestReport?.general_score?.toFixed(0)
        const differences = generalReportScore - weeklyfirstIndexReportScore
        setReportDiff(Math.abs(differences.toFixed(0)))
        setEffectExecuted(true)
      }
    }

    // eslint-disable-next-line
  }, [report])
  useEffect(() => {
    const countReports = graphData.length
    if (graphData?.length > 0) {
      if (graphData?.length <= countReports) {
        const index = graphData?.findIndex(
          weekreport => weekreport?.latestReport?.id === chartDataRef?.current?.reportEventSingleId
        )
        const generalReportScore = graphData[index]?.latestReport?.general_score?.toFixed(0)
        const weeklyfirstIndexReportScore = graphData[
          index + 1
        ]?.latestReport?.general_score?.toFixed(0)
        const differences = generalReportScore - weeklyfirstIndexReportScore
        setReportDiff(differences)
      } else {
        setReportDiff(0)
      }
    }
    // eslint-disable-next-line
  }, [tempVal])

  const handleReportSelect = async reportIds => {
    try {
      const res = await RequestService.get(`inviter-customer-reports/?report_id=${reportIds}`)
      setReportSelect(res.id)
      const index = allreports.findIndex(item => item.id === reportIds)

      dispatch(singleReport(res.data))
      dispatch(reportIndex(index))
    } catch (e) {
      // console.log('error', e)
    }
  }
  const handleButtonClick = value => {
    setReportButtonClicked(true)
    setTempVal(value)
    handleReportSelect(value)
  }

  useEffect(() => {
    if (graphData) {
      const chartElement = document.querySelector('#my_element')
      // eslint-disable-next-line
      chartElement.addEventListener('mouseleave', function (event) {
        setDataPoint(true)
      })
    }
    // eslint-disable-next-line
  }, [])
  const [average, setAverage] = useState(0)
  const [currentStartDate, setCurrentStartDate] = useState('')
  const [currentEndDate, setCurrentEndDate] = useState('')
  useEffect(() => {
    const slice = scores.slice(startIndex, endIndex).reverse()
    const avg = slice.reduce((acc, val) => acc + val, 0) / slice.length
    setAverage(Math.ceil(avg))
  }, [scores, startIndex, endIndex])
  useEffect(() => {
    // if (createdAt.length > 0 && startIndex >= 0 && endIndex <= createdAt.length) {
    const slicedDates = createdAt.slice(startIndex, endIndex).reverse()
    setCurrentStartDate(slicedDates[0] || '')
    setCurrentEndDate(slicedDates[slicedDates.length - 1] || '')
    // }
  }, [createdAt, startIndex, endIndex])

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header
          // eslint-disable-next-line
          customerId={customerId ? customerId : copiedCustomerId}
          // eslint-disable-next-line
          customerNameFallback={customerName ? customerName : copiedReportName}
          createdAt={report?.data?.created_at}
          score={report.data.general_score || 0}
          onCtaClick={handleOpenCtaUrl}
        />
        {
          // eslint-disable-next-line
          !showMountedTooltip && graphData ? (
            <div
              style={{
                boxShadow:
                  '0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22)',
                background: 'white',
                height: '107px',
                width: 'auto',
                padding: '3px 8px',
                position: 'absolute',
                top: `${mountY - 106}px`,
                left: `${mountX - 18}px`,
                zIndex: 99999999,
                border: 'gray',
              }}
            >
              <div style={{ padding: '5px', height: '93px' }}>
                {' '}
                <div style={{ textAlign: 'center', fontSize: '9px' }}>
                  {firstRendedDate} - {firstRendedTime}
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <Text isBold size={24} className={styles.mountedPercentage}>
                    {report?.data?.general_score?.toFixed(0) || 0}%
                  </Text>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      padding: '7px 8px',
                      backgroundColor:
                        // eslint-disable-next-line
                        report?.data?.general_score <= 25
                          ? '#FFDEDE'
                          : report?.data?.general_score <= 74
                          ? '#FFEFD4'
                          : '#E0FAEF',
                      color:
                        // eslint-disable-next-line
                        report?.data?.general_score <= 25
                          ? '#E53435'
                          : report?.data?.general_score <= 74
                          ? '#E89900'
                          : '#00A862',
                      marginLeft: '10px',
                      borderRadius: '4px',
                      textAlign: 'center',
                    }}
                  >
                    {
                      // eslint-disable-next-line
                      report?.data?.general_score <= 25
                        ? `${t('report.graph.bad')}`
                        : report?.data?.general_score <= 74
                        ? `${t('report.graph.medium')}`
                        : `${t('report.graph.good')}`
                    }
                  </div>
                </div>
                <Text
                  className={styles.mountedText}
                  isBold
                  style={{
                    maxWidth: '139px',
                    color: '#939395',
                    textAlign: 'center',
                    marginTop: '4px',
                  }}
                >
                  {t('report.graph.currentText')}
                </Text>
                <div style={{ textAlign: 'center' }}>
                  <CaretDownOutlined style={{ color: 'white' }} />
                </div>
              </div>
            </div>
          ) : !reportButtonClicked && dataPoint && !prevBtnTooltip && graphData ? (
            <div
              style={{
                boxShadow:
                  '0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22)',
                backgroundColor: 'white', // Corrected property name
                height: '107px',
                padding: '3px 8px',
                position: 'absolute',
                top: `${mountY - 106}px`,
                left: `${mountX - 18}px`,
                zIndex: 99999999,
                border: 'gray',
              }}
            >
              <div style={{ padding: '5px', height: '93px' }}>
                <div style={{ color: '#636363', textAlign: 'center', fontSize: '9.215px' }}>
                  {firstRendedDate} - {firstRendedTime}
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <Text isBold size={24} className={styles.mountedPercentage}>
                    {report?.data?.general_score?.toFixed(0) || 0}%
                  </Text>
                  <div
                    style={{
                      fontWeight: 'bold',
                      borderRadius: '4px',
                      marginTop: '10px',
                      marginLeft: '5px',
                    }}
                  >
                    <span
                      style={{
                        padding: '7px 8px',
                        borderRadius: '4px',
                        color:
                          // eslint-disable-next-line
                          report?.data?.general_score <= 25
                            ? '#E53435'
                            : report?.data?.general_score <= 74
                            ? '#E89900'
                            : '#00A862',

                        background:
                          // eslint-disable-next-line
                          report?.data?.general_score <= 25
                            ? '#FFDEDE'
                            : report?.data?.general_score <= 74
                            ? '#FFEFD4'
                            : '#E0FAEF',
                        textAlign: 'center',
                        height: '24px',
                      }}
                    >
                      {
                        // eslint-disable-next-line
                        report?.data?.general_score <= 25
                          ? `${t('report.graph.bad')}`
                          : report?.data?.general_score <= 74
                          ? `${t('report.graph.medium')}`
                          : `${t('report.graph.good')}`
                      }
                    </span>
                  </div>
                </div>
                <div
                  className={styles.mountedText}
                  style={{
                    fontWeight: 'bold',
                    maxWidth: '139px',
                    color: '#939395',
                    textAlign: 'center',
                    lineHeight: 'normal',
                    fontSize: '9px',
                    letterSpacing: '0.09px',
                    textTransform: 'uppercase',
                    marginTop: '11.5px',
                  }}
                >
                  {t('report.graph.currentText')}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <CaretDownOutlined style={{ color: 'white' }} />
                </div>
              </div>
            </div>
          ) : (
            ''
          )
        }
      </div>

      {/* GRAPHS SECTION */}
      {graphData ? (
        <div style={{ padding: '33px' }}>
          <Row style={{ marginTop: '150px', border: '1px solid #E8EBEC' }}>
            <Col span={19} style={{ marginTop: '20px' }}>
              <div style={{ marginLeft: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text size={18} style={{ fontWeight: 'bold' }}>
                    {t('report.graph.title')}
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        marginTop: '5px',
                        borderRadius: '2px',
                        background: 'var(--graphs-charts-dull-orange, #F3AF86)',
                        width: '12px',
                        height: '12px',
                      }}
                    />

                    <Text size={14} style={{ marginLeft: '5px' }}>
                      {t('report.graph.performance')}
                    </Text>
                    <div
                      style={{
                        marginTop: '5px',
                        borderRadius: '2px',
                        background:
                          report.data.general_score <= 99
                            ? 'var(--secondary-tints-blue-70, #7BAAF7)'
                            : 'var(--graphs-charts-bright-green, #85ECC1)',
                        width: '12px',
                        height: '12px',
                        marginLeft: '14.46px',
                      }}
                    />
                    <Text size={14} style={{ marginLeft: '6px', marginRight: '24px' }}>
                      {t('report.graph.forecast')}
                    </Text>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
                  <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    {/* {hoveredValueForOuterDiv}% */}
                    {report?.data?.general_score?.toFixed(0) || 0}%
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        // eslint-disable-next-line
                        report?.data?.general_score <= 25
                          ? '#FFDEDE'
                          : report?.data?.general_score <= 74
                          ? '#FFEFD4'
                          : '#E0FAEF',
                      // eslint-disable-next-line
                      color:
                        // eslint-disable-next-line
                        report?.data?.general_score <= 25
                          ? '#E53435'
                          : report?.data?.general_score <= 74
                          ? '#E89900'
                          : '#00A862',
                      // eslint-disable-next-line
                      // width:
                      //   // eslint-disable-next-line
                      //   report?.data?.general_score <= 25
                      //     ? '40px'
                      //     : report?.data?.general_score <= 74
                      //     ? '68px'
                      //     : '53px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginLeft: '6px',
                      padding: '2px 5px',
                      borderRadius: '4px',
                      textAlign: 'center',
                    }}
                  >
                    {
                      // eslint-disable-next-line
                      report?.data?.general_score <= 25
                        ? `${t('report.graph.bad')}`
                        : report?.data?.general_score <= 74
                        ? `${t('report.graph.medium')}`
                        : `${t('report.graph.good')}`
                    }
                  </span>
                  <div>
                    {
                      // eslint-disable-next-line
                      reportDiff ? (
                        <>
                          {reportDiff > 0 ? (
                            <>
                              <Text style={{ marginLeft: '6px' }}>
                                <span style={{ color: '#85ECC1', fontSize: '21px' }}>▲</span>+
                                {Math.abs(reportDiff)}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Text style={{ marginLeft: '6px' }}>
                                <span style={{ color: '#FE8485', fontSize: '21px' }}>▼</span>+
                                {Math.abs(reportDiff)}
                              </Text>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {reportDiff === 0 ? (
                            <Text style={{ marginLeft: '6px' }}>
                              {' '}
                              <span style={{ color: '#BCBCBC', fontSize: '21px' }}>■ </span>
                              {t('report.graph.noChange')}
                            </Text>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    }
                  </div>
                  {/* <LeftOutlined
                    onClick={handleNext}
                    disabled={endIndex >= scores.length}
                    style={{
                      color: endIndex >= scores.length ? '#ccc' : '#000',
                      marginLeft: '28px',
                    }} // Disabled color
                  /> */}
                  {/* <RightOutlined
                    onClick={handlePrev}
                    disabled={startIndex === 0}
                    style={{ color: startIndex === 0 ? '#ccc' : '#000', marginLeft: '6px' }} // Disabled color
                    /> */}
                </div>
                <span style={{ color: '#636363', fontSize: '9.215px' }}>
                  {firstRendedDate} - {firstRendedTime} -{' '}
                  {report?.data?.is_glue_report === false
                    ? `${t('report.graph.manual')}`
                    : `${t('report.graph.auto')}`}
                </span>
                <div style={{ position: 'relative' }}>
                  <div id="my_element">
                    <ApexChart options={chartOptions} series={chartOptions.series} height={225} />
                  </div>
                  {startIndex === 0 ? (
                    <div style={{ position: 'absolute', top: -50, right: 75 }}>
                      {report.data.general_score > 99 ? (
                        <div
                          className={styles.check}
                          // style={{ background: 'white' }}
                          // top: 0, right: 135
                        >
                          <div className={styles.dataCard}>
                            <div
                              style={{
                                fontWeight: 'bold',
                                fontSize: '11px',
                                textAlign: 'center',
                                lineHeight: 'normal',
                              }}
                            >
                              {t('report.graph.goodJob')}
                            </div>
                            <div
                              style={{
                                width: '180px',
                                textAlign: 'center',
                                lineHeight: 'normal',
                                marginTop: '5px',
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '10px',
                                }}
                              >
                                {t('report.graph.howAbout')}
                                <span style={{ fontWeight: 'bold' }}>
                                  {' '}
                                  {t('report.graph.interested')}
                                </span>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <button
                                onClick={openEmail}
                                type="button"
                                style={{
                                  // letterSpacing: '0.9px',
                                  padding: '4px',
                                  cursor: 'pointer',
                                  border: '1px solid var(--Primary-Ubersuggest-Orange, #F16434)',
                                  background: 'white',
                                  color: 'var(--Primary-Ubersuggest-Orange, #F16434)',
                                  fontSize: '11px',
                                  fontWeight: 'bold',
                                  marginTop: '14.5px',
                                }}
                              >
                                {t('report.graph.caseStudy')}
                              </button>
                            </div>
                          </div>
                          {/* <div
                            style={{
                              textAlign: 'center',
                              color: '#FFF',
                            }}
                          >
                            <CaretDownOutlined />
                          </div> */}
                          <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <img
                              src={graphImg}
                              alt="neilImg"
                              style={{ borderRadius: '50%', background: 'white' }}
                              width={75}
                              height={75}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={styles.check}
                          // style={{ background: 'white' }}
                          // top: 0, right: 135
                        >
                          <div className={styles.dataCard}>
                            <div
                              style={{
                                fontWeight: 'bold',
                                fontSize: '11px',
                                textAlign: 'center',
                                lineHeight: 'normal',
                              }}
                            >
                              {t('report.graph.boostHeading')}
                            </div>
                            <div
                              style={{
                                width: '180px',
                                textAlign: 'center',
                                lineHeight: 'normal',
                                marginTop: '5px',
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '11px',
                                }}
                              >
                                {t('report.graph.boostText')}
                              </div>
                              <div style={{ fontSize: '11px', fontWeight: 'bold' }}>
                                {t('report.graph.levelUp')}
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <button
                                onClick={handleOpenCtaUrl}
                                type="button"
                                style={{
                                  // letterSpacing: '0.9px',
                                  padding: '4px',
                                  cursor: 'pointer',
                                  border: '1px solid var(--Primary-Ubersuggest-Orange, #F16434)',
                                  background: 'white',
                                  color: 'var(--Primary-Ubersuggest-Orange, #F16434)',
                                  fontSize: '11px',
                                  fontWeight: 'bold',
                                  marginTop: '5px',
                                }}
                              >
                                {t('report.graph.improvePerformance')}
                              </button>
                            </div>
                          </div>
                          {/* <div
                            style={{
                              textAlign: 'center',
                              color: '#FFF',
                            }}
                          >
                            <CaretDownOutlined />
                          </div> */}
                          <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <img
                              src={graphImg}
                              alt="neilImg"
                              style={{ borderRadius: '50%', background: 'white' }}
                              width={75}
                              height={75}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {graphData.length === 1 && weeklyReport.length === 0 ? (
                    <div style={{ position: 'absolute', top: -105, left: 250 }}>
                      <div className={styles.check}>
                        <div className={styles.dataCard}>
                          <div
                            style={{
                              width: '180px',
                              textAlign: 'center',
                              lineHeight: 'normal',
                              marginTop: '5px',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '11px',
                              }}
                            >
                              Login to view historical reports.
                            </div>
                          </div>
                        </div>
                        {/* <div
                            style={{
                              textAlign: 'center',
                              color: '#FFF',
                            }}
                          >
                            <CaretDownOutlined />
                          </div> */}
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          <img
                            src={graphImg}
                            alt="neilImg"
                            style={{ borderRadius: '50%', background: 'white' }}
                            width={75}
                            height={75}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {chartDataRef.current.xAxis > 0 &&
                  chartDataRef.current.yAxis > 0 &&
                  chartDataRef.current.hoveredValue !== null &&
                  chartDataRef.current.hoveredValue !== undefined ? (
                    <div
                      style={{ position: 'absolute', top: 0, display: dataPoint ? 'none' : '' }}
                      onMouseEnter={() => setDataPoint(false)}
                    >
                      <div
                        style={{
                          boxShadow:
                            '0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22)',
                          backgroundColor: 'white',
                          height: '107px',
                          minWidth: '160px',
                          width: 'auto',
                          padding: '3px 8px',
                          position: 'absolute',
                          top: `${chartDataRef.current.yAxis - 95}px`,
                          left: `${chartDataRef.current.xAxis - 30}px`,
                          border: 'gray',
                        }}
                      >
                        <div style={{ padding: '5px' }}>
                          <div
                            style={{ color: '#636363', textAlign: 'center', fontSize: '9.215px' }}
                          >
                            {chartDataRef.current.hoveredDate} - {chartDataRef.current.time}
                          </div>
                          <div style={{ display: 'flex' }}>
                            <b style={{ fontSize: '24px', marginTop: '5px' }}>
                              {chartDataRef?.current?.hoveredValue?.toFixed(0)}%
                            </b>
                            <Text
                              isBold
                              style={{ borderRadius: '4px', marginTop: '10px', marginLeft: '5px' }}
                            >
                              <span
                                style={{
                                  padding: '7px 8px',
                                  borderRadius: '4px',
                                  backgroundColor:
                                    // eslint-disable-next-line
                                    chartDataRef.current.hoveredValue <= 25
                                      ? '#FFDEDE'
                                      : chartDataRef.current.hoveredValue <= 74
                                      ? '#FFEFD4'
                                      : '#E0FAEF',
                                  // eslint-disable-next-line
                                  color:
                                    // eslint-disable-next-line
                                    chartDataRef.current.hoveredValue <= 25
                                      ? '#E53435'
                                      : chartDataRef.current.hoveredValue <= 74
                                      ? '#E89900'
                                      : '#00A862',
                                  // eslint-disable-next-line
                                  // width:
                                  //   // eslint-disable-next-line
                                  //   chartDataRef.current.hoveredValue <= 25
                                  //     ? '40px'
                                  //     : chartDataRef.current.hoveredValue <= 74
                                  //     ? '68px'
                                  //     : '53px',
                                  height: '24px',
                                }}
                              >
                                {
                                  // eslint-disable-next-line
                                  chartDataRef.current.hoveredValue <= 25
                                    ? `${t('report.graph.bad')}`
                                    : chartDataRef.current.hoveredValue <= 74
                                    ? `${t('report.graph.medium')}`
                                    : `${t('report.graph.good')}`
                                }
                              </span>
                            </Text>
                          </div>
                          {chartDataRef.current.reportEventSingleId === reportSelect ? (
                            <div
                              style={{
                                maxWidth: '139px',
                                color: '#939395',
                                textAlign: 'center',
                                lineHeight: 'normal',
                                fontSize: '9px',
                                fontWeight: 'bold',
                                letterSpacing: '0.09px',
                                textTransform: 'uppercase',
                                marginTop: '7px',
                              }}
                            >
                              {t('report.graph.currentText')}
                            </div>
                          ) : (
                            <div style={{ textAlign: 'center' }}>
                              {graphData[0]?.latestReport?.dummy_report === true ? (
                                <div
                                  style={{
                                    maxWidth: '139px',
                                    color: '#939395',
                                    textAlign: 'center',
                                    lineHeight: 'normal',
                                    fontSize: '9px',
                                    fontWeight: 'bold',
                                    letterSpacing: '0.09px',
                                    textTransform: 'uppercase',
                                    marginTop: '19px',
                                  }}
                                >
                                  THIS IS SAMPLE REPORT
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleButtonClick(chartDataRef.current.reportEventSingleId)
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    maxWidth: '145px',
                                    borderRadius: '2px',
                                    border: '1px solid #F16434',
                                    background: 'white',
                                    color: '#F16434',
                                    fontSize: '9px',
                                    fontWeight: 'bold',
                                    marginTop: '8px',
                                  }}
                                >
                                  {t('report.graph.clickToSee')}
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        <div style={{ textAlign: 'center', color: 'white' }}>
                          <CaretDownOutlined />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
            <Col span={5} style={{ borderLeft: '1px solid #E8EBEC' }}>
              <div
                style={{
                  borderBottom: '1px solid #E8EBEC',
                  padding: '24px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '16px',
                }}
              >
                {t('report.graph.insightsHeading')}
              </div>
              <Row>
                <Col span={12}>
                  <div style={{ padding: '24px' }}>
                    <div style={{ fontSize: '14px', color: '#939395' }}>
                      {' '}
                      {t('report.graph.grade')}
                    </div>
                    <div style={{ fontSize: '28px', fontWeight: 'bold' }}>
                      {report?.data?.general_score?.toFixed(0) || 0}%
                    </div>

                    <Text isBold size={14} style={{ borderRadius: '4px', marginTop: '12px' }}>
                      <span
                        style={{
                          padding: '7px 8px',
                          borderRadius: '4px',
                          backgroundColor:
                            // eslint-disable-next-line
                            average <= 25 ? '#FFDEDE' : average <= 74 ? '#FFEFD4' : '#E0FAEF',
                          // eslint-disable-next-line
                          color:
                            // eslint-disable-next-line
                            average <= 25 ? '#E53435' : average <= 74 ? '#E89900' : '#00A862',
                          // eslint-disable-next-line
                          // width:
                          //   // eslint-disable-next-line
                          //   average <= 25 ? '40px' : average <= 74 ? '68px' : '53px',
                          height:
                            // eslint-disable-next-line
                            average <= 25 ? '24px' : average <= 74 ? '24px' : '24px',
                        }}
                      >
                        {
                          // eslint-disable-next-line
                          average <= 25
                            ? `${t('report.graph.bad')}`
                            : average <= 74
                            ? `${t('report.graph.medium')}`
                            : `${t('report.graph.good')}`
                        }
                      </span>
                    </Text>
                  </div>
                </Col>

                <Col span={12} style={{ borderLeft: '1px solid #E8EBEC' }}>
                  <div style={{ padding: '24px' }}>
                    <div style={{ fontSize: '14px', color: '#939395' }}>
                      {' '}
                      {t('report.graph.when')}
                    </div>
                    <div style={{ fontSize: '28px', fontWeight: 'bold' }}>{onlyDate}</div>
                    <div style={{ fontSize: '9.215px', color: '#939395' }}>{firstRendedTime}</div>
                    <div style={{ fontSize: '9.215px', color: '#939395' }}>
                      {report?.data?.is_glue_report === false
                        ? `${t('report.graph.manualGen')}`
                        : `${t('report.graph.automaticlGen')}`}
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ borderBottom: '1px solid #E8EBEC' }} />
              <Row>
                <Col span={12}>
                  {' '}
                  <div style={{ padding: '24px' }}>
                    <div style={{ fontSize: '14px', color: '#939395' }}>
                      {t('report.graph.ofReports')}
                    </div>
                    <div style={{ fontSize: '28px', fontWeight: 'bold' }}>{graphData.length}</div>
                    <div style={{ fontSize: '14px', color: '#636363' }}>
                      <div style={{ fontSize: '9.215px', lineHeight: '12px' }}>
                        {t('report.graph.from')} {currentStartDate}{' '}
                      </div>
                    </div>
                    <div style={{ fontSize: '14px', color: '#636363', marginTop: '2px' }}>
                      <div style={{ fontSize: '9.215px', lineHeight: '12px' }}>
                        {t('report.graph.to')} {currentEndDate}
                      </div>
                      {/* <span style={{ fontWeight: 'bold' }}>TO:</span> OCT 25 2024{' '} */}
                    </div>
                  </div>
                </Col>
                <Col span={12} style={{ borderLeft: '1px solid #E8EBEC' }}>
                  <div style={{ padding: '24px' }}>
                    <div style={{ fontSize: '14px', color: '#939395' }}>
                      {' '}
                      {t('report.graph.avgGrade')}
                    </div>
                    <div style={{ fontSize: '28px', fontWeight: 'bold' }}>{average}%</div>

                    <Text isBold size={14} style={{ borderRadius: '4px', marginTop: '12px' }}>
                      <span
                        style={{
                          padding: '7px 8px',
                          borderRadius: '4px',
                          backgroundColor:
                            // eslint-disable-next-line
                            average <= 25 ? '#FFDEDE' : average <= 74 ? '#FFEFD4' : '#E0FAEF',
                          // eslint-disable-next-line
                          color:
                            // eslint-disable-next-line
                            average <= 25 ? '#E53435' : average <= 74 ? '#E89900' : '#00A862',
                          // eslint-disable-next-line
                          // width:
                          //   // eslint-disable-next-line
                          //   average <= 25 ? '40px' : average <= 74 ? '68px' : '53px',
                          height:
                            // eslint-disable-next-line
                            average <= 25 ? '24px' : average <= 74 ? '24px' : '24px',
                        }}
                      >
                        {
                          // eslint-disable-next-line
                          average <= 25
                            ? `${t('report.graph.bad')}`
                            : average <= 74
                            ? `${t('report.graph.medium')}`
                            : `${t('report.graph.good')}`
                        }
                      </span>
                    </Text>
                  </div>
                </Col>
              </Row>
              {/* </Card> */}
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}

      {/* QUOTE WITH IMAGE SECTION */}

      <section className={styles.container}>
        <div className={styles.quote}>
          <Quote>
            <Text size={16} color="black">
              {t('report.nielPatelQuoteText.part1')}
              <strong>
                {t('report.nielPatelQuoteText.part2', {
                  activeCampaigns: report.data.campaigns_total,
                })}
              </strong>
              {t('report.nielPatelQuoteText.part3', {
                adGroupPerCampaign: report.data.ad_groups_per_campaign?.toFixed(0),
              })}

              {t('report.nielPatelQuoteText.part4')}
              <strong>
                {t('report.nielPatelQuoteText.part5', { adGroups: report.data.ad_groups_total })}
              </strong>
              <strong>
                {t('report.nielPatelQuoteText.part6', { keywords: report.data.keywords_total })}
              </strong>
              {t('report.nielPatelQuoteText.part7')}

              <strong style={{ color: '#F26234' }}>
                {t('report.nielPatelQuoteText.part8', {
                  percentage: (100 - report.data.general_score)?.toFixed(0),
                })}
              </strong>
            </Text>
          </Quote>
        </div>
      </section>

      {/* THREE STEPS SECTION */}

      <section className={styles.container}>
        <div className={styles.threeStepsTitle}>
          <Text isTitle size={32} weight={700}>
            <strong className={styles.orangeStrong}>{t('report.wait')}</strong>{' '}
            {t('report.waitText', { percentage: (100 - report.data.general_score)?.toFixed(0) })}
          </Text>
        </div>
        <div className={styles.CTACardsDiv}>
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isBold isTitle color="primary" style={{ fontSize: '55px' }}>
                    {report?.data?.quality_score?.toFixed(2)}
                  </Text>
                }
                title={t('report.yourQualityScore')}
                tooltip={t('report.yourQualityScoreHelp')}
                color="#333333"
                text={
                  <Text color="black" size={16}>
                    {t('report.yourQualityScoreText1')}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.yourQualityScoreText2')}
                    </strong>
                  </Text>
                }
                buttonText={t('report.yourQualityScoreButton')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isTitle isBold color="primary" style={{ fontSize: '55px' }}>
                    {formatCurrency(report.data.projected_wasted_spend, {
                      currency: reportPayload?.currency ? reportPayload.currency : reportCurrency,
                      language: 'en-US',
                    }) || 0}
                  </Text>
                }
                title={t('report.projectedBudgetWasted')}
                tooltip={t('report.projectedBudgetWastedHelp')}
                color="#333333"
                text={
                  <Text color="black" size={16}>
                    {t('report.projectedBudgetWastedText1')}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.projectedBudgetWastedText2', {
                        money: formatCurrency(report.data.wasted_spent, {
                          currency: reportPayload?.currency
                            ? reportPayload.currency
                            : reportCurrency,
                          language: 'en-US',
                        }),
                      })}
                    </strong>
                  </Text>
                }
                buttonText={t('report.projectedBudgetWastedButton')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isTitle isBold color="primary" style={{ fontSize: '55px' }}>
                    {getPercentage(report.data.click_through_rate)}
                  </Text>
                }
                title={t('report.yourCTR')}
                tooltip={t('report.yourCTRHelp')}
                color="#333333"
                text={
                  <Text color="black" size={16}>
                    {t('report.yourCTRText1')}{' '}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.yourCTRText2', {
                        percentage:
                          getBenchmarkProp('ctr_target', benchmarks) !== '-'
                            ? getBenchmarkProp('ctr_target', benchmarks)
                            : 4.76,
                      })}
                    </strong>
                  </Text>
                }
                buttonText={t('report.yourCTRbuttonText')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
          </Row>
          {/* {report.data.recommendations !== null && formattedImprovement.length > 0 && ( */}
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ paddingTop: 64 }}>
                <Space direction="vertical" wrap={4}>
                  <Text isTitle size={16}>
                    <span
                      style={{
                        textTransform: 'uppercase',
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                      }}
                    >
                      <img src={AlertArrow} alt="icon alert" />
                      {t('report.improvement.title')}
                    </span>
                  </Text>
                  {formattedImprovement?.length > 0 ? (
                    <Text size={16}>{t('report.improvement.subtitle')}</Text>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                      }}
                    >
                      <Text size={16}>{t('report.improvement.noOpportunity')}</Text>
                    </div>
                  )}
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {formattedImprovement &&
                formattedImprovement?.length > 0 &&
                formattedImprovement.map((col, index) => (
                  <div
                    key={`row-improvement-${index + 1}`}
                    className={classNames([
                      styles.improvementCol,
                      index <= viewQtd ? styles.showImprovementCol : styles.hiddenImprovementCol,
                    ])}
                  >
                    {col.map((item, unique) => (
                      <ImprovementCard
                        priority={item.priority}
                        key={`col-improvement-${unique + 1}`}
                        title={t(`report.recommendation.${item.type_id}.category`)}
                        description={t(`report.recommendation.${item.type_id}.message`)}
                        url={item.url}
                        impact={item.uplift}
                      />
                    ))}
                  </div>
                ))}
              {formattedImprovement.length > 1 && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 8 }}>
                  <Button
                    className={styles.btnGhostDisableStyle}
                    onClick={handleImprovementMore}
                    size="small"
                    disabled={viewQtd === formattedImprovement.length - 1}
                  >
                    {t('report.improvement.viewMoreText')}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          {/* )} */}
        </div>
        {formattedImprovement.length > 0 && (
          <div className={styles.threeStepsFooter}>
            <CTABanner
              text={
                <Text color="black" isBold isTitle size={16}>
                  {t('report.bookACall')}
                </Text>
              }
              buttonText={t('report.bookACallButton')}
              bgColor=""
              onCtaClick={handleOpenCtaUrl}
            />
          </div>
        )}
      </section>

      {/* PERFORMANCE METRICS SECTION */}
      <section id={PERFORMANCE_SECTION} className={styles.metricsSection}>
        <div className={styles.container}>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <div className={styles.metricsTitle}>
            <Text size={32} isBold isTitle>
              {t('report.performanceMetrics')}
            </Text>
          </div>
          {/* <div className={styles.metricsTitle}>
              <button
                style={{
                  fontSize: 32,
                  fontWeight: 'bold',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                }}
                onClick={showModal}
                type="submit"
              >
                Extra Insights
              </button>
            </div> */}
          {/* </div> */}
          {/* {isModalVisible && (
            <MetricsModal
              modalVisibility={isModalVisible}
              handleOk={handleOk}
              handleCancel={handleCancel}
              tableData={extraInsights?.campaign_data}
            />
          )} */}
          <div className={styles.tabsDiv}>
            <Tabs defaultActiveKey="1" onTabClick={onTabClick}>
              {/* unhide */}
              <TabPane key="1" tab={t('report.performanceMetricsOverview')}>
                {report.data.has_overall === 0 ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Row style={{ marginBottom: 24 }}>
                          <Col span={24}>
                            {' '}
                            <div className={styles.infoCardDiv}>
                              <InformationCard
                                title={t('report.spend')}
                                tooltipText=""
                                number={`${formatCurrency(report.data.overall_spend, {
                                  currency: reportPayload.currency
                                    ? reportPayload.currency
                                    : reportCurrency,
                                  language: 'en-US',
                                })}`}
                                isMoney={false}
                                color="black"
                                tagText=""
                                footerText=""
                              />
                            </div>
                          </Col>
                          {/* <Col span={12}>
                            {' '}
                            <div className={styles.infoCardDiv}>
                              <InformationCard
                                title="Campaign Status"
                                tooltipText=""
                                number={getStatusString(
                                  report?.data?.standard_metrics_data?.campaign_status
                                )}
                                isMoney={false}
                                color="black"
                                tagText=""
                                footerText=""
                              />
                            </div>
                          </Col> */}
                        </Row>
                        <Row style={{ marginBottom: 24 }} gutter={24}>
                          <Col span={12}>
                            <div className={styles.infoCardDiv}>
                              <InformationCard
                                title="Search conv."
                                tooltipText=""
                                // number={`${formatCurrency(report.data.overall_spend, {
                                //   currency: reportPayload.currency
                                //     ? reportPayload.currency
                                //     : reportCurrency,
                                //   language: 'en-US',
                                // })}`}
                                number={report?.data?.overall_conversions ?? 0}
                                isMoney={false}
                                color="black"
                                tagText=""
                                footerText=""
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className={styles.infoCardDiv} style={{ background: '#E8EBEC' }}>
                              <InformationCard
                                title="all conversions"
                                tooltipText=""
                                // number={`${formatCurrency(report.data.overall_spend, {
                                //   currency: reportPayload.currency
                                //     ? reportPayload.currency
                                //     : reportCurrency,
                                //   language: 'en-US',
                                // })}`}
                                number={
                                  report?.data?.standard_metrics_data?.campaign_all_conversions ?? 0
                                }
                                isMoney={false}
                                color="black"
                                tagText=""
                                footerText=""
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={`${formatCurrency(report.data.overall_cpa, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.overall_click,
                              report.data.overall_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'overall_impressions',
                                    value: report.data.overall_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'overall_clicks',
                                    value: report.data.overall_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'overall_conversions',
                                    value: report.data.overall_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    <div className={styles.metricsFooter}>
                      <Text size={16}>{t('report.metricsFooterText')}</Text>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab={t('report.performanceMetricsPerformanceMax')} key="2">
                {!report.data.performance_max_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={`${formatCurrency(report.data.performance_max_spend, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={`${formatCurrency(report.data.performance_max_cpa, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.performance_max_click,
                              report.data.performance_max_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'display_impressions',
                                    value: report.data.performance_max_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'display_clicks',
                                    value: report.data.performance_max_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'display_conversions',
                                    value: report.data.performance_max_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    {/* <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3' }} /> What am I seeing?
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>Display ads </strong>
                        act like TV Commercials and Billboards, they’re meant to amplify your
                        message and make customers aware of your brand. Display KPIs are clicks,
                        CTR, and view-through conversions
                      </Text>
                    </div> */}
                  </>
                )}
              </TabPane>

              <TabPane tab={t('report.performanceMetricsSearch')} key="3">
                {!report.data.search_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={`${formatCurrency(report.data.search_spend, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={`${formatCurrency(report.data.search_cpa, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.search_click,
                              report.data.search_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.search_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.search_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>{report.data.search_conversions?.toFixed(0)}</Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'search_impressions',
                                    value: report.data.search_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'search_clicks',
                                    value: report.data.search_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'search_conversions',
                                    value: report.data.search_conversions?.toFixed(0),
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3', verticalAlign: 'text-top' }} />{' '}
                        {t('report.metricsFooterTitle')}
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>
                          {t('report.searchMetricsFoooter.strong')}{' '}
                        </strong>
                        {t('report.searchMetricsFoooter.part2')}
                      </Text>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab={t('report.performanceMetricsDisplay')} key="4">
                {!report.data.display_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={`${formatCurrency(report.data.display_spend, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={`${formatCurrency(report.data.display_cpa, {
                              currency: reportPayload?.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}`}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.display_click,
                              report.data.display_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'display_impressions',
                                    value: report.data.display_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'display_clicks',
                                    value: report.data.display_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'display_conversions',
                                    value: report.data.display_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>

                    <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3' }} />{' '}
                        {t('report.metricsFooterTitle')}
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>
                          {' '}
                          {t('report.displayMetricsFoooter.strong')}
                        </strong>
                        {t('report.displayMetricsFoooter.part2')}
                      </Text>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab="Extra Insights" key="5" tabPosition="right">
                {isModalVisible && (
                  <CampaignMetricsModal
                    modalVisibility={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    isLoading={isLoading}
                  />
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      {/* SHARE WITH TEAM SECTION */}
      {!reportDashboardPage && (
        <section id={SHARE_WITH_TEAM_SECTION} className={styles.teamSection}>
          <div className={styles.container}>
            <Row>
              <Col lg={12}>
                <Text color="black" size={18} isUppercase isBold isTitle>
                  {t('report.shareWithYourTeamSection')}
                </Text>
                <Text color="darkerGray" size={16}>
                  {t('report.shareWithYourTeamText')}
                </Text>
              </Col>
              <Col
                lg={12}
                className={styles.socialButtons}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {/* <Button
                  className={styles.socialButton}
                  style={{ minWidth: '205px !important', marginLeft: '0' }}
                  onClick={CopyUrl}
                >
                  <div
                    className={styles.socialIconText}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div className={styles.socialIcon}>
                      <BsLink45Deg />
                    </div>
                    {copyUpdate
                      ? t('report.shareWithYourTeamButtonConfirmed')
                      : t('report.shareWithYourTeamButton')}
                  </div>
                </Button> */}

                {/* <Space wrap>
                <Popover content={<ManageUsers isDashboard="true" />} trigger="click">
                  <Button>{t('report.share.textButton2')}</Button>
                </Popover>
              </Space> */}
                <Space wrap>
                  <Popover
                    minWidth={300}
                    content={
                      userRole ? (
                        t('settings.manageUsers.noPermission')
                      ) : (
                        <ManageUsers isDashboard="true" setIsPopoverOpen={setIsPopoverOpen} />
                      )
                    }
                    trigger={userRole ? 'hover' : 'click'}
                    open={isPopoverOpen}
                    onOpenChange={togglePopover}
                    mouseEnterDelay={0.1}
                    mouseLeaveDelay={0.7}
                  >
                    <Button
                      disabled={userRole}
                      className={userRole ? '' : styles.shareReport} // Apply styles only when userRole is not truthy
                      style={{ border: userRole && '1px solid #797979' }}
                    >
                      {t('report.share.textButton2')}
                    </Button>
                  </Popover>

                  {isPopoverOpen && (
                    // eslint-disable-next-line
                    <div
                      style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        // zIndex: 999,
                      }}
                      onClick={togglePopover}
                    ></div>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </section>
      )}

      {/* OVERALL SECTION */}
      <div className={styles.container}>
        <OverallSectionTemporary
          report={report.data}
          language="en-US"
          currency={reportPayload?.currency ? reportPayload.currency : reportCurrency}
          benchmarks={benchmarks}
          onCtaClick={handleOpenCtaUrl}
          hideDisplay={!report.data.display_spend}
          formattedImprovement={formattedImprovement}
          // eslint-disable-next-line
          reportId={report_id}
          viewQtd={viewQtd}
          handleImprovementMore={handleImprovementMore}
          extraInsights={extraInsights?.ad_group_ad_data}
          extraInsightsKeywords={extraInsights?.keywords_data}
        />
      </div>

      {/* DISPLAY SECTION */}
      {/* <section className={styles.container}>
        <div className={styles.displayHeader}>
          <Text isTitle size={32}>
            Display
          </Text>
        </div>
        <Collapse ghost>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Display ads</Text>
                <ProgressBar percentageNumber={75} />
              </div>
            }
            key="1"
          >
            <div>lorem</div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Display placements</Text>
                <ProgressBar percentageNumber={93} />
              </div>
            }
            key="2"
          >
            <div>lorem</div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Audience performance</Text>
                <ProgressBar percentageNumber={50} />
              </div>
            }
            key="3"
          >
            <div>lorem</div>
          </Panel>
        </Collapse>
      </section> */}

      {/* STATS SECTION */}
      {/* <section className={styles.container}>
        <div className={styles.statsTabContainer}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="SEARCH" key="1">
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Active Search Campaigns"
                    tooltipText="Something"
                    number={9}
                    color="black"
                    tagText="Amazing"
                    footerText=""
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Avg Ad Groups Per"
                    tooltipText="Something"
                    number={4.3}
                    color="black"
                    tagText=""
                    footerText=""
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Active Ad Groups"
                    tooltipText="Something"
                    number={39}
                    color="black"
                    tagText=""
                    footerText="52 Active Ads"
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Avg Keywords"
                    tooltipText="Something"
                    number={23.4}
                    color="black"
                    tagText=""
                    footerText=""
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="DISPLAY" key="2">
              <div>wip tab</div>
            </TabPane>
          </Tabs>
        </div>
      </section> */}

      {/* SHARE SECTION */}
      <section id={SHARE_WITH_TEAM_SECTION} className={styles.shareContainer}>
        <div className={styles.container}>
          <Row justify="center">
            <Col lg={12}>
              <div className={styles.shareLeftSide}>
                <div className={styles.shareLeftSideTitle}>
                  <Text size={64}>{t('report.share.title')}</Text>
                </div>
                {!reportDashboardPage && <Text size={24}>{t('report.share.subtitle')}</Text>}
                <div className={styles.shareButtonContainer}>
                  {/* <Button type="primary" className={styles.shareButton} onClick={setCopied}>
                    {isCopied ? 'Public URL Copied!' : 'Share Ads Grader Now'}
                  </Button> */}
                  {/* <Space wrap>
                    <Popover content={<ManageUsers isDashboard="true" />} trigger="click">
                      <Button style={{ backgroundColor: '#f16434', color: 'white' }}>
                        {t('report.share.textButton')}
                      </Button>
                    </Popover>
                  </Space> */}
                  {!reportDashboardPage && (
                    <Space wrap>
                      <Popover
                        content={
                          userRole ? (
                            t('settings.manageUsers.noPermission')
                          ) : (
                            <ManageUsers isDashboard="true" setIsPopoverOpen2={setIsPopoverOpen2} />
                          )
                        }
                        trigger={userRole ? 'hover' : 'click'}
                        open={isPopoverOpen2}
                        onOpenChange={togglePopover2}
                        mouseEnterDelay={0.1}
                        mouseLeaveDelay={0.7}
                      >
                        <Button
                          disabled={userRole}
                          style={{
                            border: userRole && '1px solid #797979',
                            backgroundColor: userRole ? '#797979' : '#f16434',
                            color: 'white',
                          }}
                        >
                          {t('report.share.textButton')}
                        </Button>
                      </Popover>
                      {/* <Popover
                      content={
                        <ManageUsers isDashboard="true" setIsPopoverOpen2={setIsPopoverOpen2} />
                      }
                      trigger={popoverDisabled ? '' : 'click'}
                      open={isPopoverOpen2}
                      onOpenChange={togglePopover2}
                    >
                      <Button style={{ backgroundColor: '#f16434', color: 'white' }}>
                        {t('report.share.textButton')}
                      </Button>
                    </Popover> */}
                      {isPopoverOpen2 && (
                        // eslint-disable-next-line
                        <div
                          style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            // zIndex: 999,
                          }}
                          onClick={togglePopover2}
                        ></div>
                      )}
                    </Space>
                  )}
                </div>
                {!reportDashboardPage && (
                  <div className={styles.shareConfidentialContainer}>
                    <div className={styles.shareConfidentialIcon}>
                      <AiOutlineSafetyCertificate />
                    </div>
                    <Text size={16}>
                      <strong>{t('report.share.textProtect.part1')}</strong>
                      {t('report.share.textProtect.part2')}
                    </Text>
                  </div>
                )}
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className={styles.shareRightSide}>
                <img src={NeilSection} alt="banner " className={styles.neilBannerImg} />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* REPORT SUMMARY SECTION */}
      <section className={styles.summarySection}>
        <div className={styles.container}>
          <Row justify="center">
            <Col lg={12}>
              <div className={styles.summaryLeftSide}>
                <div className={styles.reportTitleContainer}>
                  <Text size={80} color="white" className={styles.reportTitleContainer}>
                    {t('report.summary.title')}
                  </Text>
                </div>
                <div className={styles.summaryMiddleParagraph}>
                  <Text size={28} color="white">
                    {t('report.summary.subtitle')}
                  </Text>
                </div>
                <div className={styles.summaryLastParagraph}>
                  <Text size={20} color="white">
                    {t('report.summary.description')}
                  </Text>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className={styles.reportRightSide}>
                <PerformanceCard
                  percentageNumber={report?.data?.general_score?.toFixed(0) || 0}
                  titleText={t('report.headerCardTitle')}
                  buttonText={t('report.headerCardButtonText')}
                  disclaimerText={t('report.headerCardDisclaimerText')}
                  onClick={handleOpenCtaUrl}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  )
}

// export default ReportPageTemporary
export default memo(ReportPageTemporary)
