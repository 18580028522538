import React, { useEffect, useState, useMemo, memo } from 'react'
import { Card, Skeleton, message, Empty, Row, Col } from 'antd'
import { RequestService } from '@services/requests'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { useSelector, useDispatch } from 'react-redux'
import Text from '@components/Text/text'
import { HOOKS_DATA, FILTERS_CLICK_FLAG } from '@utils/constants'
import copyIcon from '@images/AdsPreview/InsightsCopyOrange.svg'
import styles from './styles.module.css'
import { getYAxisConfig } from '../AdCopy/yAxisIntervals'

const Hooks = () => {
  const dispatch = useDispatch()
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const hooks = useSelector(state => state?.discoverAds?.hooksData)
  const [loading, setLoading] = useState(false)
  const selectedHooks = useSelector(state => state?.discoverAds?.selectedHooks || [])
  const activeTab = useSelector(state => state?.discoverAds?.activeTab)

  // Create a map to store unique hooks and assign colors
  const uniqueHooks = useMemo(() => {
    if (!hooks?.weeklyData) return new Set()

    const totalCounts = new Map()
    hooks.weeklyData.forEach(weekItem => {
      if (weekItem.data) {
        Object.values(weekItem.data).forEach(item => {
          // Handle both string and array values
          if (item?.value && item?.count) {
            const value = Array.isArray(item.value) ? item.value[0] : item.value
            if (value) {
              totalCounts.set(value, (totalCounts.get(value) || 0) + item.count)
            }
          }
        })
      }
    })

    return new Set(
      Array.from(totalCounts.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 30)
        .map(([hook]) => hook)
    )
  }, [hooks?.weeklyData])

  // Assign colors to hooks
  const colors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FF4500',
    '#8B008B',
    '#FFD700',
    '#00CED1',
    '#FF1493',
    '#32CD32',
    '#BA55D3',
    '#FF8C00',
    '#4169E1',
    '#2E8B57',
    '#DC143C',
    '#9370DB',
    '#20B2AA',
    '#CD853F',
    '#6495ED',
    '#DB7093',
    '#556B2F',
    '#FF69B4',
    '#4B0082',
    '#8FBC8F',
    '#BC8F8F',
    '#DAA520',
    '#008080',
    '#800000',
    '#9932CC',
    '#F4A460',
    '#2F4F4F',
  ]
  const CONSTANT_GRAY = '#A9A9A9'

  const hookColors = useMemo(() => {
    const colorMap = new Map()
    Array.from(uniqueHooks).forEach((hook, index) => {
      colorMap.set(hook, colors[index])
    })
    return colorMap
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueHooks])

  const handleCheckboxChange = (title, checked) => {
    let newSelectedHooks
    if (checked) {
      newSelectedHooks = [...selectedHooks, title]
    } else {
      newSelectedHooks = selectedHooks.filter(t => t !== title)
    }
    dispatch({ type: 'SET_SELECTED_HOOKS', payload: newSelectedHooks })
    if (newSelectedHooks?.length === 0) {
      dispatch({ type: FILTERS_CLICK_FLAG, payload: true })
    }
  }

  const getData = async () => {
    try {
      setLoading(true)
      const res = await RequestService.get(
        `/save/meta-ads-hooks/?meta_page_id=${specificBrandDetail?.pageId}&google_advertiser_id=${specificBrandDetail?.googleId}`
      )
      dispatch({
        type: HOOKS_DATA,
        payload: {
          hooks: res?.data?.result?.data?.json || [],
          weeklyData: res?.data?.result?.data?.weekly_data || [],
          years: res?.data?.result?.data?.years || [],
        },
      })
    } catch (error) {
      message.error('Failed to fetch hooks data')
      console.error('Error fetching hooks:', error)
    } finally {
      setLoading(false)
    }
  }

  const processWeeklyData = weeklyData => {
    if (!weeklyData?.length) return []

    const sortedData = [...weeklyData].sort((a, b) => {
      if (b.year !== a.year) return b.year - a.year
      return b.week - a.week
    })

    const monthlyData = new Map()

    sortedData.forEach(weekItem => {
      const { year, week, data } = weekItem
      if (!data) return

      // For week 0, use January 1st of that year
      // For other weeks, calculate the date properly
      const date = new Date(year, 0, 1)
      if (week > 0) {
        date.setDate(date.getDate() + (week - 1) * 7)
      }

      const month = date.toLocaleString('default', { month: 'short' })
      const actualYear = date.getFullYear()
      const monthYearKey = `${month}-${actualYear}`

      if (!monthlyData.has(monthYearKey)) {
        monthlyData.set(monthYearKey, {
          month,
          year: actualYear,
          monthLabel: `${month} ${actualYear}`,
          counts: new Map(),
        })
      }

      Object.values(data).forEach(item => {
        if (item?.count) {
          const value = Array.isArray(item.value) ? item.value[0] : item.value
          if (value) {
            const currentMonth = monthlyData.get(monthYearKey)
            currentMonth.counts.set(value, (currentMonth.counts.get(value) || 0) + item.count)
          }
        }
      })
    })

    // Sort by date in ascending order (oldest to newest)
    return Array.from(monthlyData.entries())
      .sort((a, b) => {
        if (a[1].year !== b[1].year) return a[1].year - b[1].year
        const monthA = new Date(Date.parse(`${a[1].month} 1, ${a[1].year}`))
        const monthB = new Date(Date.parse(`${b[1].month} 1, ${b[1].year}`))
        return monthA - monthB
      })
      .map(([_, monthData]) => ({
        month: monthData.month,
        monthLabel: monthData.monthLabel,
        year: monthData.year,
        ...Object.fromEntries(monthData.counts),
      }))
  }

  const renderTooltip = ({ active, payload }) => {
    if (!active || !payload?.length) return null

    const hasData = payload.some(entry => entry.value > 0)
    if (!hasData) return null

    const sortedPayload = [...payload]
      .filter(entry => entry.value > 0)
      .sort((a, b) => b.value - a.value)

    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltipContent}>
          {sortedPayload.slice(0, 10).map((entry, index) => (
            // eslint-disable-next-line
            <div key={`${entry.dataKey}-${index}`} className={styles.tooltipItem}>
              <div
                className={styles.tooltipText}
                style={{ color: entry.color }}
                title={entry.dataKey}
              >
                {entry.dataKey}
              </div>
              <div className={styles.tooltipValue} style={{ color: entry.color }}>
                {entry.value.toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  // eslint-disable-next-line
  const graphData = useMemo(() => {
    return processWeeklyData(hooks?.weeklyData || [])
    // eslint-disable-next-line
  }, [hooks?.weeklyData])
  // eslint-disable-next-line
  const monthlySums = useMemo(() => {
    return graphData.map(monthData => {
      const monthTotal = Object.keys(monthData)
        .filter(key => key !== 'month' && key !== 'monthLabel' && key !== 'year')
        .reduce((monthSum, key) => monthSum + (monthData[key] || 0), 0)

      return {
        month: monthData.monthLabel,
        total: monthTotal,
      }
    })
  }, [graphData])

  // eslint-disable-next-line
  const highestMonth = useMemo(() => {
    if (monthlySums.length === 0) return null

    return monthlySums.reduce(
      (maxMonth, currentMonth) => (currentMonth.total > maxMonth.total ? currentMonth : maxMonth),
      monthlySums[0]
    )
    // eslint-disable-next-line
  }, [monthlySums])

  const yAxisConfig = useMemo(() => {
    if (!graphData.length) return getYAxisConfig(0)
    return getYAxisConfig(highestMonth?.total)
  }, [graphData?.length, highestMonth?.total])

  useEffect(() => {
    if (hooks?.length <= 0) {
      getData()
    }
    // eslint-disable-next-line
  }, [hooks])

  useEffect(() => {
    if (activeTab !== 'hooks') {
      dispatch({ type: 'SET_SELECTED_HOOKS', payload: [] })
    }
    // eslint-disable-next-line
  }, [activeTab, dispatch])

  if (loading) {
    return (
      <div className={styles.container}>
        <div style={{ padding: '20px' }}>
          <Skeleton active paragraph={{ rows: 6 }} />
        </div>
      </div>
    )
  }

  if (!loading && !hooks?.hooks?.length && !hooks?.weeklyData?.length) {
    return (
      <div className={styles.container}>
        <Card>
          <Empty
            description={`No data found for ${specificBrandDetail?.pageName || 'this page'}`}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: '40px 0' }}
          />
        </Card>
      </div>
    )
  }

  const handleCopy = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard')
      })
      .catch(() => {
        message.error('Failed to copy')
      })
  }

  // Merge duplicate hooks and sum their counts
  const mergedHooks = Array.isArray(hooks.hooks)
    ? hooks.hooks.reduce((acc, hook) => {
        const exactTitle = typeof hook.title === 'string' ? hook.title : hook.title?.[0]
        if (!exactTitle) return acc

        if (!acc.has(exactTitle)) {
          acc.set(exactTitle, { ...hook, count: hook.count || 0 })
        } else {
          acc.get(exactTitle).count += hook.count || 0
        }

        return acc
      }, new Map())
    : new Map()

  const uniqueUpdatedHooks = Array.from(mergedHooks.values())
    .filter(hook => hook.title)
    .sort((a, b) => (b.count || 0) - (a.count || 0))

  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={12}>
        <Col span={12}>
          <Card className={styles.hooksCard}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 10,
                }}
                barSize={15}
                layout="horizontal"
                reverseStackOrder={false}
              >
                <CartesianGrid vertical={false} stroke="#E5E5E5" />
                <XAxis
                  dataKey="monthLabel"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666666',
                    fontSize: 11,
                    angle: 45,
                    textAnchor: 'start',
                    dy: 8,
                    dx: 8,
                  }}
                  padding={{ left: 10, right: 10 }}
                  height={100}
                  tickMargin={5}
                  interval={0}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#666666', fontSize: 12 }}
                  width={60}
                />
                <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
                {Array.from(uniqueHooks).map((hook, index) => (
                  <Bar
                    key={hook}
                    yAxisId="right"
                    dataKey={hook}
                    stackId="stack"
                    fill={hookColors.get(hook)}
                    radius={[0, 0, 0, 0]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col span={12}>
          <Card className={styles.hooksCard}>
            <div style={{ height: 400 }}>
              <div className={styles.hooksList}>
                {uniqueUpdatedHooks.map((hook, index) => {
                  const exactTitle = typeof hook.title === 'string' ? hook.title : hook.title?.[0]
                  return (
                    // eslint-disable-next-line
                    <div
                      key={exactTitle}
                      className={styles.hookItem}
                      onClick={e => {
                        if (
                          !e.target.closest(`.${styles.hook}`) &&
                          !e.target.closest(`.${styles.copyButton}`)
                        ) {
                          handleCheckboxChange(exactTitle, !selectedHooks.includes(exactTitle))
                        }
                      }}
                    >
                      <div className={styles.hookHeader}>
                        <div className={styles.checkboxContainer}>
                          <div className={styles.checkbox}>
                            <input
                              type="checkbox"
                              checked={selectedHooks.includes(exactTitle)}
                              onChange={e => handleCheckboxChange(exactTitle, e.target.checked)}
                              onClick={e => e.stopPropagation()}
                            />
                          </div>
                          <div
                            className={styles.colorDot}
                            style={{
                              backgroundColor: index < 30 ? colors[index] : CONSTANT_GRAY,
                            }}
                          />
                        </div>
                        <div className={styles.hookContainer}>
                          {/* eslint-disable-next-line */}
                          <span className={styles.hook} onClick={() => handleCopy(exactTitle)}>
                            {exactTitle}
                          </span>
                          <button
                            type="button"
                            className={styles.copyButton}
                            onClick={e => {
                              e.stopPropagation()
                              handleCopy(exactTitle)
                            }}
                          >
                            <img src={copyIcon} alt="copy" className={styles.copyIcon} />
                          </button>
                        </div>
                        <div className={styles.rightSection}>
                          <div className={styles.count}>{hook.count?.toLocaleString()}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Hooks)
