import React, { useEffect, useState, useMemo, memo } from 'react'
import { Card, Skeleton, message, Empty, Row, Col } from 'antd'
import { RequestService } from '@services/requests'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { useSelector, useDispatch } from 'react-redux'
import Text from '@components/Text/text'
import { HEADLINES_DATA, FILTERS_CLICK_FLAG } from '@utils/constants'
import copyIcon from '@images/AdsPreview/InsightsCopyOrange.svg'
import styles from './styles.module.css'
import { getYAxisConfig } from '../AdCopy/yAxisIntervals'

const Headlines = () => {
  const dispatch = useDispatch()
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const headlines = useSelector(state => state?.discoverAds?.headlinesData)
  const [loading, setLoading] = useState(false)
  const selectedHeadlines = useSelector(state => state?.discoverAds?.selectedHeadlines || [])
  const activeTab = useSelector(state => state?.discoverAds?.activeTab)
  // Create a map to store unique headlines and assign colors
  const uniqueHeadlines = useMemo(() => {
    if (!headlines?.weeklyData) return new Set()

    const totalCounts = new Map()
    headlines.weeklyData.forEach(weekItem => {
      if (weekItem.data) {
        Object.values(weekItem.data).forEach(item => {
          // Handle both string and array values
          if (item?.value && item?.count) {
            const value = Array.isArray(item.value) ? item.value[0] : item.value
            if (value) {
              totalCounts.set(value, (totalCounts.get(value) || 0) + item.count)
            }
          }
        })
      }
    })

    return new Set(
      Array.from(totalCounts.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 30)
        .map(([headline]) => headline)
    )
  }, [headlines?.weeklyData])

  // Assign colors to headlines
  const colors = [
    '#FF0000', // Pure Red
    '#00FF00', // Pure Green
    '#0000FF', // Pure Blue
    '#FF4500', // Orange Red
    '#8B008B', // Dark Magenta
    '#FFD700', // Gold
    '#00CED1', // Dark Turquoise
    '#FF1493', // Deep Pink
    '#32CD32', // Lime Green
    '#BA55D3', // Medium Orchid
    '#FF8C00', // Dark Orange
    '#4169E1', // Royal Blue
    '#2E8B57', // Sea Green
    '#DC143C', // Crimson
    '#9370DB', // Medium Purple
    '#20B2AA', // Light Sea Green
    '#CD853F', // Peru
    '#6495ED', // Cornflower Blue
    '#DB7093', // Pale Violet Red
    '#556B2F', // Dark Olive Green
    '#FF69B4', // Hot Pink
    '#4B0082', // Indigo
    '#8FBC8F', // Dark Sea Green
    '#BC8F8F', // Rosy Brown
    '#DAA520', // Goldenrod
    '#008080', // Teal
    '#800000', // Maroon
    '#9932CC', // Dark Orchid
    '#F4A460', // Sandy Brown
    '#2F4F4F', // Dark Slate Gray
  ]
  const CONSTANT_GRAY = '#A9A9A9' // Color for entries beyond 30
  const headlineColors = useMemo(() => {
    const colorMap = new Map()
    Array.from(uniqueHeadlines).forEach((headline, index) => {
      colorMap.set(headline, colors[index])
    })
    return colorMap
    // eslint-disable-next-line
  }, [uniqueHeadlines])

  const handleCheckboxChange = (title, checked) => {
    let newSelectedHeadlines
    if (checked) {
      newSelectedHeadlines = [...selectedHeadlines, title]
    } else {
      newSelectedHeadlines = selectedHeadlines.filter(t => t !== title)
    }
    dispatch({ type: 'SET_SELECTED_HEADLINES', payload: newSelectedHeadlines })
    if (newSelectedHeadlines?.length === 0) {
      dispatch({ type: FILTERS_CLICK_FLAG, payload: true })
      // You can also dispatch another action or call a function here
    }
  }

  const getData = async () => {
    try {
      setLoading(true)
      const res = await RequestService.get(
        `save/meta-ads-titles/?meta_page_id=${specificBrandDetail?.pageId}&google_advertiser_id=${specificBrandDetail?.googleId}`
      )
      dispatch({
        type: HEADLINES_DATA,
        payload: {
          headlines: res?.data?.result?.data?.json || [],
          weeklyData: res?.data?.result?.data?.weekly_data || [],
          years: res?.data?.result?.data?.years || [],
        },
      })
    } catch (error) {
      message.error('Failed to fetch headlines data')
      console.error('Error fetching headlines:', error)
    } finally {
      setLoading(false)
    }
  }

  const processWeeklyData = weeklyData => {
    if (!weeklyData?.length) return []

    const sortedData = [...weeklyData].sort((a, b) => {
      if (b.year !== a.year) return b.year - a.year
      return b.week - a.week
    })

    const monthlyData = new Map()

    sortedData.forEach(weekItem => {
      const { year, week, data } = weekItem
      if (!data) return

      // For week 0, use January 1st of that year
      // For other weeks, calculate the date properly
      const date = new Date(year, 0, 1)
      if (week > 0) {
        date.setDate(date.getDate() + (week - 1) * 7)
      }

      const month = date.toLocaleString('default', { month: 'short' })
      const actualYear = date.getFullYear()
      const monthYearKey = `${month}-${actualYear}`

      if (!monthlyData.has(monthYearKey)) {
        monthlyData.set(monthYearKey, {
          month,
          year: actualYear,
          monthLabel: `${month} ${actualYear}`,
          counts: new Map(),
        })
      }

      Object.values(data).forEach(item => {
        if (item?.count) {
          const value = Array.isArray(item.value) ? item.value[0] : item.value
          if (value) {
            const currentMonth = monthlyData.get(monthYearKey)
            currentMonth.counts.set(value, (currentMonth.counts.get(value) || 0) + item.count)
          }
        }
      })
    })

    // Sort by date in ascending order (oldest to newest)
    return Array.from(monthlyData.entries())
      .sort((a, b) => {
        if (a[1].year !== b[1].year) return a[1].year - b[1].year
        const monthA = new Date(Date.parse(`${a[1].month} 1, ${a[1].year}`))
        const monthB = new Date(Date.parse(`${b[1].month} 1, ${b[1].year}`))
        return monthA - monthB
      })
      .map(([_, monthData]) => ({
        month: monthData.month,
        monthLabel: monthData.monthLabel,
        year: monthData.year,
        ...Object.fromEntries(monthData.counts),
      }))
  }

  // Transform weekly data for the graph
  // eslint-disable-next-line
  const graphData = useMemo(() => {
    return processWeeklyData(headlines?.weeklyData || [])
  }, [headlines?.weeklyData])

  // eslint-disable-next-line
  const totalCount = useMemo(() => {
    return headlines?.headlines?.reduce((max, copy) => Math.max(max, copy.count || 0), 0) || 0
  }, [headlines?.headlines])
  // Calculate max value and determine Y-axis scale

  useEffect(() => {
    if (headlines?.length <= 0) {
      getData()
    }
    // eslint-disable-next-line
  }, [headlines]) // Runs only once on mount

  // Reset filters when tab changes
  // eslint-disable-next-line
  const monthlySums = useMemo(() => {
    // eslint-disable-next-line
    return graphData.map(monthData => {
      const monthTotal = Object.keys(monthData)
        .filter(key => key !== 'month' && key !== 'monthLabel' && key !== 'year') // Exclude labels
        .reduce((monthSum, key) => monthSum + (monthData[key] || 0), 0) // Sum all counts in the month

      return {
        month: monthData.monthLabel,
        total: monthTotal,
      }
    })
  }, [graphData])
  const highestMonth = useMemo(() => {
    if (monthlySums.length === 0) return null

    return monthlySums.reduce(
      (maxMonth, currentMonth) => (currentMonth.total > maxMonth.total ? currentMonth : maxMonth),
      monthlySums[0]
    ) // Start with the first month as initial max
  }, [monthlySums])
  const yAxisConfig = useMemo(() => {
    if (!graphData.length) return getYAxisConfig(0)
    return getYAxisConfig(highestMonth?.total)
  }, [graphData?.length, highestMonth?.total])

  useEffect(() => {
    if (activeTab !== 'headlines') {
      dispatch({ type: 'SET_SELECTED_HEADLINES', payload: [] })
    }
    // eslint-disable-next-line
  }, [activeTab, dispatch])

  if (loading) {
    return (
      <div className={styles.container}>
        <div style={{ padding: '20px' }}>
          <Skeleton active paragraph={{ rows: 6 }} />
        </div>
      </div>
    )
  }

  if (!loading && !headlines?.headlines?.length && !headlines?.weeklyData?.length) {
    return (
      <div className={styles.container}>
        <Card>
          <Empty
            description={`No data found for ${specificBrandDetail?.pageName || 'this page'}`}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: '40px 0' }}
          />
        </Card>
      </div>
    )
  }
  const handleCopy = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard')
      })
      .catch(() => {
        message.error('Failed to copy')
      })
  }
  // Merge duplicate headlines and sum their counts
  const mergedHeadlines = Array.isArray(headlines.headlines)
    ? headlines.headlines.reduce((acc, headline) => {
        const exactTitle = typeof headline.title === 'string' ? headline.title : headline.title?.[0]
        if (!exactTitle) return acc

        if (!acc.has(exactTitle)) {
          acc.set(exactTitle, { ...headline, count: headline.count || 0 })
        } else {
          acc.get(exactTitle).count += headline.count || 0
        }

        return acc
      }, new Map())
    : new Map()

  const uniqueUpdatedHeadlines = Array.from(mergedHeadlines.values())
    .filter(headline => headline.title) // Ignore empty or null headlines
    .sort((a, b) => (b.count || 0) - (a.count || 0))

  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={12}>
        <Col span={12}>
          <Card className={styles.headlinesCard}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 10,
                }}
                barSize={15}
                layout="horizontal"
                reverseStackOrder={false}
              >
                <CartesianGrid vertical={false} stroke="#E5E5E5" />
                <XAxis
                  dataKey="monthLabel"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666666',
                    fontSize: 11,
                    angle: 45,
                    textAnchor: 'start',
                    dy: 8,
                    dx: 8,
                  }}
                  padding={{ left: 10, right: 10 }}
                  height={100}
                  tickMargin={5}
                  interval={0}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#666666', fontSize: 12 }}
                  width={60}
                />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  content={({ active, payload }) => {
                    if (active && payload && payload?.length) {
                      const hasData = payload.some(entry => entry.value > 0)
                      if (!hasData) return null

                      const sortedPayload = [...payload]
                        .filter(entry => entry.value > 0)
                        .sort((a, b) => b.value - a.value)

                      return (
                        <div
                          style={{
                            padding: '12px',
                            background: 'white',
                            border: '1px solid #e8e8e8',
                            borderRadius: '4px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                            minWidth: '200px',
                            maxWidth: '300px',
                          }}
                        >
                          <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                            {sortedPayload.slice(0, 10).map((entry, index) => (
                              <div
                                key={entry.dataKey}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom:
                                    index < Math.min(sortedPayload.length, 10) - 1 ? '8px' : '0',
                                  fontSize: '13px',
                                  color: '#333',
                                }}
                              >
                                <div
                                  style={{
                                    flex: '1 1 auto',
                                    paddingRight: '16px',
                                    color: entry.color,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px',
                                  }}
                                  title={entry.dataKey}
                                >
                                  {entry.dataKey}
                                </div>
                                <div
                                  style={{
                                    flex: '0 0 auto',
                                    minWidth: '40px',
                                    textAlign: 'right',
                                    color: entry.color,
                                    fontWeight: '500',
                                  }}
                                >
                                  {entry.value}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                    return null
                  }}
                />
                {Array.from(uniqueHeadlines).map((headline, index) => (
                  <Bar
                    key={headline}
                    yAxisId="right"
                    dataKey={headline}
                    stackId="stack"
                    fill={headlineColors.get(headline)}
                    radius={[0, 0, 0, 0]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col span={12}>
          <Card className={styles.headlinesCard}>
            <div style={{ height: 400 }}>
              <div className={styles.headlinesList}>
                {uniqueUpdatedHeadlines.map((headline, index) => {
                  const exactTitle =
                    typeof headline.title === 'string' ? headline.title : headline.title?.[0]
                  return (
                    // eslint-disable-next-line
                    <div
                      key={exactTitle}
                      className={styles.headlineItem}
                      onClick={e => {
                        if (
                          !e.target.closest(`.${styles.headline}`) &&
                          !e.target.closest(`.${styles.copyButton}`)
                        ) {
                          handleCheckboxChange(exactTitle, !selectedHeadlines.includes(exactTitle))
                        }
                      }}
                    >
                      <div className={styles.headlineHeader}>
                        <div className={styles.checkboxContainer}>
                          <div className={styles.checkbox}>
                            <input
                              type="checkbox"
                              checked={selectedHeadlines.includes(exactTitle)}
                              onChange={e => handleCheckboxChange(exactTitle, e.target.checked)}
                              onClick={e => e.stopPropagation()}
                            />
                          </div>
                          <div
                            className={styles.colorDot}
                            style={{
                              backgroundColor: index < 30 ? colors[index] : CONSTANT_GRAY,
                            }}
                          />
                        </div>
                        <div className={styles.headlineContainer}>
                          {/* eslint-disable-next-line */}
                          <span className={styles.headline} onClick={() => handleCopy(exactTitle)}>
                            {exactTitle}
                          </span>
                          <button
                            type="button"
                            className={styles.copyButton}
                            onClick={e => {
                              e.stopPropagation()
                              handleCopy(exactTitle)
                            }}
                          >
                            <img src={copyIcon} alt="copy" className={styles.copyIcon} />
                          </button>
                        </div>
                        <div className={styles.rightSection}>
                          <div className={styles.count}>{headline.count?.toLocaleString()}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Headlines)
