import React, { useEffect, useState } from 'react'
import { Card, Col, Image, Row, Dropdown, Menu, Button, Modal, Input, Spin, Tabs } from 'antd'
import { RequestService, bucketBaseURL } from '@services/requests'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Text from '@components/Text/text'
import {
  BOARD_DETAIL_DATA,
  BOARDS_LIST,
  BOARD_LIST,
  BOARDS_LIST_COUNT,
  SHARED_BOARDS,
  ACTIVE_KEY,
  SHARED_AND_PRIVATE_BOARDS_COUNT,
  ADS_CHANNEL,
} from '@utils/constants'
import accountsTracking from '@services/accountsTracking'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import ShareModal from '@components/DiscoverAds/AdsModal/ShareModal'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import MoreOptionsIcon from '@images/DiscoverAds/ButtonIcons/MoreOptions.png'
import HashIcon from '@images/DiscoverAds/Boards/Frame 1410100316.png'
import InvitedUsersModal from '@components/DiscoverAds/AdsModal/InvitedUsersModal'
import noAdsImage from '@images/DiscoverAds/Boards/noAdsImageTemp.png'
import styles from './boardList.module.css'

const BoardList = ({ boardsList }) => {
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const publicLinkStatus = localStorage.getItem('public_status')
  const { TabPane } = Tabs
  const [sharedBoardsCounter, setSharedBoardsCounter] = useState()
  const [activeKey, setActiveKey] = useState('1')
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [isInvitedOpen, setIsInvitedOpen] = useState(false)
  const [sharedBaordData, setSharedBaordData] = useState([])
  const [originalBoards, setOriginalBoards] = useState([])
  const [boards, setBoards] = useState([])
  const [savedAds, setSavedAds] = useState([])
  const [loading, setLoading] = useState(false)
  const [isRenameModalVisible, setRenameModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [currentBoardName, setCurrentBoardName] = useState('')
  const [currentBoardId, setCurrentBoardId] = useState()
  const [newBoardName, setNewBoardName] = useState('')
  const boardSearch = useSelector(state => state?.discoverAds?.boardSearch)
  const baordsList = useSelector(state => state?.discoverAds?.boardsList)
  const sharedBoardsList = useSelector(state => state?.discoverAds?.sharedBoardsList)
  const history = useHistory()
  const dispatch = useDispatch()
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const [fullBoardCount, setFullBoardCount] = useState()
  const boardsListCount = useSelector(state => state?.discoverAds?.boardListCount)
  const activeKeyFromStore = useSelector(state => state?.discoverAds?.activeKey)
  const [permission, setPermission] = useState('')
  const [privateBoardStatus, setPrivateBoardStatus] = useState()
  useEffect(() => {
    if (activeKeyFromStore === '1') {
      setActiveKey('1')
    }
  }, [activeKeyFromStore])
  useEffect(() => {
    // setFullBoardCount(baordsList.length + sharedBoardsList.length)
    const data = [...baordsList, ...sharedBoardsList]
    const uniqueBoardsData = data.reduce((acc, currentBoard) => {
      const boardExists = acc.find(board => board.board_id === currentBoard.board_id)
      if (!boardExists) {
        acc.push(currentBoard)
      }
      return acc
    }, [])
    const sharedBoardsCounterData = uniqueBoardsData.filter(board => board?.is_shared)
    setSharedBoardsCounter(sharedBoardsCounterData.length)
    setFullBoardCount(uniqueBoardsData.length)
    dispatch({ type: SHARED_AND_PRIVATE_BOARDS_COUNT, payload: uniqueBoardsData.length })

    if (activeKey === '1') {
      // Tab 1: All Boards
      const allBoards = [...baordsList, ...sharedBoardsList]

      // Make allBoards unique based on board_id
      const uniqueBoards = allBoards?.reduce((acc, current) => {
        const isDuplicate = acc.find(board => board.board_id === current.board_id)
        if (!isDuplicate) {
          acc.push(current)
        }
        return acc
      }, [])
      setBoards(uniqueBoards)
      setOriginalBoards(uniqueBoards)
      dispatch({ type: ACTIVE_KEY, payload: '' })
    } else if (activeKey === '2') {
      // Tab 2: My Boards (is_shared: false)
      const myBoards = baordsList.filter(board => !board.is_shared)
      setBoards(myBoards)
      setOriginalBoards(myBoards)
      dispatch({ type: ACTIVE_KEY, payload: '' })
    } else if (activeKey === '3') {
      const allBoards = [...baordsList, ...sharedBoardsList]
      const uniqueBoards = allBoards.reduce((acc, currentBoard) => {
        const boardExists = acc.find(board => board.board_id === currentBoard.board_id)
        if (!boardExists) {
          acc.push(currentBoard)
        }
        return acc
      }, [])
      const sharedBoards = uniqueBoards.filter(board => board?.is_shared)
      setBoards(sharedBoards)
      setOriginalBoards(sharedBoards)
      dispatch({ type: ACTIVE_KEY, payload: '' })
    }

    // eslint-disable-next-line
  }, [activeKey, baordsList, sharedBoardsList])

  const handleSharedBoards = async () => {
    if (shareBoardLink) {
      if (publicLinkStatus === 'public') {
        try {
          const response = await RequestService.get(
            `/create/board-ads/${shareBoardLink}/?role=${publicLinkStatus}`
          )
          const updatedBoards = response.data.boards?.map(board => ({
            ...board,
            is_shared: true,
          }))
          dispatch({ type: SHARED_BOARDS, payload: updatedBoards })
          // localStorage.removeItem('public_status')
        } catch (error) {
          // console.log('🚀 ~ handleSharedBoards ~ error:', error)
        }
      } else {
        try {
          const response = await RequestService.get(`/create/board-ads/${shareBoardLink}/`)
          const updatedBoards = response.data.boards?.map(board => ({
            ...board,
            is_shared: true,
          }))
          dispatch({ type: SHARED_BOARDS, payload: updatedBoards })
          localStorage.removeItem('share_Baord_Link')
        } catch (error) {
          // console.log('🚀 ~ handleSharedBoards ~ error:', error)
        }
      }
    }
  }
  const handleDiscoverAdsBoard = async () => {
    setLoading(true)
    try {
      const response = await RequestService.get('/create/board-ads/')
      dispatch({ type: BOARDS_LIST, payload: response?.data?.boards })
      dispatch({ type: BOARDS_LIST_COUNT, payload: response?.data?.boards?.length })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessToken = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    const inviteFromString = localStorage.getItem('inviteFrom')
    const inviteFrom = JSON?.parse(inviteFromString)
    const boardPermission = inviteForm?.boardRole
    const isBoardPrivate = inviteFrom?.isBoardPrivate
    setPrivateBoardStatus(isBoardPrivate)
    setPermission(boardPermission)
    handleDiscoverAdsBoard()

    // eslint-disable-next-line
    if (userEmail === inviteFormEmail && isBoardPrivate) {
      handleSharedBoards()
    } else if (userEmail !== inviteFormEmail && isBoardPrivate) {
      // storeAndFetchData()
    } else {
      handleSharedBoards()
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (baordsList.length > 0) {
      setBoards(baordsList)
      setOriginalBoards(baordsList)
    }
    // eslint-disable-next-line
  }, [baordsList])
  const showRenameModal = (boardName, boardId) => {
    setCurrentBoardName(boardName)
    setCurrentBoardId(boardId)
    setNewBoardName(boardName)
    setRenameModalVisible(true)
  }
  const handleViewAll = () => {
    setIsInvitedOpen(true)
    setIsShareModalOpen(false)
  }

  const handleRename = async () => {
    if (newBoardName && newBoardName !== currentBoardName) {
      const data = {
        name: newBoardName,
      }
      try {
        await RequestService.put(`/create/boards/${currentBoardId}/`, data)

        // Update the boards array with the new name
        setBoards(prevBoards =>
          prevBoards.map(board =>
            board.board_id === currentBoardId ? { ...board, board_name: newBoardName } : board
          )
        )
        setOriginalBoards(prevBoards =>
          prevBoards.map(board =>
            board.board_id === currentBoardId ? { ...board, board_name: newBoardName } : board
          )
        )
        dispatch({ type: ACTIVE_KEY, payload: '1' })
        try {
          const response = await RequestService.get('/create/board-ads/')
          dispatch({ type: BOARDS_LIST, payload: response?.data?.boards })
          dispatch({ type: BOARDS_LIST_COUNT, payload: response.data.boards.length })

          // setLoading(false)
        } catch (error) {
          // console.log('🚀 ~ handleOk ~ error:', error)
          // setLoading(false)
        }
      } catch (error) {
        // console.log('Error renaming board:', error)
      }
    }
    setRenameModalVisible(false)
  }

  const showDeleteModal = (boardName, boardId, board) => {
    const trackingData = {
      eventName: 'Delete a Board Click',
      userStatus: 'Free',
      pageName: currentUrl,
      sourceName: 'Clicked Delete Button in Dropdown',
      hasBoards: true,
      adResultsCountBoard: board?.ad_count,
    }
    accountsTracking(trackingData)
    setCurrentBoardId(boardId)
    setCurrentBoardName(boardName)
    setDeleteModalVisible(true)
  }

  const handleDelete = async () => {
    try {
      await RequestService.delete(`/create/boards/${currentBoardId}`)
      setBoards(prevBoards => prevBoards.filter(board => board?.board_id !== currentBoardId))
      setOriginalBoards(prevBoards =>
        prevBoards.filter(board => board?.board_id !== currentBoardId)
      )

      const updatedSavedAds = { ...savedAds }
      delete updatedSavedAds[currentBoardName]
      setSavedAds(updatedSavedAds)
      dispatch({ type: BOARDS_LIST_COUNT, payload: boardsListCount - 1 })
      const trackingData = {
        eventName: 'Delete a Board Success',
        userStatus: 'Free',
        pageName: currentUrl,
        sourceName: 'Clicked OK Button in Modal',
        hasBoards: true,
      }
      accountsTracking(trackingData)
    } catch (error) {
      // console.log('Error deleting board:', error)
    }
    dispatch({ type: ACTIVE_KEY, payload: '1' })
    try {
      const response = await RequestService.get('/create/board-ads/')
      dispatch({ type: BOARDS_LIST, payload: response?.data?.boards })
      dispatch({ type: BOARDS_LIST_COUNT, payload: response.data.boards.length })

      // setLoading(false)
    } catch (error) {
      // console.log('🚀 ~ handleOk ~ error:', error)
      // setLoading(false)
    }
    setDeleteModalVisible(false)
  }
  const showShareModal = baordData => {
    setSharedBaordData(baordData)
    setIsShareModalOpen(true)
  }

  const handleShareOk = () => {
    setIsShareModalOpen(false)
  }
  const handleInvitedOk = () => {
    setIsInvitedOpen(false)
  }

  const handleShareCancel = () => {
    setIsShareModalOpen(false)
  }
  const handleInvitedCancel = () => {
    setIsInvitedOpen(false)
  }
  const handleInvitedBack = () => {
    setIsInvitedOpen(false)
    setIsShareModalOpen(true)
  }

  const renderMenu = (boardName, boardId, board) => {
    if (board.role === 'Owner' || board.role === 'Admin') {
      return (
        <Menu>
          <Menu.Item key="1" onClick={() => showShareModal(board)}>
            Share
          </Menu.Item>
          <Menu.Item key="2" onClick={() => showRenameModal(boardName, boardId)}>
            Rename
          </Menu.Item>
          <Menu.Item key="3" onClick={() => showDeleteModal(boardName, boardId, board)}>
            Delete
          </Menu.Item>
        </Menu>
      )
    } // eslint-disable-next-line
    else if (board.role === 'Editor') {
      return (
        <Menu>
          <Menu.Item key="2" onClick={() => showRenameModal(boardName, boardId)}>
            Rename
          </Menu.Item>
        </Menu>
      )
    } else {
      return null
    }
    // return null // Return null for Viewer or any other role with no options
  }

  const handleBoardClick = board => {
    if (board?.ads?.length > 0) {
      localStorage.setItem('board_detail_id', board?.board_id)
      dispatch({ type: BOARD_DETAIL_DATA, payload: board })
      dispatch({ type: BOARD_LIST, payload: boardsList })
      history.push('/dashboard/boards/detail')
    }
  }
  useEffect(() => {
    if (boardSearch.length > 0) {
      setBoards(
        originalBoards.filter(board =>
          board?.board_name?.toLowerCase().includes(boardSearch.toLowerCase())
        )
      )
    } else {
      // If search is cleared, restore the original board list
      setBoards(originalBoards)
    }
  }, [boardSearch, originalBoards])

  const hanldeDashboardRedirect = () => {
    history.push('/dashboard/creative-library')
  }
  useEffect(() => {
    dispatch({ type: ADS_CHANNEL, payload: 'All' })
    // eslint-disable-next-line
  }, [])
  const activeKeyChange = key => {
    const tab = activeKey
    setActiveKey(key)
    const trackingPayload = {
      pageName: 'Creative Library',
      // lastPageUrl,
      eventName: 'Switch Tab Click',
      sourceName:
        // eslint-disable-next-line
        key === '1'
          ? 'Clicked All Baords '
          : key === '2'
          ? 'Clicked My Baords'
          : 'Clicked Shared Baords',
      userStatus: 'Free',
      // appSection: '',
      tabSelected:
        // eslint-disable-next-line
        key === '1' ? 'All Boards' : key === '2' ? 'My Boards' : 'Shared Boards',
      currentTab:
        // eslint-disable-next-line
        tab === '1' ? 'All Boards' : tab === '2' ? 'My Boards' : 'Shared Boards',
    }
    accountsTracking(trackingPayload)
  }
  return (
    <div>
      <Tabs style={{ marginTop: 12 }} activeKey={activeKey} onChange={key => activeKeyChange(key)}>
        <TabPane
          tab={
            <span
              style={{
                letterSpacing: '1px',
                color: activeKey === '1' ? '#26282C' : '#8F8F8F',
                fontWeight: activeKey === '1' ? 'bold' : 'normal',
              }}
              className={styles.tabTitle}
            >
              All Boards ({fullBoardCount})
            </span>
          }
          key="1"
        />
        <TabPane
          tab={
            <span
              style={{
                letterSpacing: '1px',
                color: activeKey === '2' ? '#26282C' : '#8F8F8F',
                fontWeight: activeKey === '2' ? 'bold' : 'normal',
              }}
              className={styles.tabTitle}
            >
              My Boards ({baordsList.filter(board => !board.is_shared).length})
            </span>
          }
          key="2"
        />
        <TabPane
          tab={
            <span
              style={{
                letterSpacing: '1px',
                color: activeKey === '3' ? '#26282C' : '#8F8F8F',
                fontWeight: activeKey === '3' ? 'bold' : 'normal',
              }}
              className={styles.tabTitle}
            >
              Shared Boards ({sharedBoardsCounter})
            </span>
          }
          key="3"
        />
      </Tabs>
      {
        // eslint-disable-next-line
        loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
            <Spin />
          </div>
        ) : boards?.length > 0 ? (
          <>
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 2,
                1400: 4,
                1800: 4,
                2200: 6,
                2500: 7,
              }}
            >
              <Masonry columnsCount={3} gutter="20px">
                {boards?.map(board => (
                  <div style={{ marginTop: 12, cursor: 'pointer' }} key={board}>
                    {board?.ad_count >= 0 && (
                      <Card
                        title={
                          <Text
                            onClick={
                              board?.ad_count <= 0
                                ? hanldeDashboardRedirect
                                : () => handleBoardClick(board)
                            }
                          >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                              <img src={HashIcon} alt="hash-icon" />
                              <Text size={18} isBold style={{ letterSpacing: '1px' }}>
                                {board?.board_name}
                              </Text>
                              <div className={styles.counterContainer1}>
                                <Text isBold size={12} style={{ marginTop: '4px' }}>
                                  {board?.ad_count}
                                </Text>
                              </div>
                            </div>
                            <div style={{ color: '#8F8F8F', fontSize: 14 }}>
                              Created At {dayjs(board?.created_at).format('MMM D, YYYY')}
                            </div>
                          </Text>
                        }
                        extra={
                          <div id="ads-card-more-options">
                            {renderMenu(board?.board_name, board?.board_id, board) && (
                              <Dropdown
                                overlay={renderMenu(board?.board_name, board?.board_id, board)}
                                trigger={['click']}
                                getPopupContainer={() =>
                                  document.getElementById('ads-card-more-options')
                                }
                              >
                                <Button
                                  type="text"
                                  style={{ border: 'none' }}
                                  icon={<img src={MoreOptionsIcon} alt="more-options" width={32} />}
                                />
                              </Dropdown>
                            )}
                          </div>
                        }
                      >
                        {board.ad_count <= 0 ? (
                          // eslint-disable-next-line
                          <div style={{ textAlign: 'center' }} onClick={hanldeDashboardRedirect}>
                            {/* <Image
                              preview={false}
                              src={noAdsImage}
                              alt="no preview"
                              style={{ height: '250px' }}
                            /> */}
                            <Text isBold size={18} style={{ color: '#f16434' }}>
                              Nothing to see here.
                            </Text>
                            <Text isBold size={15} style={{ color: '#f16434', marginTop: 12 }}>
                              To get started, try adding some ads to this board.
                            </Text>
                          </div>
                        ) : (
                          // eslint-disable-next-line
                          <div
                            className={styles.boardThumbnails}
                            style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                            onClick={() => handleBoardClick(board)}
                          >
                            {board?.ads.slice(0, 2).map((ad, index) => (
                              // eslint-disable-next-line
                              <div style={{ width: '48%' }} key={index}>
                                {
                                  // eslint-disable-next-line
                                  ad.ads_grader_image_url || ad.image_url ? (
                                    <Image
                                      preview={false}
                                      src={
                                        ad.ads_grader_image_url
                                          ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad.ads_grader_image_url}`
                                          : ad.image_url
                                      }
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  ) : ad?.ads_grader_video_url || ad.video_url ? (
                                    <div
                                      style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '150px',
                                      }}
                                    >
                                      <video
                                        style={{ width: '100%', height: '150px' }}
                                        src={
                                          ad?.ads_grader_video_url
                                            ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad?.ads_grader_video_url}`
                                            : ad.video_url
                                        }
                                        onPlay={e => e.preventDefault()} // Prevents playing
                                      >
                                        <track
                                          kind="captions"
                                          srcLang="en"
                                          src="captions_en.vtt"
                                          label="English captions"
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          backgroundColor: '#FF0000', // YouTube red color
                                          width: '40px',
                                          height: '25px',
                                          color: 'white',
                                          borderRadius: '5px', // Rounded rectangle shape
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="white"
                                          width="20px"
                                          height="20px"
                                        >
                                          <path d="M8 5v14l11-7z" />
                                        </svg>
                                      </div>
                                    </div>
                                  ) : (
                                    <Image
                                      preview={false}
                                      src={noPreviewImage}
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  )
                                }
                              </div>
                            ))}

                            {/* Second row: 4 images */}
                            {board?.ads.slice(2, 6).map((ad, index) => (
                              // eslint-disable-next-line
                              <div style={{ maxWidth: '20%' }} key={index}>
                                {
                                  // eslint-disable-next-line
                                  ad.ads_grader_image_url || ad.image_url ? (
                                    <img
                                      src={
                                        ad?.ads_grader_image_url
                                          ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad?.ads_grader_image_url}`
                                          : ad?.image_url
                                      }
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  ) : ad?.ads_grader_video_url || ad.video_url ? (
                                    <div
                                      style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '150px',
                                      }}
                                    >
                                      <video
                                        style={{ width: '100%', height: '150px' }}
                                        src={
                                          ad?.ads_grader_video_url
                                            ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad?.ads_grader_video_url}`
                                            : ad?.video_url
                                        }
                                        onPlay={e => e.preventDefault()} // Prevents playing
                                      >
                                        <track
                                          kind="captions"
                                          srcLang="en"
                                          src="captions_en.vtt"
                                          label="English captions"
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          backgroundColor: '#FF0000', // YouTube red color
                                          width: '40px',
                                          height: '25px',
                                          color: 'white',
                                          borderRadius: '5px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="white"
                                          width="20px"
                                          height="20px"
                                        >
                                          <path d="M8 5v14l11-7z" />
                                        </svg>
                                      </div>
                                    </div>
                                  ) : (
                                    <Image
                                      preview={false}
                                      src={noPreviewImage}
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  )
                                }
                              </div>
                            ))}

                            {board?.ads.slice(6, 9).map((ad, index) => (
                              // eslint-disable-next-line
                              <div style={{ maxWidth: '20%' }} key={index}>
                                {
                                  // eslint-disable-next-line
                                  ad.ads_grader_image_url || ad.image_url ? (
                                    <Image
                                      preview={false}
                                      src={
                                        ad.ads_grader_image_url
                                          ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad.ads_grader_image_url}`
                                          : ad.image_url
                                      }
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  ) : ad?.ads_grader_video_url || ad.video_url ? (
                                    <div
                                      style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '150px',
                                      }}
                                    >
                                      <video
                                        style={{ width: '100%', height: '150px' }}
                                        src={
                                          ad?.ads_grader_video_url
                                            ? `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${ad?.ads_grader_video_url}`
                                            : ad.video_url
                                        }
                                        onPlay={e => e.preventDefault()} // Prevents playing
                                      >
                                        <track
                                          kind="captions"
                                          srcLang="en"
                                          src="captions_en.vtt"
                                          label="English captions"
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          backgroundColor: '#FF0000', // YouTube red color
                                          width: '40px',
                                          height: '25px',
                                          color: 'white',
                                          borderRadius: '5px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="white"
                                          width="20px"
                                          height="20px"
                                        >
                                          <path d="M8 5v14l11-7z" />
                                        </svg>
                                      </div>
                                    </div>
                                  ) : (
                                    <Image
                                      preview={false}
                                      src={noPreviewImage}
                                      alt={ad.company}
                                      style={{ width: '100%', height: '150px' }}
                                    />
                                  )
                                }
                              </div>
                            ))}

                            {board?.ad_count > 9 && (
                              <div style={{ width: '23%', position: 'relative' }}>
                                <Image
                                  preview={false}
                                  src={board?.ads[9]?.image_url}
                                  alt={board?.ads[9]?.company}
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    filter: 'brightness(50%)',
                                  }}
                                />
                                <div className={styles.extraImages}>{board?.ad_count - 10}+</div>
                              </div>
                            )}
                          </div>
                        )}
                      </Card>
                    )}
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </>
        ) : (
          <Text size={24} isBold style={{ marginTop: 40, textAlign: 'center', color: '#f16434' }}>
            No Boards Found.
          </Text>
        )
      }

      {/* </Row> */}

      {/* Rename Modal */}
      <Modal
        title="Rename Board"
        visible={isRenameModalVisible}
        onOk={handleRename}
        onCancel={() => setRenameModalVisible(false)}
      >
        <Input
          value={newBoardName}
          onChange={e => setNewBoardName(e.target.value)}
          placeholder="Enter new board name"
        />
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Delete Board"
        visible={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete the board &quot;{currentBoardName}&quot;?</p>
      </Modal>
      {isShareModalOpen && (
        <ShareModal
          shareBoardData={sharedBaordData}
          handleViewAll={handleViewAll}
          modalOpen={isShareModalOpen}
          handleOk={handleShareOk}
          handleCancel={handleShareCancel}
        />
      )}
      {isInvitedOpen && (
        <InvitedUsersModal
          shareBoardData={sharedBaordData}
          modalOpen={isInvitedOpen}
          handleOk={handleInvitedOk}
          handleCancel={handleInvitedCancel}
          handleBack={handleInvitedBack}
        />
      )}
    </div>
  )
}

export default BoardList
