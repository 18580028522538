import React, { useState } from 'react'
import { Text } from '@components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, Dropdown, Menu, Input, Spin, message, Modal, Tooltip, Row, Col, Button } from 'antd'
import verticalDivider from '@images/AdsPreview/verticalDivider.png'
import { CheckOutlined, CloseOutlined, MinusOutlined, PushpinOutlined } from '@ant-design/icons'
import { RequestService, bucketBaseURL } from '@services/requests'
import activeIcon from '@images/AdsPreview/activeIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  API_DATA_BOARDS_LIST,
  FETCH_SPECTFIC_BOARD_DATA,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
  SAVED_DESCRIPTION,
  BRAND_STATUS,
  BRAND_STATUS_LOADER,
  FOLLOW_BRANDS_LOADER,
  TRIGGER_UPDATE_FOLLOW_BRANDS,
} from '@utils/constants'
import dayjs from 'dayjs'
import GoogleIcon from '@images/DiscoverAds/SearchBar/GoogleIcon.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import AddIcon from '@images/DiscoverAds/ButtonIcons/add.png'
import AddIconOrange from '@images/DiscoverAds/ButtonIcons/addOrange.svg'
import CopyTextIcon from '@images/DiscoverAds/ButtonIcons/CopyText.png'
import CopyTextIconOrange from '@images/DiscoverAds/ButtonIcons/copyTextOrange.svg'
import ShareIcon from '@images/DiscoverAds/ButtonIcons/share.png'
import ShareIconOrange from '@images/DiscoverAds/ButtonIcons/shareOrange.svg'
import DownloadIcon from '@images/DiscoverAds/ButtonIcons/Download.png'
import DownloadIconOrange from '@images/DiscoverAds/ButtonIcons/DownloadOrange.svg'
import SaveAdIcon from '@images/DiscoverAds/ButtonIcons/SaveAd.png'
import SaveAdIconOrange from '@images/DiscoverAds/ButtonIcons/SaveAdOrange.svg'
import SavedAdIcon from '@images/DiscoverAds/ButtonIcons/SavedAd.png'
import SavedAdIconOrange from '@images/DiscoverAds/ButtonIcons/SavedAdOrange.svg'
import MoreOptionsIcon from '@images/DiscoverAds/ButtonIcons/MoreOptions.png'
import FollowIcon from '@images/DiscoverAds/MoreOptions/followBrandIcon.png'
import CopyLinkIcon from '@images/DiscoverAds/MoreOptions/copyIcon.png'
import PinIcon from '@images/DiscoverAds/MoreOptions/pinIcon.png'
import accountsTracking from '@services/accountsTracking'
import { addBoard } from '../../../store/actions'
import AdsModal from '../AdsModal'
import styles from './adsCard.module.css'

const AdsCard = ({
  savedAdsFlowRoute,
  savedAdsFlow,
  adsData,
  onShowModal,
  onPrevious,
  onNext,
  selectedData,
  isFirst,
  isLast,
  isSavedInSavedAds,
  isAdPinned,
  boardDetailRoute,
  followBrandsMainFlow,
}) => {
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const followBrandsLoader = useSelector(state => state?.discoverAds?.followBrandsLoader)
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  const [ThumbnailLodaer, setThumbnailLoader] = useState(false)
  const [descriptionLoader, setDescriptionLoader] = useState(false)
  const boardDetailData = useSelector(state => state?.discoverAds?.boardDetailData)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [adsBoardData, setAdsBoardData] = useState(apiDataBoardsList)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPinModalOpen, setIsPinModalOpen] = useState(false)
  const [isSaved, setIsSaved] = useState(adsData?.status)
  const [isFollowed, setIsFollowed] = useState(adsData?.is_followed)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isUnSaveLoading, setIsUnSaveLoading] = useState(false)
  const responseForBrandStatus = useSelector(state => state?.discoverAds?.brandStatus)
  const loaderForBrandsStatus = useSelector(state => state?.discoverAds?.brandStatusLoader)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const showModal = () => {
    setIsModalOpen(true)
    onShowModal()
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handlePinOk = () => {
    // Logic to pin the ad to a board
    setIsPinModalOpen(false)
  }

  const handlePinCancel = () => {
    setIsPinModalOpen(false)
  }

  const handleSearch = e => {
    e.stopPropagation()
    setSearchTerm(e.target.value)
  }
  const fetchSpecificBoardsList = async () => {
    const singleBoardID = localStorage.getItem('board_detail_id')
    try {
      const response = await RequestService.get(`/create/board-ad-detail/${singleBoardID}/`)
      dispatch({ type: FETCH_SPECTFIC_BOARD_DATA, payload: response?.data?.boards[0]?.ads })
      dispatch({
        type: FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
        payload: response?.data?.boards[0],
      })
    } catch (error) {
      // console.log('🚀 ~ fetchSpecificBoardsList ~ error:', error)
    }
  }
  const getSavedAds = async () => {
    // setLoading(true)
    try {
      const response = await RequestService.get('/save/ads/')
      const ads = response?.data?.meta_ads || []

      dispatch({ type: SAVED_PAGE_ALL_DATA, payload: ads })
      dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: ads })

      const tagsSet = new Set()
      ads.forEach(item => {
        if (item.tags && Array.isArray(item.tags)) {
          item.tags.forEach(tag => {
            tagsSet.add(tag)
          })
        }
      })
      dispatch({ type: SAVED_PAGE_UNIQUE_TAGS, payload: [...tagsSet] })
      setIsUnSaveLoading(false)
      message.success('Ad unsaved successfully')
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      setIsUnSaveLoading(false)
    }
  }
  const handleUnsaveAds = async () => {
    const trackingPayload = {
      eventName: 'Unsave Ad Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Un Save Ad Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    setIsUnSaveLoading(true)

    try {
      // eslint-disable-next-line
      await RequestService.delete(`/un-save/assigned-ads/?ad_id=${adsData?._id}`)
      getSavedAds()
      const tracking = {
        eventName: 'Unsave Ad Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Un Save Ad Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
    } catch (error) {
      setIsUnSaveLoading(false)
    }
  }
  const handleSaveAds = async () => {
    const trackingPayload = {
      eventName: 'Save Ad Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Saved Ad Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    setIsSaveLoading(true)
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      is_active: true,
    }
    try {
      await RequestService.post('/save/ads/', data)
      await RequestService.post('/ads-preview/competitors-prod/', data)
      setIsSaveLoading(false)
      setIsSaved(true)
      message.success('Ad Saved')
      const tracking = {
        eventName: 'Save Ad Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Saved Ad Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
    } catch (error) {
      setIsSaveLoading(false)
      setIsSaved(false)
      console.error('Error creating board:', error)
    }
  }
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setAdsBoardData(response?.data)
    } catch (error) {
      // console.log('🚀 ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }
  const handlePinToBoard = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = adsBoardData.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
        is_pinned: true,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Pinned to board')
        setIsPinModalOpen(false)
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }
  const handlePinToBoardFromDetailPage = async (value, id) => {
    const trimmedValue = value?.trim().toLowerCase()
    const exists = adsBoardData.some(board => board.name.toLowerCase() === trimmedValue)

    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      board: id,
      is_pinned: true,
    }
    try {
      await RequestService.post('/create/pinned-ads/', data)
      hanldeDiscoverAdsBoard()
      fetchSpecificBoardsList()
      message.success('Pinned to board')
      setIsPinModalOpen(false)
    } catch (error) {
      console.error('Error pinning ad to board:', error)
    }
  }

  const handleSelectOrCreate = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = adsBoardData.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        const datas = {
          // eslint-disable-next-line
          ad_id: adsData?._id,
          board: response.data.data.id,
        }
        const trackingPayload = {
          eventName: 'Create Board Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Entered Board Name in Dropdown',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
          brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
          hasBoards: true,
        }
        accountsTracking(trackingPayload)
        try {
          await RequestService.post('/create/pinned-ads/', datas)
          hanldeDiscoverAdsBoard()
          message.success('Added to baord')
          const tracking = {
            eventName: 'Add to Board Success',
            pageName: currentUrl,
            userStatus: 'Free',
            sourceName: 'Clicked Board Name in Dropdown',
            currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
            brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
            hasBoards: true,
          }
          accountsTracking(tracking)
        } catch (error) {
          console.error('Error pinning ad to board:', error)
        }
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Added to baord')
        const tracking = {
          eventName: 'Add to Board Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Board Name in Dropdown',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
          brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
          hasBoards: true,
        }
        accountsTracking(tracking)
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }

  const filteredItems = adsBoardData.filter(board =>
    board?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = async () => {
    const tracking = {
      eventName: 'Copy Script Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Copy Icon',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(tracking)
    if (adsData?.description) {
      const textToCopy = adsData?.description
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setIsCopied(true)
          message.success('Text copied to clipboard!!')
          const tracking1 = {
            eventName: 'Copy Script Success',
            pageName: currentUrl,
            userStatus: 'Free',
            sourceName: 'Clicked Copy Icon',
            currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
            brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
          }
          accountsTracking(tracking1)
          setTimeout(() => setIsCopied(false), 2000)
        })
        .catch(() => {
          message.error('Failed to copy Link')
        })
    } else {
      setDescriptionLoader(true)
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })

        const textToCopy = response?.data?.description

        if (textToCopy) {
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              setIsCopied(true)
              message.success('Text copied to clipboard!!')
              const tracking1 = {
                eventName: 'Copy Script Success',
                pageName: currentUrl,
                userStatus: 'Free',
                sourceName: 'Clicked Copy Icon',
                currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
                brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
              }
              accountsTracking(tracking1)
              setTimeout(() => setIsCopied(false), 2000)
            })
            .catch(() => {
              message.error('Failed to copy text')
              setDescriptionLoader(false)
            })
        } else {
          message.error('No script available')
        }
        setDescriptionLoader(false)
      } catch (error) {
        setDescriptionLoader(false)
      }
    }
  }
  const handleUnPinFromBoard = async (boardId, adId) => {
    try {
      const data = {
        board_id: boardId,
        ad_id: adId,
        is_pinned: false,
      }
      await RequestService.post('/un-save/pinned-ads/', data)
      fetchSpecificBoardsList()
      message.success('Unpinned from board')
    } catch (error) {
      message.error('Error while unpinning ad')
    }
  }
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = flag => {
    setVisible(flag)
  }
  const handleBrandStatusForDropdown = async () => {
    if (!visible) {
      try {
        dispatch({ type: BRAND_STATUS_LOADER, payload: true })
        // setLoaderForBrandsStatus(true)
        const response = await RequestService.get(
          `/save/follow-brand-request/?brand_name=${
            adsData?.additional_info?.page_name || adsData?.advertiser
          }&page_id=${adsData?.additional_info.page_id}&advertiser_id=${adsData?.advertiser_id}`
        )
        dispatch({ type: BRAND_STATUS, payload: response?.data })
        // setResponseForBrandStatus(response?.data)
        dispatch({ type: BRAND_STATUS_LOADER, payload: false })
        // setLoaderForBrandsStatus(false)
      } catch (error) {
        dispatch({ type: BRAND_STATUS_LOADER, payload: false })
        // setLoaderForBrandsStatus(false)
      }
    }
  }

  const handleBrandStatus = async () => {
    try {
      dispatch({ type: BRAND_STATUS_LOADER, payload: true })
      // setLoaderForBrandsStatus(true)
      const response = await RequestService.get(
        `/save/follow-brand-request/?brand_name=${
          adsData?.additional_info?.page_name || adsData?.advertiser
        }&page_id=${adsData?.additional_info.page_id}&advertiser_id=${adsData?.advertiser_id}`
      )
      dispatch({ type: BRAND_STATUS, payload: response?.data })
      // setResponseForBrandStatus(response?.data)
      dispatch({ type: BRAND_STATUS_LOADER, payload: false })
      // setLoaderForBrandsStatus(false)
    } catch (error) {
      dispatch({ type: BRAND_STATUS_LOADER, payload: false })
      // setLoaderForBrandsStatus(false)
    }
  }
  const handleRemoveFromBoard = async (boardId, adId) => {
    try {
      await RequestService.delete(`/create/delete-ads/?ad_id=${adId}&board_id=${boardId}`)
      fetchSpecificBoardsList()
      message.success('Removed from board')
    } catch (error) {
      message.error('Error while removing ad')
    }
  }

  const hanldeFollowBrands = async () => {
    const trackingPayload = {
      eventName: 'Follow Brand Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked Follow Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    try {
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: true })
      // setFollowBrandsLoader(true)
      const data = {
        google_page_url: adsData?.origin?.provider === 'google' ? adsData?.ad_snapshot_url : '',
        meta_page_url:
          adsData?.origin?.provider === 'meta' ? adsData?.additional_info?.page_profile_uri : '',
        advertiser_id: adsData?.advertiser_id,
        page_id: adsData.additional_info.page_id,
        brand_name: adsData?.additional_info?.page_name || adsData?.advertiser,
        is_followed: true,
        brand: null,
      }
      const response = await RequestService.post('/save/follow-brands/', data)
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: true })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
      if (response.data.msg === 'Your request for following the brand is pending.') {
        message.success('Brand follow request sent successfully')
      } else {
        message.success('Follow successful')
      }
      // handleBrandStatus()
      setIsFollowed(true)
      const tracking = {
        eventName: 'Follow Brand Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Follow Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
    } catch (error) {
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
    }
  }

  const hanldeUnFollowBrands = async () => {
    const trackingPayload = {
      eventName: 'Unfollow Brand Click',
      pageName: currentUrl,
      userStatus: 'Free',
      sourceName: 'Clicked UnFollow Button',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
      brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
    }
    accountsTracking(trackingPayload)
    try {
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: true })
      // setFollowBrandsLoader(true)
      await RequestService.delete(
        `/save/follow-brands/?brand_id&advertiser_id=${adsData?.advertiser_id}&page_id=${
          adsData.additional_info.page_id
        }&brand_name=${adsData?.additional_info?.page_name || adsData?.advertiser}`
      )
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: true })
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
      message.success('Unfollow successful')
      if (followBrandsMainFlow) {
        history.push('/dashboard/brands')
      }
      // handleBrandStatus()
      setIsFollowed(false)
      const tracking = {
        eventName: 'Unfollow Brand Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked UnFollow Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      }
      accountsTracking(tracking)
    } catch (error) {
      dispatch({ type: FOLLOW_BRANDS_LOADER, payload: false })
      // setFollowBrandsLoader(false)
    }
  }

  const saveOptionMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      {/* Search Input */}
      <Menu.Item key="search" disabled>
        <Input
          placeholder="Search or create board"
          value={searchTerm}
          onChange={handleSearch}
          onPressEnter={() => {
            handleSelectOrCreate(searchTerm)
            setSearchTerm('') // Clear search term after submission
          }}
          onClick={event => {
            event.stopPropagation()
          }} // Prevent modal from opening
        />
      </Menu.Item>

      {/* Boards List */}
      {filteredItems.length > 0 ? (
        filteredItems.map(board => {
          // eslint-disable-next-line
          const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

          return (
            <Menu.Item
              key={board?.id}
              onClick={e => {
                // eslint-disable-next-line
                e.domEvent.stopPropagation()
                if (!isPinned) {
                  handleSelectOrCreate(board.name, board.id)
                }
              }}
              // disabled={isPinned}
              style={isPinned ? { cursor: 'not-allowed', opacity: 0.6 } : {}}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <span style={{ color: '#FF5E29' }}># </span>
                  {board.name}
                </div>
                {isPinned && <CheckOutlined style={{ color: '#f16434' }} />}
              </div>
            </Menu.Item>
          )
        })
      ) : (
        <Menu.Item key="no-results" disabled>
          No boards found
        </Menu.Item>
      )}
    </Menu>
  )

  const pinnedOptionsMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      {filteredItems.map((board, index) => {
        // eslint-disable-next-line
        const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

        return (
          <React.Fragment key={board?.id}>
            {isPinned ? (
              <Menu.Item key={board?.id} disabled>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div>
                    <span style={{ color: '#FF5E29' }}># </span>
                    {board.name}
                  </div>
                  <CheckOutlined style={{ color: '#f16434' }} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={board?.id} onClick={() => handlePinToBoard(board?.name, board?.id)}>
                <span style={{ color: '#FF5E29' }}># </span>
                {board.name}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      })}
    </Menu>
  )

  const moreOptionsMenu = (
    <Menu
      onClick={e => {
        e.domEvent.stopPropagation() // Prevents closing when clicking inside the dropdown
      }}
    >
      {
        // eslint-disable-next-line
        followBrandsLoader || loaderForBrandsStatus ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : // eslint-disable-next-line
        !responseForBrandStatus.is_status ? (
          <Menu.Item key="1" onClick={hanldeFollowBrands}>
            <div style={{ display: 'flex', gap: 8 }}>
              <img src={FollowIcon} alt="follow-icon" />
              Follow Brand
            </div>
            <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
              Follow specific brands to track their ads.
            </Text>
          </Menu.Item>
        ) : responseForBrandStatus.is_status ? (
          <>
            {
              // eslint-disable-next-line
              responseForBrandStatus.message === 'Brand exists in the FollowBrand table.' ? (
                <Menu.Item key="5" onClick={hanldeUnFollowBrands}>
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={FollowIcon} alt="follow-icon" />
                    UnFollow Brand
                  </div>
                  {responseForBrandStatus.count > 0 ? (
                    <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                      Unfollow to stop tracking this brand’s ads.
                    </Text>
                  ) : (
                    <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                      Data/Ads for this brand will be pulled out in 24 hours.
                    </Text>
                  )}
                </Menu.Item>
              ) : // eslint-disable-next-line
              responseForBrandStatus.message === 'Brand exists in the FollowRequest table.' &&
                responseForBrandStatus?.follow_request_status === 'pending' ? (
                <Menu.Item key="6">
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={FollowIcon} alt="follow-icon" />
                    Request pending
                  </div>
                  <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                    Follow request pending for approval.
                  </Text>
                </Menu.Item>
              ) : // eslint-disable-next-line
              responseForBrandStatus.message === 'Brand exists in the FollowRequest table.' &&
                responseForBrandStatus.follow_request_status === 'approved' ? (
                <Menu.Item key="1" onClick={hanldeFollowBrands}>
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={FollowIcon} alt="follow-icon" />
                    Follow Brand
                  </div>
                  <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                    Follow specific brands to track their ads.
                  </Text>
                </Menu.Item>
              ) : // eslint-disable-next-line
              responseForBrandStatus.message === 'Brand exists in the FollowRequest table.' &&
                responseForBrandStatus.follow_request_status === 'disapproved' ? (
                <Menu.Item key="5" style={{ opacity: 0.8 }}>
                  <div style={{ display: 'flex', gap: 8 }}>
                    <img src={FollowIcon} alt="follow-icon" />
                    Request Unsuccessful
                  </div>
                  <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                    Explore and track ads from other brands.
                  </Text>
                </Menu.Item>
              ) : (
                ''
              )
            }
          </>
        ) : (
          ''
        )
      }

      <Menu.Item key="2" onClick={handleCopyToClipboard}>
        <div style={{ display: 'flex', gap: 8 }}>
          <img src={CopyLinkIcon} alt="copy-icon" />
          {isCopied ? 'Link Copied' : 'Copy Link'}
        </div>
        <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
          {isCopied ? 'Link Copied to clipboard.' : ' Copy the link to share this.'}
        </Text>
      </Menu.Item>

      {isAdPinned && boardDetailRoute === 'true' ? (
        <Menu.Item
          key="3"
          // eslint-disable-next-line
          onClick={() => handleUnPinFromBoard(boardDetailData?.board_id, adsData?._id)}
        >
          <div style={{ display: 'flex', gap: 8 }}>
            <PushpinOutlined style={{ width: 24, height: 24, fontSize: 24 }} />
            Unpin From Board
          </div>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Unpin this ad from Board.
          </Text>
        </Menu.Item>
      ) : (
        <>
          {!isAdPinned && boardDetailRoute === 'true' ? (
            <Menu.Item
              key="3"
              onClick={() => {
                handlePinToBoardFromDetailPage(boardDetailData?.board_name, boardDetailData?.id)
                setVisible(false) // Close the dropdown after pinning
              }}
            >
              <div style={{ display: 'flex', gap: 8 }}>
                <img src={PinIcon} alt="pin-icon" />
                Pin to Board
              </div>
              <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                Pin this ad to a board to organize ads.
              </Text>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="3"
              onClick={() => {
                setIsPinModalOpen(true)
                setVisible(false) // Close the dropdown after opening the pin modal
              }}
            >
              <div style={{ display: 'flex', gap: 8 }}>
                <img src={PinIcon} alt="pin-icon" />
                Pin to Board
              </div>
              <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                Pin this ad to a board to organize ads.
              </Text>
            </Menu.Item>
          )}
        </>
      )}

      {boardDetailRoute === 'true' && (
        <Menu.Item
          key="4"
          // eslint-disable-next-line
          onClick={() => handleRemoveFromBoard(boardDetailData?.board_id, adsData?._id)}
        >
          <div style={{ display: 'flex', gap: 14 }}>
            <MinusOutlined />
            Remove from Board
          </div>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Remove this ad from board.
          </Text>
        </Menu.Item>
      )}
    </Menu>
  )

  const handleDownloadThumbnailForMeta = async () => {
    setThumbnailLoader(true)
    if (adsData?.description) {
      const adText = adsData?.description || 'No description available'
      const textBlob = new Blob([adText], { type: 'text/plain' })
      const textUrl = URL.createObjectURL(textBlob)
      const textLink = document.createElement('a')
      textLink.href = textUrl
      textLink.download = 'description.txt' // File name for the text file
      document.body.appendChild(textLink)
      textLink.click()
      document.body.removeChild(textLink)
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
        const adText = response?.data?.description
        const textBlob = new Blob([adText], { type: 'text/plain' })
        const textUrl = URL.createObjectURL(textBlob)
        const textLink = document.createElement('a')
        textLink.href = textUrl
        textLink.download = 'description.txt' // File name for the text file
        document.body.appendChild(textLink)
        textLink.click()
        document.body.removeChild(textLink)
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } catch (error) {
        setDescriptionLoader(false)
      }
    }

    if (adsData?.ads_grader_image_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData.ads_grader_image_url}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (!adsData?.ads_grader_image_url && adsData?.image_url) {
      const imageUrl = adsData.image_url
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else if (adsData?.ads_grader_video_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbnail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbnail downloaded successfully')
      const trackingPayload = {
        eventName: 'Download Success',
        pageName: currentUrl,
        userStatus: 'Free',
        sourceName: 'Clicked Download Button',
        currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
        fileFormat: '.png,.txt',
      }
      accountsTracking(trackingPayload)
    } else {
      message.info('No media URL available to download')
    }
    setThumbnailLoader(false)
  }
  const handleDownloadThumbnailForGoogle = async () => {
    setThumbnailLoader(true)
    if (adsData?.description) {
      const adText = adsData?.description || 'No description available'
      const textBlob = new Blob([adText], { type: 'text/plain' })
      const textUrl = URL.createObjectURL(textBlob)
      const textLink = document.createElement('a')
      textLink.href = textUrl
      textLink.download = 'description.txt' // File name for the text file
      document.body.appendChild(textLink)
      textLink.click()
      document.body.removeChild(textLink)
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
        const adText = response?.data?.description
        const textBlob = new Blob([adText], { type: 'text/plain' })
        const textUrl = URL.createObjectURL(textBlob)
        const textLink = document.createElement('a')
        textLink.href = textUrl
        textLink.download = 'description.txt' // File name for the text file
        document.body.appendChild(textLink)
        textLink.click()
        document.body.removeChild(textLink)
      } catch (error) {
        setThumbnailLoader(false)
      }
    }
    try {
      if (adsData?.image_url && !adsData?.video_url) {
        const imageUrl = adsData.image_url
        const response = await fetch(imageUrl, { mode: 'cors' })
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'thumbmail.png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Thumbmail downloaded successfully')
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } else if (adsData?.video_url) {
        const imageUrl = adsData?.image_url
        try {
          // Show message when the video download starts
          const response = await RequestService.get(
            `/ads-preview/download-ads/?video_url=${imageUrl}`,
            {
              responseType: 'blob',
            }
          )
          // Create a blob URL and trigger download
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'thumbnail.png') // Give it a filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          // Show success message when the download is complete
          message.success('Thumbnail downloaded successfully')
        } catch (error) {
          // Show error message if something goes wrong
          message.error('Failed to download Tthumbnail')
        }
        const trackingPayload = {
          eventName: 'Download Success',
          pageName: currentUrl,
          userStatus: 'Free',
          sourceName: 'Clicked Download Button',
          currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
          fileFormat: '.png,.txt',
        }
        accountsTracking(trackingPayload)
      } else {
        message.info('No media URL available to download')
      }
    } catch (error) {
      message.error(`Failed to download media: ${error.message}`)
    }
    setThumbnailLoader(false)
  }
  return (
    <>
      <Card
        className={styles.card}
        hoverable
        // eslint-disable-next-line
        onClick={() => showModal(adsData._id)}
      >
        {followBrandsMainFlow &&
          adsData?.updated_at &&
          (() => {
            const updatedAt = new Date(adsData.additional_info.ad_creation_time)
            const currentTime = new Date()
            const diffInHours = (currentTime - updatedAt) / (1000 * 60 * 60)

            return diffInHours < 48 ? <div className={styles.ribbon}>New</div> : null
          })()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F7F8F9',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 8,
              marginLeft: 16,
              height: 48,
              alignItems: 'center',
            }}
          >
            {
              // eslint-disable-next-line
              adsData?.origin?.provider === 'meta' ? (
                <div style={{ marginTop: -5 }}>
                  <img src={MetaIcon} alt="meta-icon" width="100%" height={16} />
                </div>
              ) : adsData?.origin?.provider === 'google' ? (
                <div style={{ marginTop: -5 }}>
                  <img src={GoogleIcon} alt="google-icon" width={16} />
                </div>
              ) : (
                ''
              )
            }
            <img src={verticalDivider} height={12} alt="divider" />
            {adsData?.additional_info?.ad_creation_time ? (
              <>
                <span className={styles.dateText}>
                  {dayjs(adsData?.additional_info?.ad_creation_time).format('MMM D, YYYY')}
                </span>
              </>
            ) : (
              ''
            )}
            <img src={verticalDivider} height={12} alt="divider" />
            {
              // eslint-disable-next-line
              adsData?.additional_info?.is_active === 'false' ||
              adsData?.additional_info?.is_active === false ? (
                ''
              ) : adsData?.additional_info?.is_active === 'true' ||
                adsData?.additional_info?.is_active === true ? (
                <img src={activeIcon} className={styles.profileIcons} alt="active-icon-pic" />
              ) : (
                ''
              )
            }
            <span className={styles.dateText}>
              {
                // eslint-disable-next-line
                adsData?.additional_info?.is_active === 'true' ||
                adsData?.additional_info?.is_active === true
                  ? 'Active'
                  : adsData?.additional_info?.is_active === 'false' ||
                    adsData?.additional_info?.is_active === false
                  ? 'Ended'
                  : ''
              }
            </span>
            {isAdPinned ? (
              <>
                <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                <PushpinOutlined
                  color="#f16434"
                  style={{ color: '#f16434', fontSize: '15px' }}
                />{' '}
              </>
            ) : (
              ''
            )}
          </div>
          {/* <div style={{ marginTop: -5, marginRight: 16 }}>
            <img src={InfoIcon} alt="google-icon" width={20} />
          </div> */}
        </div>

        <div className={styles.profileContainer}>
          {/* eslint-disable-next-line */}
          <div className={styles.profile} onClick={() => showModal(adsData._id)}>
            {localDate === adsDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <div>
                    <img
                      src={adsData?.additional_info?.page_profile_picture_url}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <div>
                    <img
                      src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            )}

            <div>
              <Text isBold size={16} style={{ maxWidth: 130 }}>
                {adsData?.additional_info?.page_name}
              </Text>
            </div>
          </div>

          {/* <div
            // eslint-disable-next-line
            id={`ads-card-more-options-${adsData._id}`}
          >
            <Dropdown
              onClick={event => {
                event.stopPropagation() // Prevents event propagation to the Card
                handleBrandStatusForDropdown()
              }}
              overlay={moreOptionsMenu}
              visible={visible}
              getPopupContainer={() =>
                // eslint-disable-next-line
                document.getElementById(`ads-card-more-options-${adsData._id}`)
              }
              trigger={['click']} // Ensures the dropdown is triggered on click
              onVisibleChange={handleVisibleChange}
            >
              <button
                type="submit"
                style={{ border: 'none', padding: 0, background: 'none', display: 'inline-flex' }}
              >
                <img
                  src={MoreOptionsIcon}
                  width={32}
                  alt="more-options"
                  style={{ cursor: 'pointer' }} // Ensures the cursor is a pointer
                />
              </button>
            </Dropdown>
          </div> */}
          {adsData?.is_followed ? (
            <button
              className={styles.followButton}
              type="button"
              onClick={event => {
                event.stopPropagation() // Prevents event propagation to the Card
                hanldeUnFollowBrands()
              }}
            >
              <Text className={styles.addToBoard}>Unfollow</Text>
            </button>
          ) : (
            // <Button
            //   onClick={event => {
            //     event.stopPropagation() // Prevents event propagation to the Card
            //     hanldeUnFollowBrands()
            //   }}
            // >
            //   UNFOLLOW
            // </Button>
            // <Button
            //   onClick={event => {
            //     event.stopPropagation() // Prevents event propagation to the Card
            //     hanldeFollowBrands()
            //   }}
            // >
            //   FOllow
            // </Button>
            <button
              className={styles.followButton}
              type="button"
              onClick={event => {
                event.stopPropagation() // Prevents event propagation to the Card
                hanldeFollowBrands()
              }}
            >
              <Text className={styles.addToBoard}>Follow</Text>
            </button>
          )}
        </div>
        {((Array.isArray(adsData?.title) && adsData.title[0]?.length > 1) ||
          (typeof adsData?.title === 'string' && adsData.title.length > 1)) && (
          <Text
            style={{
              marginTop: 16,
              color: '#67696B',
              fontSize: 12,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2,
            }}
          >
            {Array.isArray(adsData?.title) ? adsData.title[0] : adsData.title}
          </Text>
        )}

        {localDate === adsDate ? (
          <>
            {
              // eslint-disable-next-line
              savedAdsFlow === 'true' ? (
                <>
                  {adsData?.origin?.provider === 'meta' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <video
                              style={{ width: '100%', height: '300px' }}
                              src={adsData?.video_url}
                              onPlay={e => e.preventDefault()} // Prevents playing
                            >
                              <track
                                kind="captions"
                                srcLang="en"
                                src="captions_en.vtt"
                                label="English captions"
                              />
                              Your browser does not support the video tag.
                            </video>

                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                  {adsData?.origin?.provider === 'google' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url && !adsData?.video_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.image_url && adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={adsData.image_url ?? noPreviewImage}
                              className={styles.cardImage}
                              alt="card-img"
                            />
                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' || adsData?.origin?.provider === 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <video
                          style={{ width: '100%', height: '300px' }}
                          src={adsData?.video_url}
                          onPlay={e => e.preventDefault()} // Prevents playing
                        >
                          <track
                            kind="captions"
                            srcLang="en"
                            src="captions_en.vtt"
                            label="English captions"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' || adsData?.origin?.provider !== 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        ) : (
          <>
            {
              // eslint-disable-next-line
              savedAdsFlow === 'true' ? (
                <>
                  {adsData?.origin?.provider === 'meta' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.ads_grader_image_url ? (
                          <img
                            src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.ads_grader_video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`}
                              className={styles.cardImage}
                              alt="card-img"
                            />

                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                  {adsData?.origin?.provider === 'google' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url && !adsData?.video_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.image_url && adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={adsData.image_url ?? noPreviewImage}
                              className={styles.cardImage}
                              alt="card-img"
                            />
                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' || adsData?.origin?.provider === 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.ads_grader_image_url ? (
                      <img
                        src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.ads_grader_video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' || adsData?.origin?.provider !== 'meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        )}

        {/* <div className={styles.buttonContainer} id="area"> */}
        <Row style={{ marginTop: 20 }} id="area">
          <Col
            xs={24} // Full width for small screens
            lg={12} // Half width for large screens (900px - 1400px)
            xxl={12}
          >
            <Dropdown
              onClick={event => {
                event.stopPropagation() // Prevents event propagation to the Card
                hanldeDiscoverAdsBoard()
                const trackingPayload = {
                  eventName: 'Add to Board Click',
                  pageName: currentUrl,
                  userStatus: 'Free',
                  sourceName: 'Clicked Add to Board Button',
                  currentTab:
                    currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
                  brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
                  hasBoards: true,
                }
                accountsTracking(trackingPayload)
              }}
              overlay={saveOptionMenu}
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData._id}`)}
              trigger={['click']}
              overlayStyle={{
                width: 'max-content',
                // maxWidth: '240px',
                zIndex: 1,
              }}
            >
              <button className={styles.saveButton} type="button">
                <img src={AddIcon} alt="add-icon" className={styles.copyIcon} />
                <img src={AddIconOrange} alt="add-icon" className={styles.copyIconOrange} />
                <Text className={styles.addToBoard}>Add to board</Text>
              </button>
            </Dropdown>
          </Col>

          <Col
            xs={24}
            lg={3}
            xxl={3}
            // eslint-disable-next-line
            id={`adsCardTooltip-${adsData._id}`}
          >
            <Tooltip
              title="View ad"
              color="rgb(32, 32, 32)"
              // eslint-disable-next-line
              getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData._id}`)}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  const trackingPayload = {
                    eventName: 'URL External Redirection Click',
                    pageName: currentUrl,
                    userStatus: 'Free',
                    sourceName: 'Clicked Ad Link Button',
                    adsSourceClick: 'View Ad button on Discover Ads Page',
                  }
                  accountsTracking(trackingPayload)
                  window.open(adsData?.ad_snapshot_url, '_blank')
                }}
              >
                {/* <img src={ShareIcon} alt="share-icon" className={styles.footerIcon} /> */}
                <img src={ShareIcon} alt="share-icon" className={styles.copyIcon} />
                <img src={ShareIconOrange} alt="share-icon" className={styles.copyIconOrange} />
              </button>
            </Tooltip>
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            {descriptionLoader ? (
              <div style={{ textAlign: 'center', width: '40px' }}>
                <Spin />
              </div>
            ) : (
              <Tooltip
                title="Copy script"
                color="rgb(32, 32, 32)"
                // eslint-disable-next-line
                getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData._id}`)}
                placement="top"
              >
                {/* eslint-disable-next-line */}
                <button
                  className={styles.addButton}
                  type="button"
                  onClick={e => {
                    e.stopPropagation()
                    handleCopyToClipboard()
                  }}
                  id="first"
                >
                  {/* <img src={CopyTextIcon} alt="share-icon" className={styles.footerIcon} /> */}
                  <img src={CopyTextIcon} alt="share-icon" className={styles.copyIcon} />
                  <img
                    src={CopyTextIconOrange}
                    alt="share-icon"
                    className={styles.copyIconOrange}
                  />
                </button>
              </Tooltip>
            )}
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            {ThumbnailLodaer ? (
              <div style={{ textAlign: 'center', width: '40px' }}>
                <Spin />
              </div>
            ) : (
              <Tooltip
                title="Download Thumbnail"
                color="rgb(32, 32, 32)"
                // eslint-disable-next-line
                getPopupContainer={() => document.getElementById(`adsCardTooltip-${adsData._id}`)}
                placement="top"
              >
                <button
                  className={styles.addButton}
                  type="button"
                  onClick={e => {
                    e.stopPropagation()
                    const trackingPayload = {
                      eventName: 'Download Click',
                      pageName: currentUrl,
                      userStatus: 'Free',
                      sourceName: 'Clicked Download Button',
                      currentTab:
                        currentUrl === '/dashboard/creative-library' ? selectedChannel : '',
                      fileFormat: '.png,.txt',
                    }
                    accountsTracking(trackingPayload)
                    // eslint-disable-next-line
                    adsData?.origin?.provider === 'meta'
                      ? handleDownloadThumbnailForMeta()
                      : handleDownloadThumbnailForGoogle()
                  }}
                  id="first"
                >
                  {/* <img src={DownloadIcon} alt="share-icon" className={styles.footerIcon} /> */}
                  <img src={DownloadIcon} alt="download-icon" className={styles.copyIcon} />
                  <img
                    src={DownloadIconOrange}
                    alt="download-icon"
                    className={styles.copyIconOrange}
                  />
                </button>
              </Tooltip>
            )}
          </Col>
          <Col xs={24} lg={3} xxl={3}>
            {savedAdsFlowRoute === 'true' ? (
              <>
                {isUnSaveLoading ? (
                  <div style={{ textAlign: 'center', width: '40px' }}>
                    <Spin />
                  </div>
                ) : (
                  <button
                    type="button"
                    className={styles.addButton} // Change style based on save status
                    onClick={e => {
                      // eslint-disable-next-line
                      e.stopPropagation()
                      handleUnsaveAds()
                    }}
                  >
                    <Tooltip
                      title="Un Save Ad"
                      color="rgb(32, 32, 32)"
                      // eslint-disable-next-line
                      getPopupContainer={() =>
                        // eslint-disable-next-line
                        document.getElementById(`adsCardTooltip-${adsData._id}`)
                      }
                      placement="top"
                    >
                      <img src={SavedAdIcon} alt="saved-icon" className={styles.copyIcon} />
                      <img
                        src={SavedAdIconOrange}
                        alt="saved-icon"
                        className={styles.copyIconOrange}
                      />
                    </Tooltip>
                  </button>
                )}
              </>
            ) : (
              <>
                {' '}
                {isSaveLoading && !isSavedInSavedAds && !isSaved ? (
                  <div style={{ textAlign: 'center', width: '40px' }}>
                    <Spin />
                  </div>
                ) : (
                  <button
                    type="button"
                    className={styles.addButton}
                    onClick={e => {
                      e.stopPropagation()
                      handleSaveAds()
                    }}
                  >
                    {isSaved || isSavedInSavedAds ? (
                      <div>
                        <Tooltip
                          title="Ad Saved"
                          color="rgb(32, 32, 32)"
                          // eslint-disable-next-line
                          getPopupContainer={() =>
                            // eslint-disable-next-line
                            document.getElementById(`adsCardTooltip-${adsData._id}`)
                          }
                          placement="top"
                        >
                          {/* <img src={SavedAdIcon} alt="share-icon" className={styles.footerIcon} /> */}
                          <img src={SavedAdIcon} alt="share-icon" className={styles.copyIcon} />
                          <img
                            src={SavedAdIconOrange}
                            alt="share-icon"
                            className={styles.copyIconOrange}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip
                        title="Save Ad"
                        color="rgb(32, 32, 32)"
                        // eslint-disable-next-line
                        getPopupContainer={() =>
                          // eslint-disable-next-line
                          document.getElementById(`adsCardTooltip-${adsData._id}`)
                        }
                        placement="top"
                      >
                        <img src={SaveAdIcon} alt="share-icon" className={styles.copyIcon} />
                        <img
                          src={SaveAdIconOrange}
                          alt="share-icon"
                          className={styles.copyIconOrange}
                        />
                      </Tooltip>
                    )}
                  </button>
                )}
              </>
            )}
          </Col>
          {/* </div> */}
        </Row>
        {/* </div> */}
      </Card>

      {isModalOpen && selectedData && (
        <AdsModal
          adsData={selectedData}
          modalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onPrevious={onPrevious}
          onNext={onNext}
          isFirst={isFirst}
          isLast={isLast}
          boardsList={adsBoardData}
        />
      )}

      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Pin ad to Board</span>
            <CloseOutlined onClick={() => setIsPinModalOpen(false)} style={{ cursor: 'pointer' }} />
          </div>
        }
        visible={isPinModalOpen}
        onCancel={() => setIsPinModalOpen(false)}
        footer={null}
      >
        <Dropdown
          overlay={pinnedOptionsMenu}
          // getPopupContainer={() => document.getElementById('area')}
          trigger={['click']}
          overlayStyle={{ width: 'max-content', maxWidth: '240px' }}
        >
          <Input
            placeholder="Search or create board"
            value={searchTerm}
            onChange={handleSearch}
            onClick={() => setIsDropdownVisible(prev => !prev)}
            onPressEnter={() => {
              handleSelectOrCreate(searchTerm)
              setSearchTerm('')
              setIsDropdownVisible(false)
            }}
          />
        </Dropdown>
      </Modal>
    </>
  )
}

export default AdsCard
