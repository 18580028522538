import { getPageNameFromRoute } from '@utils/routeMapper'
import { RequestService } from './requests'

const accountsTracking = async data => {
  const page = getPageNameFromRoute(data.pageName)
  const values = {
    page_name: page,
    event_name: data.eventName,
    source: data.sourceName,
    sign_in_method: data.signInMethod,
    user_status: data.userStatus,
    app_section: data.lastPageUrl,
    tab_selected: data.tabSelected,
    current_tab: data.currentTab,
    contain_results: data.containResults,
    brand_name: data.brandName,
    has_boards: data.hasBoards,
    ad_results_count_board: data.adResultsCountBoard,
    shared_email_account: data.email,
    shared_email_role: data.role,
    file_format: data.fileFormat,
    button_location: data?.buttonLocation,
    data: data.formData,
    ads_source_click: data.adsSourceClick,
    ad_results_count_filter: data.count,
    selected_location: data.selectedLocation,
    selected_language: data.selectedLanguage,
    selected_format: data.selectedFormat,
    ad_filter: data.adFilter,
    search_input: data.searchInput,
    search_input_term: data.searchInputTerm,
    ad_results_count: data.adResultsCount,
    add_section: data.appSection,
    section_name: data.sectionName,
    duration: data.timeSpent,
    email: data.authEmail,
    board_name: data.boardName,
    brand_selected: data.brandSelected,
    tag_name: data.tagName,
  }
  try {
    await RequestService.post('/accounts/tracking/', values)
  } catch (e) {
    console.error('Error fetching Accounts Tracking data:', e)
  }
}
export default accountsTracking
