import React, { useState } from 'react'
import { Form, Input, Button, Spin } from 'antd'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Text } from '@components'
import { axiosInstance } from '@services/requests'
import Modal from '@components/Modal'
import styles from './ResetPassword.module.css'

const ResetPassword = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  })

  const handleCancel = () => {
    setShowModal(false)
  }
  const handleBackToLogin = () => {
    history.push('/login')
  }

  return (
    <>
      <div className={styles.passwordResetWrapper}>
        <div className={styles.loginFormCard}>
          <Formik
            initialValues={{
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async values => {
              const userEmail = localStorage.getItem('forget-user-email')
              const data = {
                email: userEmail,
                new_password: values.newPassword,
                confirm_password: values.confirmPassword,
              }
              setIsLoading(true)
              try {
                const response = await axiosInstance.patch('accounts/renew-password/', data)
                if (response.status === 200) {
                  setIsLoading(false)
                  setShowModal(true)
                }
              } catch (e) {
                setIsLoading(false)
              }
            }}
          >
            {({ handleSubmit, errors, touched }) => (
              <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                  label={
                    <Text className={styles.inputHeader}>
                      New Password <span className={styles.requiredAsterisk}>*</span>
                    </Text>
                  }
                >
                  <Field
                    placeholder="Enter your password"
                    name="newPassword"
                    as={Input.Password}
                    className={errors.newPassword && touched.newPassword ? styles.errorInput : ''}
                  />
                  <ErrorMessage name="newPassword">
                    {msg => <div className={styles.errorMessage}>{msg}</div>}
                  </ErrorMessage>
                </Form.Item>

                <Form.Item
                  label={
                    <Text className={styles.inputHeader}>
                      Confirm New Password <span className={styles.requiredAsterisk}>*</span>
                    </Text>
                  }
                >
                  <Field
                    placeholder="Re-enter your password"
                    name="confirmPassword"
                    as={Input.Password}
                    className={
                      errors.confirmPassword && touched.confirmPassword ? styles.errorInput : ''
                    }
                  />
                  <ErrorMessage name="confirmPassword">
                    {msg => <div className={styles.errorMessage}>{msg}</div>}
                  </ErrorMessage>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                    className={styles.searchButton}
                  >
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Formik>
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Text size={14}>
              Back to
              <span
                role="button"
                tabIndex="0"
                className={styles.registerHere}
                onClick={handleBackToLogin}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    // handleNavigation('register')
                  }
                }}
              >
                &nbsp;Login
              </span>
            </Text>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      <Modal
        open={showModal}
        heading="Password Reset Successful"
        description=""
        onCancel={handleCancel}
      >
        <Button size="small" type="primary" className={styles.submitButton}>
          <Link to="/login">Back to Login</Link>
        </Button>
      </Modal>
    </>
  )
}

export default ResetPassword
