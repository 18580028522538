import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { PUBLISHED_VERSION } from '@config/enviroment'
import routes from '@config/routes'
import PrivateRoute from '@components/PrivateRoute'
import tokenService from '@services/tokenService'
import UnderDevelopment from '@pages/underDevelopmentPage'
import UnderDevelopmentNavbar from '@components/UnderDevelopmentNavbar'
import MobileView from '@components/MobileView'
import Navbar from './components/Navbar'
import { useUtmParamsStore } from './hooks/use-utm-params-store'
import store from './store'
import './styles/global.scss'

const App = () => {
  const [isMobile, setIsMobile] = useState(false)
  // const { t } = useTranslation()
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 1024) // Adjust the threshold as needed
    }
    checkIsMobile() // Check initially
    // Attach event listener for resizing
    window.addEventListener('resize', checkIsMobile)
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, []) // Empty dependency array to run the effect only once
  const rollbarConfig = {
    accessToken: '9af3564ae57245558c5fdebf55540cae',
    environment: 'testenv',
  }

  return (
    // <Provider config={rollbarConfig}>
    //   <ErrorBoundary>
    <>
      {isMobile ? (
        <Suspense fallback="">
          {' '}
          <Router>
            {/* <Redirect to="/" /> */}
            <MobileView />
          </Router>
        </Suspense>
      ) : (
        ''
      )}
      <Suspense fallback="">
        <Provider store={store}>
          <Router>
            <ToastContainer />
            <Navbar />
            <Switch>
              {routes.map((route, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Router>
        </Provider>
      </Suspense>
    </>
    //   </ErrorBoundary>
    // </Provider>
  )
}

function RouteWithSubRoutes(route) {
  const { search } = useLocation()
  useUtmParamsStore(search)
  const accessToken = tokenService.getLocalAccessToken()
  const { path } = route

  // Check if user is authenticated but hasn't completed lead form
  const user = localStorage.getItem('np_ads_grader:session')
  const userObject = JSON.parse(user)
  const isLeadFormCompleted = userObject?.user?.is_lead_save

  // If user is authenticated but hasn't completed lead form and tries to access login, redirect to lead-form
  if (
    accessToken &&
    !isLeadFormCompleted &&
    (path === '/login' || path === '/start' || path === '/reset-password')
  ) {
    return <Redirect to="/lead-form" />
  }

  if (route?.privateRoute) {
    return (
      <PrivateRoute
        route={route}
        isAuthenticated={!!accessToken}
        component={
          <Route
            path={path}
            //  eslint-disable-next-line
            render={props => <route.component {...props} routes={route.routes} />}
          />
        }
      />
    )
  }
  return (
    <>
      {/* eslint-disable-next-line */}
      <Route path={path} render={props => <route.component {...props} routes={route.routes} />} />
    </>
  )
}

export default App
