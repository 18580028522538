import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useFormik } from 'formik'
import { Button, Spin } from 'antd'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { generatePassswordForGoogleUser } from '@services/generatePasswordForGoogleuser'
import Modal from '@components/Modal'
import { updateLocalStorage } from '@utils/helperFunctions'
import { updateUserData } from '../../store/actions'
import styles from './generatePassword.module.css'

const GeneratePassword = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const trackingPageName = useSelector(state => state?.AccountsTrackingReducer?.trackingPageName)
  useEffect(() => {
    if (code) {
      const redirectUri = `${window.location.origin}/auth/google/callback`
      fetchGoogleUserInfo(code, redirectUri, trackingPageName).then(res => {
        if (res.status === 200) {
          setUser(res.data)
        } else {
          setError(true)
        }
      })
    }
    // eslint-disable-next-line
  }, [code])
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required().label('Password'),
      confirm_password: Yup.string().required().label('Confirm Password'),
    }),
    onSubmit: async values => {
      setIsLoading(true)
      await generatePassswordForGoogleUser(values, user?.access_token).then(res => {
        setIsLoading(false)
        if (res.status === 200) {
          updateLocalStorage(user)
          dispatch(updateUserData(user))
          setShowModal(true)
        } else {
          setError(true)
        }
      })
    },
  })

  const { getFieldProps } = formik
  const handleCancel = () => {
    setShowModal(false)
    history.push('/')
  }

  return (
    <div className={styles.passwordResetWrapper}>
      <h2 className={styles.bold}>Ads Grader</h2>
      <p className={styles.bold}>
        by <span className={styles.NP}>NP</span> digital
      </p>
      <div className={styles.loginFormCard}>
        <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>Email</p>
            <input
              className={styles.input}
              type="text"
              disabled
              placeholder="email"
              value={user?.user?.email}
            />
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>New Password</p>
            <input
              className={styles.input}
              type="password"
              placeholder="Enter password"
              {...getFieldProps('password')}
            />
            {formik.touched.password && Boolean(formik.errors.password) && (
              <p>{formik.errors.password}</p>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>Confirm New Password</p>
            <input
              className={styles.input}
              type="password"
              placeholder="Enter confirm password"
              {...getFieldProps('confirm_password')}
            />
            {formik.touched.confirm_password && Boolean(formik.errors.confirm_password) && (
              <p>{formik.errors.confirm_password}</p>
            )}
          </div>

          <button className={styles.submitButton} disabled={isLoading} type="submit">
            {isLoading ? <Spin style={{ color: 'blue' }} /> : 'Generate Password'}
          </button>
          {error && <p>Something went wrong!</p>}
        </form>
      </div>
      <Modal
        open={showModal}
        heading="Password Generated Successfulyl"
        description=""
        onCancel={handleCancel}
      />
    </div>
  )
}

export default GeneratePassword
