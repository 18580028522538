import { Text } from '@components'
import AdsCard from '@components/DiscoverAds/AdsCard'
import SearchBar from '@components/DiscoverAds/SearchBar'
import AdsCounter from '@components/DiscoverAds/AdsCounter'
import { RequestService, axiosInstance } from '@services/requests'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import NoData from '@components/GoogleAdsPreview/ErrorStates/NoData'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  TRIGGER_UPDATE_FOLLOW_BRANDS,
  DISABLE_SPLIT_VIEW,
  ADS_DATA,
  ADS_LOADER,
  LIVE_SEARCH,
  TEMP_COUNT,
  HAS_MORE,
  ADS_PAGINATION,
  CLEANED_DATA_COUNT,
  ADS_CHANNEL,
  CLEAR_BRANDS_FLITER,
  MORE_DATA_LOADER,
  API_DATA_BOARDS_LIST,
  ADS_FORMAT,
  ADS_HOME_STATE,
  ADS_LOCATION,
  EMPTY_ADS_PREVIEW_DATA,
  ADS_SEARCH_KEYWORD,
  ADS_NO_DATA,
  MIX_GOOGLE_PAGINATION,
  MIX_META_PAGINATION,
  CHECKED_VALUES,
  BRANDS_TAGS_DATA,
  BRANDS_TAGS_FOR_GOOGLE,
  BRANDS_TAGS_FOR_META,
  BRAND_CHOSEN,
  INPUT_VALUES,
  PAGE_ID_AND_ADVERTISER_ID,
  TRIGGER_SEARCH,
  BRAND_TAG_FOR_ALL,
  BRAND_TAG_FOR_GOOGLE,
  BRAND_TAG_FOR_META,
  CLEAR_BRANDS_TAG_FLAG,
  FOLLOWED_BRANDS_LIST,
  ADS_DATA_FOR_GOOGLE,
  ADS_DATA_FOR_META,
  HAS_MORE_FOR_GOOGLE_ALL_CHANNEL,
  HAS_MORE_FOR_META_ALL_CHANNEL,
  ADS_PAGINATION_FOR_GOOGLE_ALL_CHANNEL,
  ADS_PAGINATION_FOR_META_ALL_CHANNEL,
  TOGGLE_SPLIT_VIEW,
} from '@utils/constants'
import { notify } from '@utils/notify'
import CategoryButton from '@components/DiscoverAds/CategoryButton'
import SearchResultsError from '@components/GoogleAdsPreview/ErrorStates/SearchResultsError'
import accountsTracking from '@services/accountsTracking'
import { Card, Checkbox, Col, message, Row, Skeleton, Spin } from 'antd'
import styled from 'styled-components'
import metaStyles from './discoverAdsFeature.module.css'

const DiscoverAdsFeature = () => {
  const dispatch = useDispatch()
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const trackingLocation = useSelector(state => state?.AccountsTrackingReducer?.trackingLocation)
  const metaOptionPermanent = useSelector(state => state?.discoverAds?.metaBrandsList)
  const googleOptionPermanent = useSelector(state => state?.discoverAds?.googleBrandsList)
  const allOptionPermanent = useSelector(state => state?.discoverAds?.allBrandsList)
  const triggerUpdateMe = useSelector(state => state?.discoverAds?.triggerUpdateMe)
  const clearBrandsTagFlag = useSelector(state => state?.discoverAds?.clearBrandsTagFlag)
  const brandsTagForAll = useSelector(state => state?.discoverAds?.brandTagForAll)
  const brandsTagForGoogle = useSelector(state => state?.discoverAds?.brandTagForGoogle)
  const brandsTagForMeta = useSelector(state => state?.discoverAds?.brandTagForMeta)
  const brandsTagForAllOrignal = useSelector(state => state?.discoverAds?.homePageBrands)
  const brandsTagForGoogleOriginal = useSelector(state => state?.discoverAds?.brandsTagForGoogle)
  const brandsTagForMetaOriginal = useSelector(state => state?.discoverAds?.brandsTagForMeta)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const checkedValues = useSelector(state => state?.discoverAds?.checkedValues)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const isMetaStatus = useSelector(state => state?.discoverAds?.isMetaStatus)
  const isGoogleStatus = useSelector(state => state?.discoverAds?.isGoogleStatus)
  const adsPreviewData = useSelector(state => state?.discoverAds?.adsPreviewData)
  const adsPreviewDataForGoogle = useSelector(state => state?.discoverAds?.adsPreviewDataForGoogle)
  const adsPreviewDataForMeta = useSelector(state => state?.discoverAds?.adsPreviewDataForMeta)
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const adsPagination = useSelector(state => state?.discoverAds?.adsPagination)
  const adsPaginationForGoogle = useSelector(state => state?.discoverAds?.adsPaginationForGoogle)
  const adsPaginationForMeta = useSelector(state => state?.discoverAds?.adsPaginationForMeta)
  const hasMore = useSelector(state => state?.discoverAds?.hasMore)
  const hasMoreForGoogle = useSelector(state => state?.discoverAds?.hasMoreForGoogle)
  const hasMoreForMeta = useSelector(state => state?.discoverAds?.hasMoreForMeta)
  const adsNoDataForGoogle = useSelector(state => state?.discoverAds?.adsNoDataForGoogle)
  const adsNoDataForMeta = useSelector(state => state?.discoverAds?.adsNoDataForMeta)
  const brandsFilter = useSelector(state => state?.discoverAds?.brandsFilter)
  const activeCountRedux = useSelector(state => state?.discoverAds?.activeAdsCount)
  const InActiveCountRedux = useSelector(state => state?.discoverAds?.inActiveAdsCount)
  const isLiveSearch = useSelector(state => state?.discoverAds?.isLiveSearch)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const followedBrandsList = useSelector(state => state?.discoverAds?.followedBrandsList)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)
  const [filteredDiscoverData, setFilteredDiscoverData] = useState([])
  const [filteredDiscoverDataAllForGoogle, setFilteredDiscoverDataAllForGoogle] = useState(
    adsPreviewDataForGoogle
  )
  const [filteredDiscoverDataAllForMeta, setFilteredDiscoverDataAllForMeta] = useState(
    adsPreviewDataForMeta
  )
  const [newDataSpin, setNewDataSpin] = useState(false)
  const [activeCount, setActiveCount] = useState()
  const [inactiveCount, setInActiveCount] = useState()
  const [showButton, setShowButton] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  const containerRef = useRef(null)
  const dataRef = useRef()
  const dataRefAllForGoogle = useRef()
  const dataRefAllForMeta = useRef()
  const splitView = useSelector(state => state?.discoverAds?.splitView)
  useEffect(() => {
    if (brandsTagForAllOrignal) {
      dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
      dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
      dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    }
    // eslint-disable-next-line
  }, [brandsTagForAllOrignal, brandsTagForGoogleOriginal, brandsTagForMetaOriginal])

  const getFollowedBrandsList = async () => {
    try {
      const response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      // console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
  }
  const triggerUpdateFollowBrands = async () => {
    let response = []
    try {
      response = await RequestService.get('save/followed-brands-status/')
      dispatch({ type: FOLLOWED_BRANDS_LIST, payload: response?.data?.data })
    } catch (error) {
      // console.log('🚀 ~ getFollowedBrandsList ~ error:', error)
    }
    if (selectedChannel === 'All') {
      if (Array.isArray(dataRefAllForGoogle.current)) {
        dataRefAllForGoogle.current.forEach(ad => {
          const isFollowed = response?.data?.data.some(
            brand =>
              brand.google_advertiser_id === ad?.advertiser_id ||
              brand.meta_page_id === ad?.additional_info?.page_id
          )
          // eslint-disable-next-line
          ad.is_followed = isFollowed
        })
        setFilteredDiscoverDataAllForGoogle([...dataRefAllForGoogle.current])
      }
      if (Array.isArray(dataRefAllForMeta.current)) {
        dataRefAllForMeta.current.forEach(ad => {
          const isFollowed = response?.data?.data.some(
            brand =>
              brand.google_advertiser_id === ad?.advertiser_id ||
              brand.meta_page_id === ad?.additional_info?.page_id
          )
          // eslint-disable-next-line
          ad.is_followed = isFollowed
        })
        setFilteredDiscoverDataAllForMeta([...dataRefAllForMeta.current])
      }
    } else {
      // eslint-disable-next-line
      if (Array.isArray(dataRef.current)) {
        dataRef.current.forEach(ad => {
          const isFollowed = response?.data?.data.some(
            brand =>
              brand.google_advertiser_id === ad?.advertiser_id ||
              brand.meta_page_id === ad?.additional_info?.page_id
          )
          // eslint-disable-next-line
          ad.is_followed = isFollowed
        })
        setFilteredDiscoverData([...dataRef.current])
      }
    }
  }

  useEffect(() => {
    triggerUpdateFollowBrands()
    // eslint-disable-next-line
  }, [triggerUpdateMe])

  useEffect(() => {
    getFollowedBrandsList()
    // eslint-disable-next-line
  }, [])

  // useeffect for unique Tags of brands

  useEffect(() => {
    if (searchKeyword && !loading) {
      let uniqueTags = []
      if (selectedChannel === 'All') {
        const combinedAdsPreviewData = [
          ...filteredDiscoverDataAllForGoogle,
          ...filteredDiscoverDataAllForMeta,
        ]
        uniqueTags = combinedAdsPreviewData.reduce((acc, current) => {
          const duplicate = acc.find(
            item => item?.additional_info?.page_name === current?.additional_info?.page_name
          )
          if (!duplicate) {
            acc.push(current)
          }
          return acc
        }, [])
      } else {
        uniqueTags = filteredDiscoverData.reduce((acc, current) => {
          const duplicate = acc.find(
            item => item?.additional_info?.page_name === current?.additional_info?.page_name
          )
          if (!duplicate) {
            acc.push(current)
          }
          return acc
        }, [])
      }

      const updatedBrandTags = uniqueTags.map(ad => ({
        brand_name: ad?.additional_info?.page_name, // Use the page_name as brand_name
        page_profile_logo: ad?.additional_info?.ads_grader_page_profile_picture_url,
      }))
      dispatch({ type: BRAND_TAG_FOR_ALL, payload: updatedBrandTags })
      dispatch({ type: BRAND_TAG_FOR_META, payload: updatedBrandTags })
      dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: updatedBrandTags })
    }

    // eslint-disable-next-line
  }, [
    searchKeyword,
    filteredDiscoverData,
    filteredDiscoverDataAllForGoogle,
    filteredDiscoverDataAllForMeta,
  ])

  useEffect(() => {
    if (clearBrandsTagFlag) {
      if (selectedChannel === 'All') {
        dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForAll })
        dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForAll })
      } else if (selectedChannel === 'Meta') {
        dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForMeta })
        dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForMeta })
      } else if (selectedChannel === 'Google') {
        dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForGoogle })
        dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForGoogle })
      }
    }
    // eslint-disable-next-line
  }, [clearBrandsTagFlag])

  useEffect(() => {
    if (adsPreviewData.length > 0) {
      dataRef.current = adsPreviewData
      setFilteredDiscoverData(adsPreviewData)
    }
    // eslint-disable-next-line
  }, [adsPreviewData])
  useEffect(() => {
    if (selectedChannel === 'All') {
      dataRefAllForGoogle.current = adsPreviewDataForGoogle
      setFilteredDiscoverDataAllForGoogle(adsPreviewDataForGoogle)
    }
    // eslint-disable-next-line
  }, [adsPreviewDataForGoogle])
  useEffect(() => {
    if (selectedChannel === 'All') {
      dataRefAllForMeta.current = adsPreviewDataForMeta
      setFilteredDiscoverDataAllForMeta(adsPreviewDataForMeta)
    }
    // eslint-disable-next-line
  }, [adsPreviewDataForMeta])

  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log('🚀 ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }

  useEffect(() => {
    hanldeDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [selectedChannel])

  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    let combinedData = []
    if (splitView) {
      combinedData = [...filteredDiscoverDataAllForGoogle, ...filteredDiscoverDataAllForMeta]
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : combinedData.length - 1))
    } else {
      combinedData = filteredDiscoverData
      setSelectedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : filteredDiscoverData.length - 1
      )
    }
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      eventName: 'Ad Details Window View',
      brandName: combinedData[selectedIndex]?.additional_info?.page_name,
      sourceName: 'Clicked Previous Icon',
    }
    accountsTracking(trackingPayload)
  }

  const handleNext = () => {
    let combinedData = []
    if (splitView) {
      combinedData = [...filteredDiscoverDataAllForGoogle, ...filteredDiscoverDataAllForMeta]
      setSelectedIndex(prevIndex => (prevIndex < combinedData.length - 1 ? prevIndex + 1 : 0))
    } else {
      combinedData = filteredDiscoverData
      setSelectedIndex(prevIndex =>
        prevIndex < filteredDiscoverData.length - 1 ? prevIndex + 1 : 0
      )
    }
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: currentUrl === '/dashboard/creative-library' ? selectedChannel : 'null',
      eventName: 'Ad Details Window View',
      // brandName: adsData?.additional_info?.page_name || adsData?.advertiser,
      sourceName: 'Clicked Next Icon',
    }
    accountsTracking(trackingPayload)
  }

  const handleCategoryClickForAll = categryData => {
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: selectedChannel,
      eventName: 'Top Brands Bar Click',
      sourceName: 'Clicked Brands Bar',
      brandSelected: categryData?.brand_name,
    }
    accountsTracking(trackingPayload)
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })

    setFilteredDiscoverDataAllForMeta(
      filteredDiscoverDataAllForMeta.filter(
        item => item?.additional_info?.page_name === categryData?.brand_name
      )
    )
    setFilteredDiscoverDataAllForGoogle(
      filteredDiscoverDataAllForGoogle.filter(
        item => item?.additional_info?.page_name === categryData?.brand_name
      )
    )
    dispatch({
      type: BRAND_TAG_FOR_ALL,
      payload: brandsTagForAllOrignal?.discover_ads.filter(
        item => item?.brand_name === categryData?.brand_name
      ),
    })
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
    dispatch({ type: TRIGGER_SEARCH, payload: true })
    dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
    dispatch({ type: BRAND_CHOSEN, payload: true })
  }
  const handleCategoryClickForMeta = categryData => {
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: selectedChannel,
      eventName: 'Top Brands Bar Click',
      sourceName: 'Clicked Brands Bar',
      brandSelected: categryData?.brand_name,
    }
    accountsTracking(trackingPayload)
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
    setFilteredDiscoverData(
      filteredDiscoverData.filter(
        item => item?.additional_info?.page_name === categryData?.brand_name
      )
    )

    dispatch({
      type: BRAND_TAG_FOR_META,
      payload: brandsTagForMetaOriginal.filter(
        item => item?.brand_name === categryData?.brand_name
      ),
    })
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
    dispatch({ type: TRIGGER_SEARCH, payload: true })
    dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
    dispatch({ type: BRAND_CHOSEN, payload: true })
  }
  const handleCategoryClickForGoogle = categryData => {
    const trackingPayload = {
      pageName: currentUrl,
      userStatus: 'Free',
      currentTab: selectedChannel,
      eventName: 'Top Brands Bar Click',
      sourceName: 'Clicked Brands Bar',
      brandSelected: categryData?.brand_name,
    }
    accountsTracking(trackingPayload)
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: true })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
    setFilteredDiscoverData(
      filteredDiscoverData.filter(
        item => item?.additional_info?.page_name === categryData?.brand_name
      )
    )
    dispatch({
      type: BRAND_TAG_FOR_GOOGLE,
      payload: brandsTagForGoogleOriginal?.filter(
        item => item?.brand_name === categryData?.brand_name
      ),
    })
    dispatch({ type: PAGE_ID_AND_ADVERTISER_ID, payload: categryData })
    dispatch({ type: TRIGGER_SEARCH, payload: true })
    dispatch({ type: INPUT_VALUES, payload: categryData?.brand_name })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: categryData?.brand_name })
    dispatch({ type: BRAND_CHOSEN, payload: true })
  }

  const handleCheckboxCount = () => {
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    if (selectedChannel === 'All') {
      const activeCountLoopForGoogle = adsPreviewDataForGoogle.filter(
        item =>
          item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
      ).length

      const activeCountLoopForMeta = adsPreviewDataForMeta.filter(
        item =>
          item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
      ).length

      const inactiveCountLoopForGoogle = adsPreviewDataForGoogle.filter(
        item =>
          item?.additional_info?.is_active === false || item?.additional_info?.is_active === 'false'
      ).length
      const inactiveCountLoopForMeta = adsPreviewDataForMeta.filter(
        item =>
          item?.additional_info?.is_active === false || item?.additional_info?.is_active === 'false'
      ).length
      const totalActiveCount = activeCountLoopForGoogle + activeCountLoopForMeta
      const totalInActiveCount = inactiveCountLoopForGoogle + inactiveCountLoopForMeta
      setActiveCount(totalActiveCount)
      setInActiveCount(totalInActiveCount)
    } else {
      const activeCountLoop = adsPreviewData.filter(
        item =>
          item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
      ).length

      const inactiveCountLoop = adsPreviewData.filter(
        item =>
          item?.additional_info?.is_active === false || item?.additional_info?.is_active === 'false'
      ).length
      setActiveCount(activeCountLoop)
      setInActiveCount(inactiveCountLoop)
    }
  }
  useEffect(() => {
    handleCheckboxCount()
    // eslint-disable-next-line
  }, [adsPreviewData, adsPreviewDataForGoogle, adsPreviewDataForMeta])
  const options = [
    { label: 'Active', value: 'Active', count: isLiveSearch ? activeCount : activeCountRedux },
    {
      label: 'Inactive',
      value: 'Inactive',
      count: isLiveSearch ? inactiveCount : InActiveCountRedux,
    },
  ]
  const handleUpdateCheckboxStatus = data => {
    if (data.length <= 0) {
      dispatch({ type: ADS_NO_DATA, payload: true })
    } else {
      dispatch({ type: ADS_NO_DATA, payload: false })
    }
  }
  const location = useSelector(state => state?.discoverAds?.location)
  const adsFormats = useSelector(state => state?.discoverAds?.formats)

  const handleCheckboxChange = selectedValues => {
    if (selectedChannel === 'All') {
      let filteredValuesForGoogle = []
      let filteredValuesForMeta = []
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      if (checkedValues?.length === 0) {
        return
      }
      if (selectedValues?.length > 0) {
        dispatch({ type: CHECKED_VALUES, payload: selectedValues })
      }
      if (checkedValues?.length === 2) {
        filteredValuesForGoogle = adsPreviewDataForGoogle
        setFilteredDiscoverDataAllForGoogle(filteredValuesForGoogle)
        filteredValuesForMeta = adsPreviewDataForMeta
        setFilteredDiscoverDataAllForMeta(filteredValuesForMeta)
      } else if (checkedValues[0] === 'Active') {
        filteredValuesForGoogle = adsPreviewDataForGoogle.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        )
        setFilteredDiscoverDataAllForGoogle(filteredValuesForGoogle)
        filteredValuesForMeta = adsPreviewDataForMeta.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        )
        setFilteredDiscoverDataAllForMeta(filteredValuesForMeta)
      } else if (checkedValues[0] === 'Inactive') {
        filteredValuesForGoogle = adsPreviewDataForGoogle.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        )
        setFilteredDiscoverDataAllForGoogle(filteredValuesForGoogle)
        filteredValuesForMeta = adsPreviewDataForMeta.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        )
        setFilteredDiscoverDataAllForMeta(filteredValuesForMeta)
      }
      const combinedResult = [...filteredValuesForMeta, ...filteredValuesForGoogle]
      handleUpdateCheckboxStatus(combinedResult)
    } else {
      let filteredValues = []
      dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
      if (checkedValues?.length === 0) {
        return
      }
      if (selectedValues?.length > 0) {
        dispatch({ type: CHECKED_VALUES, payload: selectedValues })
      }
      if (checkedValues?.length === 2) {
        filteredValues = adsPreviewData
        setFilteredDiscoverData(filteredValues)
      } else if (checkedValues[0] === 'Active') {
        filteredValues = adsPreviewData.filter(
          item =>
            item?.additional_info?.is_active === true || item?.additional_info?.is_active === 'true'
        )
        setFilteredDiscoverData(filteredValues)
      } else if (checkedValues[0] === 'Inactive') {
        filteredValues = adsPreviewData.filter(
          item =>
            item?.additional_info?.is_active === false ||
            item?.additional_info?.is_active === 'false'
        )
        setFilteredDiscoverData(filteredValues)
      }
      handleUpdateCheckboxStatus(filteredValues)
    }
  }

  useEffect(() => {
    if (checkedValues.length > 0) {
      handleCheckboxChange()
    }
    // eslint-disable-next-line
  }, [checkedValues, adsPreviewData])

  const fetchMoreData = async () => {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    try {
      setNewDataSpin(true)
      let response = []
      let formattedData = []
      let formattedRawData = []
      if (selectedChannel === 'Meta') {
        if (isMetaStatus) {
          response = await RequestService.get(
            `/ads-preview/competitors-prod/?next_url_meta=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isMetaStatus) {
          response = await RequestService.get(`${adsPagination}`)
        }
        formattedRawData = response?.data.result?.facebook_ads
        if (Array.isArray(formattedRawData?.data)) {
          formattedData = formattedRawData?.data?.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad.advertiser_id ||
                brand.meta_page_id === ad.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        if (formattedData?.length > 0) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData,
          })
        }

        if (formattedRawData.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedRawData.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      } else if (selectedChannel === 'Google') {
        if (isGoogleStatus) {
          response = await RequestService.get(
            `/ads-preview/competitors-prod/?next_url=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isGoogleStatus) {
          response = await RequestService.get(`${adsPagination}`)
        }

        formattedRawData = response?.data.result?.facebook_ads
        if (Array.isArray(formattedRawData?.data)) {
          formattedData = formattedRawData?.data?.map(ad => {
            const isFollowed = followedBrandsList.some(
              brand =>
                brand.google_advertiser_id === ad.advertiser_id ||
                brand.meta_page_id === ad.additional_info?.page_id
            )

            return {
              ...ad,
              is_followed: isFollowed,
            }
          })
        }
        if (formattedData?.length > 0) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData,
          })
        }

        if (response?.data.result?.facebook_ads?.serpapi_pagination?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data.result?.facebook_ads?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else if (response?.data.result?.facebook_ads?.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data.result?.facebook_ads?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      }
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
    } catch (error) {
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
    }
  }
  const fetchMoreDataForGoogle = async () => {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    try {
      setNewDataSpin(true)
      let response = []
      let formattedData = []
      let formattedRawData = []

      // ------------------------For Goolge--------------------

      if (isGoogleStatus) {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?next_url=${encodeURIComponent(
            adsPaginationForGoogle
          )}&channels=[Google]&search_term=${searchKeyword}`
        )
      } else if (!isGoogleStatus) {
        response = await RequestService.get(`${adsPaginationForGoogle}`)
      }

      formattedRawData = response?.data.result?.facebook_ads
      if (Array.isArray(formattedRawData?.data)) {
        formattedData = formattedRawData?.data?.map(ad => {
          const isFollowed = followedBrandsList.some(
            brand =>
              brand.google_advertiser_id === ad.advertiser_id ||
              brand.meta_page_id === ad.additional_info?.page_id
          )

          return {
            ...ad,
            is_followed: isFollowed,
          }
        })
      }
      if (formattedData?.length > 0) {
        dispatch({
          type: ADS_DATA_FOR_GOOGLE,
          payload: formattedData,
        })
      }

      if (response?.data.result?.facebook_ads?.serpapi_pagination?.next) {
        dispatch({
          type: ADS_PAGINATION_FOR_GOOGLE_ALL_CHANNEL,
          payload: response?.data.result?.facebook_ads?.serpapi_pagination?.next,
        })
        dispatch({
          type: HAS_MORE_FOR_GOOGLE_ALL_CHANNEL,
          payload: true,
        })
      } else if (response?.data.result?.facebook_ads?.paging?.next) {
        dispatch({
          type: ADS_PAGINATION_FOR_GOOGLE_ALL_CHANNEL,
          payload: response?.data.result?.facebook_ads?.paging?.next,
        })
        dispatch({
          type: HAS_MORE_FOR_GOOGLE_ALL_CHANNEL,
          payload: true,
        })
      } else {
        dispatch({
          type: HAS_MORE_FOR_GOOGLE_ALL_CHANNEL,
          payload: false,
        })
      }

      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
    } catch (error) {
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
    }
  }
  const fetchMoreDataForMeta = async () => {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    dispatch({ type: TRIGGER_UPDATE_FOLLOW_BRANDS, payload: false })
    try {
      setNewDataSpin(true)
      let response = []
      let formattedData = []
      let formattedRawData = []

      // ----------------------For Meta--------------------
      if (isMetaStatus) {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?next_url_meta=${encodeURIComponent(
            adsPaginationForMeta
          )}&channels=[Meta]&search_term=${searchKeyword}`
        )
      } else if (!isMetaStatus) {
        response = await RequestService.get(`${adsPaginationForMeta}`)
      }
      formattedRawData = response?.data.result?.facebook_ads
      if (Array.isArray(formattedRawData?.data)) {
        formattedData = formattedRawData?.data?.map(ad => {
          const isFollowed = followedBrandsList.some(
            brand =>
              brand.google_advertiser_id === ad.advertiser_id ||
              brand.meta_page_id === ad.additional_info?.page_id
          )

          return {
            ...ad,
            is_followed: isFollowed,
          }
        })
      }
      if (formattedData?.length > 0) {
        dispatch({
          type: ADS_DATA_FOR_META,
          payload: formattedData,
        })
      }

      if (formattedRawData.paging?.next) {
        dispatch({
          type: ADS_PAGINATION_FOR_META_ALL_CHANNEL,
          payload: formattedRawData.paging?.next,
        })
        dispatch({
          type: HAS_MORE_FOR_META_ALL_CHANNEL,
          payload: true,
        })
      } else {
        dispatch({
          type: HAS_MORE_FOR_META_ALL_CHANNEL,
          payload: false,
        })
      }

      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
    } catch (error) {
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
    }
  }

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
      setShowBackButton(scrollLeft > 0)
      setShowNextButton(scrollLeft < scrollWidth - clientWidth - 1)
    }
  }

  useEffect(() => {
    checkScrollPosition()
    window.addEventListener('resize', checkScrollPosition)
    return () => window.removeEventListener('resize', checkScrollPosition)
  }, [adsPreviewData]) // Re-check when data changes

  const scroll = direction => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 2
      containerRef.current.scrollBy({
        left: direction === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      })
    }
  }
  const clearState = () => {
    dispatch({ type: TOGGLE_SPLIT_VIEW, payload: true })
    dispatch({ type: DISABLE_SPLIT_VIEW, payload: false })
    dispatch({ type: LIVE_SEARCH, payload: false })
    dispatch({ type: HAS_MORE, payload: false })
    dispatch({
      type: HAS_MORE_FOR_GOOGLE_ALL_CHANNEL,
      payload: false,
    })
    dispatch({
      type: HAS_MORE_FOR_META_ALL_CHANNEL,
      payload: false,
    })
    dispatch({ type: INPUT_VALUES, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: BRAND_TAG_FOR_ALL, payload: brandsTagForAllOrignal.discover_ads })
    dispatch({ type: BRAND_TAG_FOR_GOOGLE, payload: brandsTagForGoogleOriginal })
    dispatch({ type: BRAND_TAG_FOR_META, payload: brandsTagForMetaOriginal })
    dispatch({ type: CLEAR_BRANDS_TAG_FLAG, payload: false })
  }

  const ButtonContainer = styled.span`
    position: fixed;
    bottom: 32px;
    right: 32px;
    transform: translate(50%, 50%);
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 1;
    background: #f16434;
    border-radius: 5rem;
    // transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
    display: 'flex';
    // &:hover {
    //   opacity: 1;
    // }
  `

  const scrollToTop = () => {
    const targetDiv = document?.getElementById('report')

    // if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' })
    setShowButton(false)
    // }
  }

  window.addEventListener('wheel', event => {
    const position = document?.getElementById('scrollableDiv')
    const scrollPosition = position?.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  window.addEventListener('keydown', event => {
    const position = document?.getElementById('scrollableDiv')
    const scrollPosition = position?.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  const handleBrandTagsData = async () => {
    try {
      const response = await axiosInstance.get('/save/brand-tags/')
      dispatch({ type: BRANDS_TAGS_DATA, payload: response?.data })
      const filteredBrandsForMeta = response?.data?.discover_ads
        .filter(entry => entry.meta_page_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          meta_page_id: entry?.meta_page_id,
          page_profile_logo: entry?.page_profile_logo,
        }))

      const filteredBrandsForGoogle = response?.data?.discover_ads
        .filter(entry => entry.google_advertiser_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          google_advertiser_id: entry?.google_advertiser_id,
          page_profile_logo: entry?.page_profile_logo,
        }))
      dispatch({ type: BRANDS_TAGS_FOR_META, payload: filteredBrandsForMeta })
      dispatch({ type: BRANDS_TAGS_FOR_GOOGLE, payload: filteredBrandsForGoogle })
    } catch (error) {
      // console.log('🚀 ~ DriveSuccess ~ error:', error)
    }
  }

  useEffect(() => {
    if (brandsTagForAllOrignal.length <= 0) {
      handleBrandTagsData()
    }
    // eslint-disable-next-line
  }, [brandsTagForAllOrignal])
  const skeletonArray = Array.from({ length: 8 })
  const getSearchInputType = value => {
    if (!value) {
      return ''
    }
    // URL pattern - checks for domain-like patterns
    const urlPattern = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}/

    // Check if it's a brand from our options
    const isBrand = () => {
      if (selectedChannel === 'Meta') {
        return metaOptionPermanent.some(option => option.value === value)
        // eslint-disable-next-line
      } else if (selectedChannel === 'Google') {
        return googleOptionPermanent.some(option => option.value === value)
        // eslint-disable-next-line
      } else if (selectedChannel === 'All') {
        return allOptionPermanent.some(option => option.value === value)
      }
      return false
    }

    // First check if it's a URL
    if (urlPattern.test(value)) {
      return 'URL'
    }
    // Then check if it's a brand
    // eslint-disable-next-line
    else if (isBrand(value)) {
      return 'Brand'
    }
    // Otherwise it's a keyword
    return 'Keyword'
  }
  return (
    <div id="scrollDiv">
      <Text className={metaStyles.heading}>Discover Ads</Text>
      <div
        style={{
          marginTop: 20,
          position: 'sticky',
          top: '-1px',
          zIndex: 99,
        }}
      >
        <SearchBar />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Text style={{ fontSize: 14, marginRight: 9, color: '#939395' }}>Brands:</Text>
          {showBackButton && (
            <LeftCircleOutlined
              onClick={() => scroll('back')}
              style={{
                cursor: 'pointer',
                marginRight: 10,
                fontSize: '27px',
                color: '#f16434',
              }}
            />
          )}
          <div
            ref={containerRef}
            style={{
              display: 'flex',
              gap: 16,
              overflowX: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              flex: 1,
            }}
            onScroll={checkScrollPosition}
          >
            {
              // eslint-disable-next-line
              selectedChannel === 'All' ? (
                <>
                  {' '}
                  {brandsTagForAll?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClickForAll(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' ? (
                <>
                  {brandsTagForMeta?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClickForMeta(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : selectedChannel === 'Google' ? (
                <>
                  {brandsTagForGoogle?.map(data => (
                    <CategoryButton
                      categryData={data}
                      onClick={() => handleCategoryClickForGoogle(data)}
                      key={data.name}
                      clearState={clearState}
                    />
                  ))}
                </>
              ) : (
                ''
              )
            }
          </div>

          {showNextButton && (
            <div>
              <RightCircleOutlined
                onClick={() => scroll('next')}
                style={{
                  cursor: 'pointer',
                  marginLeft: 10,
                  marginRight: 30,
                  color: '#f16434',
                  fontSize: '27px',
                  marginTop: '2px',
                }}
              />
            </div>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: 14, marginRight: 9, color: '#939395' }}>Filters: </span>
          <div
            style={{
              background: 'white',
              padding: '5px 18px',
              border: ' 1px solid var(--primary-tint-raisin-black-10, #E9E9EA)',
            }}
          >
            <Checkbox.Group
              options={options.map(option => ({
                label: (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span style={{ color: '#26282C', fontSize: 12 }}>{option.label}</span>
                    <div
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '3.801px 5.068px',
                        fontSize: '12px',
                        color: '#939395',
                        borderRadius: '23.441px',
                        border: '0.634px solid #EBEBEC',
                        background: '#FFF',
                      }}
                    >
                      {option.count}
                    </div>
                  </div>
                ),
                value: option.value,
              }))}
              value={checkedValues}
              // onChange={handleCheckboxChange}
              onChange={e => {
                handleCheckboxChange(e)
                const searchInputType = getSearchInputType(searchKeyword)
                let trackingPayload = ''
                if (selectedChannel === 'All') {
                  trackingPayload = {
                    pageName: currentUrl,
                    userStatus: 'Free',
                    currentTab: selectedChannel,
                    eventName: 'Ads Filter Click',
                    sourceName: 'Entered Tag In Input Box',
                    searchInput: searchInputType,
                    searchInputTerm: searchKeyword,
                    selectedLocation: 'Anywhere',
                    selectedLanguage: 'All',
                    selectedFormat: 'All',
                    adFilter: e?.length === 2 ? 'Active ,Inactive' : e[0],
                    count:
                      // eslint-disable-next-line
                      e?.length === 2
                        ? options[0]?.count + options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Inactive'
                        ? options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Active'
                        ? options[0]?.count
                        : '',
                  }
                } else if (selectedChannel === 'Meta') {
                  trackingPayload = {
                    pageName: currentUrl,
                    userStatus: 'Free',
                    currentTab: selectedChannel,
                    eventName: 'Ads Filter Click',
                    sourceName: 'Entered Tag In Input Box',
                    searchInput: searchInputType,
                    searchInputTerm: searchKeyword,
                    selectedLanguage: !trackingLocation ? 'All' : trackingLocation,
                    selectedFormat: !adsFormats ? 'All' : adsFormats,
                    adFilter: e?.length === 2 ? 'Active ,Inactive' : e[0],
                    count:
                      // eslint-disable-next-line
                      e?.length === 2
                        ? options[0]?.count + options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Inactive'
                        ? options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Active'
                        ? options[0]?.count
                        : '',
                  }
                } else if (selectedChannel === 'Google') {
                  trackingPayload = {
                    pageName: currentUrl,
                    userStatus: 'Free',
                    currentTab: selectedChannel,
                    eventName: 'Ads Filter Click',
                    sourceName: 'Entered Tag In Input Box',
                    searchInput: searchInputType,
                    searchInputTerm: searchKeyword,
                    selectedLocation: !trackingLocation ? 'Anywhere' : trackingLocation,
                    selectedFormat: !adsFormats ? 'All' : adsFormats,
                    adFilter: e?.length === 2 ? 'Active ,Inactive' : e[0],
                    count:
                      // eslint-disable-next-line
                      e?.length === 2
                        ? options[0]?.count + options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Inactive'
                        ? options[1]?.count
                        : // eslint-disable-next-line
                        e[0] === 'Active'
                        ? options[0]?.count
                        : '',
                  }
                }

                accountsTracking(trackingPayload)
              }}
              style={{ display: 'flex', gap: 16 }}
            />
          </div>
        </div>
      </div>
      {
        // eslint-disable-next-line
        loading ? (
          <div style={{ textAlign: 'center' }}>
            {/* <Spin /> */}
            <div style={{ padding: '20px' }}>
              <Row gutter={[16, 16]} justify="center">
                {skeletonArray.map((_, index) => (
                  <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Card style={{ height: 300 }}>
                      <Skeleton active title={false} paragraph={{ rows: 4 }} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ) : (
          <>
            {
              // eslint-disable-next-line
              selectedChannel === 'All' ? (
                splitView ? (
                  // Split view layout
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      {/* Google content */}
                      <div>
                        {filteredDiscoverDataAllForGoogle.length > 0 && (
                          <InfiniteScroll
                            style={{ overflow: 'hidden' }}
                            dataLength={filteredDiscoverDataAllForGoogle.length}
                            next={fetchMoreDataForGoogle}
                            hasMore={hasMoreForGoogle && !brandsFilter}
                            loader={
                              newDataSpin ? (
                                <div style={{ marginTop: 150, textAlign: 'center' }}>
                                  <Spin />
                                </div>
                              ) : null
                            }
                            scrollableTarget="scrollableDiv"
                            scrollThreshold="35%"
                          >
                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
                              <Masonry columnsCount={2} gutter="20px">
                                {filteredDiscoverDataAllForGoogle?.map((item, index) => (
                                  <AdsCard
                                    // eslint-disable-next-line
                                    key={index}
                                    discoverAdsFlow
                                    adsData={item}
                                    onShowModal={() => handleShowModal(index)}
                                    onPrevious={handlePrevious}
                                    onNext={handleNext}
                                    selectedData={filteredDiscoverDataAllForGoogle[selectedIndex]}
                                    isFirst={selectedIndex === 0}
                                    isLast={
                                      selectedIndex === filteredDiscoverDataAllForGoogle.length - 1
                                    }
                                    boardsList={boardsList}
                                  />
                                ))}
                              </Masonry>
                            </ResponsiveMasonry>
                          </InfiniteScroll>
                        )}
                      </div>
                    </Col>
                    <Col span={12}>
                      {/* Meta content */}
                      {filteredDiscoverDataAllForMeta.length > 0 && (
                        <InfiniteScroll
                          style={{ overflow: 'hidden' }}
                          dataLength={filteredDiscoverDataAllForMeta.length}
                          next={fetchMoreDataForMeta}
                          hasMore={hasMoreForMeta && !brandsFilter}
                          loader={
                            newDataSpin ? (
                              <div style={{ marginTop: 150, textAlign: 'center' }}>
                                <Spin />
                              </div>
                            ) : null
                          }
                          scrollableTarget="scrollableDiv"
                          scrollThreshold="35%"
                        >
                          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
                            <Masonry columnsCount={2} gutter="20px">
                              {filteredDiscoverDataAllForMeta?.map((item, index) => (
                                <AdsCard
                                  // eslint-disable-next-line
                                  key={index}
                                  discoverAdsFlow
                                  adsData={item}
                                  onShowModal={() => handleShowModal(index)}
                                  onPrevious={handlePrevious}
                                  onNext={handleNext}
                                  selectedData={filteredDiscoverDataAllForMeta[selectedIndex]}
                                  isFirst={selectedIndex === 0}
                                  isLast={
                                    selectedIndex === filteredDiscoverDataAllForMeta.length - 1
                                  }
                                  boardsList={boardsList}
                                />
                              ))}
                            </Masonry>
                          </ResponsiveMasonry>
                        </InfiniteScroll>
                      )}
                    </Col>
                  </Row>
                ) : (
                  // Combined view layout
                  <div>
                    {filteredDiscoverDataAllForGoogle.length === 0 &&
                    filteredDiscoverDataAllForMeta.length === 0 ? (
                      <SearchResultsError />
                    ) : (
                      <InfiniteScroll
                        style={{ overflow: 'hidden' }}
                        dataLength={
                          filteredDiscoverDataAllForGoogle.length +
                          filteredDiscoverDataAllForMeta.length
                        }
                        next={() => {
                          if (hasMoreForGoogle) fetchMoreDataForGoogle()
                          if (hasMoreForMeta) fetchMoreDataForMeta()
                        }}
                        hasMore={(hasMoreForGoogle || hasMoreForMeta) && !brandsFilter}
                        loader={
                          newDataSpin ? (
                            <div style={{ marginTop: 150, textAlign: 'center' }}>
                              <Spin />
                            </div>
                          ) : null
                        }
                        scrollableTarget="scrollableDiv"
                        scrollThreshold="35%"
                      >
                        <ResponsiveMasonry
                          columnsCountBreakPoints={{
                            350: 1,
                            750: 2,
                            900: 2,
                            1400: 4,
                            1800: 4,
                            2200: 6,
                            2500: 7,
                          }}
                        >
                          <Masonry columnsCount={7} gutter="20px">
                            {[
                              ...filteredDiscoverDataAllForGoogle,
                              ...filteredDiscoverDataAllForMeta,
                            ]
                              .sort((a, b) => new Date(b.date) - new Date(a.date))
                              .map((item, index) => (
                                <AdsCard
                                  // eslint-disable-next-line
                                  key={index}
                                  discoverAdsFlow
                                  adsData={item}
                                  onShowModal={() => handleShowModal(index)}
                                  onPrevious={handlePrevious}
                                  onNext={handleNext}
                                  selectedData={
                                    [
                                      ...filteredDiscoverDataAllForGoogle,
                                      ...filteredDiscoverDataAllForMeta,
                                    ][selectedIndex]
                                  }
                                  isFirst={selectedIndex === 0}
                                  isLast={
                                    selectedIndex ===
                                    filteredDiscoverDataAllForGoogle.length +
                                      filteredDiscoverDataAllForMeta.length -
                                      1
                                  }
                                  boardsList={boardsList}
                                />
                              ))}
                          </Masonry>
                        </ResponsiveMasonry>
                      </InfiniteScroll>
                    )}
                  </div>
                )
              ) : (
                // Single channel view
                <div>
                  <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={filteredDiscoverData.length}
                    next={fetchMoreData}
                    hasMore={hasMore && !brandsFilter}
                    loader={
                      newDataSpin ? (
                        <div style={{ marginTop: 150, textAlign: 'center' }}>
                          <Spin />
                        </div>
                      ) : null
                    }
                    scrollableTarget="scrollableDiv"
                    scrollThreshold="35%"
                  >
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{
                        350: 1,
                        750: 2,
                        900: 2,
                        1400: 4,
                        1800: 4,
                        2200: 6,
                        2500: 7,
                      }}
                    >
                      <Masonry columnsCount={7} gutter="20px">
                        {filteredDiscoverData?.map((item, index) => (
                          <AdsCard
                            // eslint-disable-next-line
                            key={index}
                            discoverAdsFlow
                            adsData={item}
                            onShowModal={() => handleShowModal(index)}
                            onPrevious={handlePrevious}
                            onNext={handleNext}
                            selectedData={filteredDiscoverData[selectedIndex]}
                            isFirst={selectedIndex === 0}
                            isLast={selectedIndex === filteredDiscoverData.length - 1}
                            boardsList={boardsList}
                          />
                        ))}
                      </Masonry>
                    </ResponsiveMasonry>
                  </InfiniteScroll>
                </div>
              )
            }
          </>
        )
      }
      {
        // eslint-disable-next-line
        selectedChannel === 'All' && adsNoDataForGoogle && adsNoDataForMeta && !loading ? (
          <SearchResultsError />
        ) : (selectedChannel === 'Google' || selectedChannel === 'Meta') &&
          (adsNoDataForGoogle || adsNoDataForMeta) &&
          !loading ? (
          <SearchResultsError />
        ) : (
          ''
        )
      }

      {(filteredDiscoverData?.length > 0 ||
        filteredDiscoverDataAllForGoogle.length > 0 ||
        filteredDiscoverDataAllForMeta.length > 0) &&
      showButton ? (
        <ButtonContainer onClick={scrollToTop}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </ButtonContainer>
      ) : (
        ''
      )}
    </div>
  )
}

export default DiscoverAdsFeature
