// Map routes to their display names
const routeToPageName = {
  '/dashboard/creative-library': 'Creative Lbrary',
  '/dashboard/save-ads': 'Saved Ads',
  '/dashboard/boards': 'Boards',
  '/dashboard/brands': 'Followed Brands',
  '/dashboard/calculator': 'Calculator',
  '/dashboard/benchmarks': 'Benchmarks',
  '/dashboard/learning-center': 'Learning Center',
  '/dashboard/consulting': 'Consulting Services',
  '/dashboard': 'Reports',
  '/login': 'Login Page',
  '/home': 'Home Page',
  '/': 'Home Page',
  '/register': 'Sign Up',
  '/start': 'Start',
  '/dashboard/boards/detail': 'Board Ads Detail',
  '/dashboard/brands/detail': 'Followed Brands Detail',
  '/discover-ads-landing-page': 'Discover Ads Landing Page',
  '/learning-center/explore-more': 'Explore More',
}

// Map routes to their section names
const routeToSectionName = {
  '/dashboard/creative-library': 'Creative Library',
  '/dashboard/save-ads': 'Creative Library',
  '/dashboard/boards': 'Creative Library',
  '/dashboard/brands': 'Creative Library',
  '/dashboard/calculator': 'Calculator',
  '/dashboard/benchmarks': 'Benchmarks',
  '/dashboard/learning-center': 'Learning Center',
  '/dashboard/consulting': 'Consulting Services',
  '/learning-center/explore-more': 'Explore More',
  '/dashboard': 'Reports',
}

// Get the page name for a given route
export const getPageNameFromRoute = route => {
  // Check if the route exists in our mapping
  if (routeToPageName[route]) {
    return routeToPageName[route]
  }

  // For report sections, use the section name
  if (route?.startsWith('/dashboard/reports/')) {
    const reportSection = route.split('/').pop()
    return reportSection.charAt(0).toUpperCase() + reportSection.slice(1)
  }

  // Default to Dashboard if no match
  return route
}

// Get the section name for a given route
export const getSectionNameFromRoute = route => {
  // Check if the route exists in our mapping
  if (routeToSectionName[route]) {
    return routeToSectionName[route]
  }

  // For report sections, return Reports
  if (route?.startsWith('/dashboard/reports/')) {
    return 'Reports'
  }

  // Default to Dashboard if no match
  return route
}

// Get the report section name if applicable
export const getReportSectionFromRoute = route => {
  if (route?.startsWith('/dashboard/reports/')) {
    return route.split('/').pop()
  }
  return undefined
}
