import React, { useEffect, useState } from 'react'
import { Form, Input, Button } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field, ErrorMessage } from 'formik'
import Text from '@components/Text/text'
import { USER_EMIAL, TRACKING_PAGE_NAME } from '@utils/constants'
import * as Yup from 'yup'
import { notify } from '@utils/notify'
import { useTranslation } from 'react-i18next'
import { updateLocalStorage } from '@utils/helperFunctions'
import { axiosInstance } from '@services/requests'
import accountsTracking from '@services/accountsTracking'
import { updateUserData } from '../../../store/actions'
import Register from '../Register'
import ResetPassword from '../ResetPassword'
import styles from './EmailLoginPage.module.css'
import UnVerifiedModal from '../UnVerfiedModal'
import VerificationEmailModal from '../verificationEmailModal'

const validationSchema = Yup.object({
  email: Yup.string().email('Enter a valid email.').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const EmailLoginPage = ({ mainFlow, register }) => {
  const { currentUrl } = useSelector(state => state.AccountsTrackingReducer)
  const session = localStorage.getItem('np_ads_grader:session')
  const parsedData = JSON.parse(session)
  const provider = parsedData?.user
  let isGooglePresent = false
  if (provider?.social_accounts) {
    isGooglePresent = provider?.social_accounts.some(account => account?.provider === 'google')
  }
  const [email, setEmail] = useState()
  const [UnVerifiedUserModal, setUnVerifiedUserdModal] = useState(false)
  const [verificationEmail, setVerificationEmail] = useState(false)
  const history = useHistory()
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [registerFlag, setRegisterFlag] = useState(register ?? false)
  // eslint-disable-next-line
  const [loginFlag, setLoginFlag] = useState(register ? false : true)
  const [forgetPasswordFlag, setForgetPasswordFlag] = useState(false)

  const handleNavigation = target => {
    setRegisterFlag(target === 'register')
    setLoginFlag(target === 'login')
    setForgetPasswordFlag(target === 'forgetPassword')
    if (target === 'register' || target === 'login') {
      const trackingPayload = {
        pageName: 'Home Page',
        eventName: target === 'register' ? 'Sign Up Page View' : 'Sign In Page View',
        sourceName: target === 'register' ? 'Clicked Register button' : 'Clicked Login button',
      }
      accountsTracking(trackingPayload)
    }
    dispatch({ type: TRACKING_PAGE_NAME, payload: 'Home Page' })
  }

  const handleClose = () => {
    setUnVerifiedUserdModal(false)
  }
  const handleCloseverificationEmail = () => {
    setVerificationEmail(false)
  }
  useEffect(() => {
    if (mainFlow) {
      localStorage.setItem('domain_flow', true)
    }
    // eslint-disable-next-line
  }, [])
  const trackingPageName = useSelector(state => state?.AccountsTrackingReducer?.trackingPageName)
  const isNewUser = datJoined => {
    if (!datJoined) return false
    const now = new Date()
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000)

    return new Date(datJoined) >= fiveMinutesAgo
  }
  return (
    <>
      {loginFlag && (
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            dispatch({ type: USER_EMIAL, payload: values.email })
            try {
              setIsLoading(true)
              const data = { email: values.email, password: values.password }
              const res = await axiosInstance.post('/accounts/email-login/', data)
              const dateJoined = res.data?.user?.profile?.date_joined
              const newUser = isNewUser(dateJoined)
              const trackingPayload = {
                pageName: trackingPageName,
                eventName: newUser ? 'Sign Up Success' : 'Sign In Success',
                sourceName: 'Clicked Login Button',
                signInMethod: 'Email',
                userStatus: 'Free',
                authEmail: res.data?.user?.email,
              }
              setIsLoading(false)
              accountsTracking(trackingPayload)
              if (res.status === 200) {
                const userData = { ...res.data, isGoogleUser: false }
                updateLocalStorage(userData)
                dispatch(updateUserData(userData))
                if (!shareBoardLink) {
                  if (!res?.data?.user?.is_lead_save) {
                    history.push('/lead-form')
                  } else {
                    // eslint-disable-next-line
                    if (isGooglePresent && mainFlow) {
                      history.push('/dashboard')
                    } else {
                      history.push('/dashboard/creative-library')
                    }
                  }
                } else {
                  history.push('/dashboard/boards')
                }
                notify(t('notification.loginSuccess'), 'success')
              }
            } catch (e) {
              setIsLoading(false)
              if (
                e?.response?.status === 400 &&
                Array.isArray(e?.response?.data?.error) &&
                e?.response?.data?.error.length > 0 &&
                e?.response?.data?.error[0] === 'Invalid email or password.'
              ) {
                setStatus('Login failed: Invalid email, password, or both')
              } else if (
                e?.response?.status === 400 &&
                e?.response?.data?.non_field_errors[0] === 'Invalid email or password.'
              ) {
                setStatus('Login failed: Invalid email, password, or both')
              } else if (
                e?.response?.status === 400 &&
                e?.response?.data?.non_field_errors[0] ===
                  'Your account is not active. Please verify your email or contact support.'
              ) {
                setUnVerifiedUserdModal(true)
              } else if (
                e?.response?.status === 400 &&
                e?.response?.data?.non_field_errors[0] ===
                  'A verification email has already been sent. Please check your inbox.'
              ) {
                setVerificationEmail(true)
              } else if (e?.response?.status === 500) {
                notify(t('notification.internalServer'), 'error')
              } else {
                notify(t('notification.somethingWentWrong'), 'error')
              }
            }
          }}
        >
          {({ handleSubmit, errors, touched, status }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              {/* Email Field */}
              <Form.Item
                label={
                  // eslint-disable-next-line
                  <label htmlFor="email" className={styles.inputHeader}>
                    Email <span className={styles.requiredAsterisk}>*</span>
                  </label>
                }
              >
                <Field
                  id="email" // Correctly links input with label
                  name="email"
                  as={Input}
                  placeholder="Enter your email"
                  className={errors.email && touched.email ? styles.errorInput : ''}
                />
                <ErrorMessage name="email">
                  {msg => <div className={styles.errorMessage}>{msg}</div>}
                </ErrorMessage>
              </Form.Item>

              {/* Password Field */}
              <Form.Item
                label={
                  // eslint-disable-next-line
                  <label htmlFor="password" className={styles.inputHeader}>
                    Password <span className={styles.requiredAsterisk}>*</span>
                  </label>
                }
              >
                <Field
                  id="password" // Correctly links input with label
                  name="password"
                  as={Input.Password}
                  placeholder="Enter your password"
                  className={errors.password && touched.password ? styles.errorInput : ''}
                />
                <ErrorMessage name="password">
                  {msg => <div className={styles.errorMessage}>{msg}</div>}
                </ErrorMessage>
                {status && <div className={styles.errorMessage}>{status}</div>}
              </Form.Item>

              {/* Forgot Password */}
              <div style={{ textAlign: 'right' }}>
                <Text
                  className={styles.forgetPassword}
                  onClick={() => handleNavigation('forgetPassword')}
                >
                  Forgot Password ?
                </Text>
              </div>

              {/* Login Button */}
              <div style={{ marginTop: 8 }}>
                <Form.Item>
                  <Button
                    loading={isLoading}
                    className={styles.searchButton}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    LOGIN
                  </Button>
                </Form.Item>
              </div>

              {/* Register Link */}
              <div style={{ textAlign: 'center' }}>
                <Text size={14}>
                  Don&apos;t have an account?{' '}
                  <span
                    role="button"
                    tabIndex="0"
                    className={styles.registerHere}
                    onClick={() => handleNavigation('register')}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleNavigation('register')
                      }
                    }}
                  >
                    Register here
                  </span>
                </Text>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {registerFlag && <Register onNavigate={handleNavigation} />}
      {forgetPasswordFlag && <ResetPassword onNavigate={handleNavigation} />}
      {UnVerifiedUserModal && (
        <UnVerifiedModal visible={UnVerifiedUserModal} onClose={handleClose} />
      )}
      {verificationEmail && (
        <VerificationEmailModal
          visible={verificationEmail}
          onClose={handleCloseverificationEmail}
          email={email}
        />
      )}
    </>
  )
}

export default EmailLoginPage
