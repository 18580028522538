import React from 'react'
import { Button, Col, Row } from 'antd'
import Text from '@components/Text/text'
import copyIcon from '@images/DiscoverAds/Modal/DetailsCard/Copy to CSV - Orange.png'
import downloadIcon from '@images/DiscoverAds/Modal/DetailsCard/download.png'
import viewIcon from '@images/DiscoverAds/Modal/DetailsCard/view.png'
import thumbnailIcon from '@images/DiscoverAds/Modal/DetailsCard/thumbnail.png'
import { PlusOutlined } from '@ant-design/icons'
import accountsTracking from '@services/accountsTracking'
import styles from './action.module.css'

const Actions = ({ handleSignUpModal, setSourceName, setPageName, setEventName }) => {
  const temp = 0
  return (
    <div>
      <Text style={{ fontSize: 14, color: '#67696B', marginTop: 24 }}>Actions</Text>
      <div style={{ marginTop: 12 }}>
        <Row gutter={16} justify="space-between" align="middle">
          <Col xs={24} sm={12} md={8} lg={6}>
            <div>
              <Button
                className={styles.hoverButton}
                style={{
                  width: '100%',
                  border: '1px solid #DFE3E4',
                  textTransform: 'capitalize',
                  color: '#000000',
                }}
                onClick={() => {
                  handleSignUpModal()
                  setSourceName('Clicked Copy Script Button')
                  setPageName('Un Auth Discover Ads Page')
                  setEventName('Sign In Page View')
                }}
              >
                <img style={{ marginTop: -8, fontSize: 14 }} src={copyIcon} alt="icon" />
                <span style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 12 }}>Copy Script</span>
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Button
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                handleSignUpModal()
                const trackingPayload = {
                  eventName: 'Download Click',
                  pageName: 'Un Auth Discover Ads Page',
                  userStatus: 'unlogged',
                  sourceName: 'Clicked Download Button',
                  currentTab: 'All',
                  fileFormat: '.png,.txt',
                }
                accountsTracking(trackingPayload)
                setSourceName('Clicked Download Button')
                setPageName('Un Auth Discover Ads Page')
                setEventName('Sign In Page View')
              }}
            >
              <img style={{ marginTop: -8, fontSize: 14 }} src={downloadIcon} alt="icon" />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Download</span>
            </Button>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6}>
            <Button
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                handleSignUpModal()
                setSourceName('Clicked Ad Link Button')
                setPageName('Un Auth Discover Ads Page')
                setEventName('Sign In Page View')
              }}
            >
              <img style={{ marginTop: -8, fontSize: 14 }} src={viewIcon} alt="icon" />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Ad Link</span>
            </Button>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Button
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                handleSignUpModal()
                setSourceName('Clicked Thumbnail Button')
                setPageName('Un Auth Discover Ads Page')
                setEventName('Sign In Page View')
              }}
            >
              <img style={{ marginTop: -8, fontSize: 14 }} src={thumbnailIcon} alt="icon" />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Thumbnail</span>
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 12 }}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Button
              type="button"
              className={styles.hoverButton}
              style={{
                width: '100%',
                border: '1px solid #DFE3E4',
                textTransform: 'capitalize',
                color: '#000000',
              }}
              onClick={() => {
                handleSignUpModal()
                setSourceName('Clicked Save Ad Button')
                setPageName('Un Auth Discover Ads Page')
                setEventName('Sign In Page View')
              }}
            >
              <PlusOutlined style={{ color: '#F16434' }} />
              <span style={{ marginLeft: 6, fontWeight: 'bold', fontSize: 12 }}>Save Ad</span>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Actions
