import { apiRoutes } from '../config/apiRoutes'
import accountsTracking from './accountsTracking'
import api from './api'

const isNewUser = datJoined => {
  if (!datJoined) return false
  const now = new Date()
  const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000)

  return new Date(datJoined) >= fiveMinutesAgo
}
export const fetchMetaUserInfo = async (code, pageName) => {
  const { url, method } = apiRoutes.metaAuth
  try {
    const res = await api({
      url,
      method,
      data: { access_token: code },
    })
    const dateJoined = res.data?.user?.profile?.date_joined
    const newUser = isNewUser(dateJoined)
    const trackingPayload = {
      pageName,
      eventName: newUser ? 'Sign Up Success' : 'Sign In Success',
      sourceName: 'Clicked Sign in with Facebook Button',
      signInMethod: 'Google',
      userStatus: 'Free',
      authEmail: res.data?.user?.email,
    }
    accountsTracking(trackingPayload)

    return res
  } catch (e) {
    return e.response
  }
}
