import React, { useMemo, useState, useEffect } from 'react'
import { Card, Row, Col, message } from 'antd'
import {
  FileImageOutlined,
  VideoCameraOutlined,
  PictureOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { useSelector, useDispatch } from 'react-redux'
import { RequestService } from '@services/requests'
import copyIcon from '@images/AdsPreview/InsightsCopyOrange.svg'
import Text from '@components/Text/text'
import { SELECTED_MEDIA_TYPE, MEDIA_MIX_DATA, FILTERS_CLICK_FLAG } from '@utils/constants'
import styles from './styles.module.css'
import { getYAxisConfig } from '../AdCopy/yAxisIntervals'

const MediaMix = () => {
  const weeklyData = useSelector(state => state?.discoverAds?.mediaMixData || [])
  const specificBrandDetail = useSelector(state => state?.discoverAds?.specificBrandDetail)
  const selectedMediaType = useSelector(state => state?.discoverAds?.selectedMediaType || [])
  const dispatch = useDispatch()
  const activeTab = useSelector(state => state?.discoverAds?.activeTab)

  // Memoize mediaItems to prevent recreation on every render
  const mediaItems = useMemo(
    () => [
      {
        id: 1,
        type: 'image',
        count: specificBrandDetail?.images,
        icon: <FileImageOutlined />,
        color: '#4A9FFF',
      },
      {
        id: 2,
        type: 'video',
        count: specificBrandDetail?.videos,
        icon: <VideoCameraOutlined />,
        color: '#7B2BFF',
      },
      {
        id: 3,
        type: 'text',
        count: specificBrandDetail?.text,
        icon: <FileTextOutlined />,
        color: '#FF6B6B',
      },
    ],
    // eslint-disable-next-line
    [specificBrandDetail?.images, specificBrandDetail?.videos, specificBrandDetail?.text]
  )

  // Calculate max count for relative bar widths
  const maxCount = specificBrandDetail.adCount

  // Fetch landing page data for graph
  const getData = async () => {
    if (!specificBrandDetail?.pageId && !specificBrandDetail?.googleId) return

    try {
      const res = await RequestService.get(
        `/save/meta-ads-type/?meta_page_id=${specificBrandDetail?.pageId}&google_advertiser_id=${specificBrandDetail?.googleId}`
      )
      dispatch({ type: MEDIA_MIX_DATA, payload: res?.data?.result?.data?.weekly_data || [] })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    if (weeklyData?.length <= 0) {
      getData()
    }
    // eslint-disable-next-line
  }, [weeklyData])

  // Transform weekly data for the graph
  const graphData = useMemo(() => {
    if (!weeklyData || weeklyData.length === 0) return []

    // Sort data by year and week in descending order
    const sortedData = [...weeklyData].sort((a, b) => {
      if (b.year !== a.year) return b.year - a.year
      return b.week - a.week
    })

    // Convert week numbers to month data
    const monthlyData = new Map()

    sortedData.forEach(weekItem => {
      const { year, week, data } = weekItem
      if (!data) return

      // For week 0, use January 1st of that year
      // For other weeks, calculate the date properly
      const date = new Date(year, 0, 1)
      if (week > 0) {
        date.setDate(date.getDate() + (week - 1) * 7)
      }

      const month = date.toLocaleString('default', { month: 'short' })
      const actualYear = date.getFullYear()
      const monthYearKey = `${month}-${actualYear}`

      // Initialize or update monthly counts
      if (!monthlyData.has(monthYearKey)) {
        monthlyData.set(monthYearKey, {
          month,
          year: actualYear,
          monthLabel: `${month} ${actualYear}`,
          image: 0,
          video: 0,
          text: 0,
        })
      }

      // Aggregate data for this week into monthly totals
      Object.values(data).forEach(item => {
        if (item?.count) {
          const value = Array.isArray(item.value) ? item.value[0] : item.value
          if (value) {
            const monthData = monthlyData.get(monthYearKey)
            if (value === 'image and video') {
              // Split "image and video" count between both types
              const splitCount = item.count / 2
              monthData.image += splitCount
              monthData.video += splitCount
            } else {
              monthData[value] += item.count
            }
          }
        }
      })
    })

    // Convert to array format and sort by date (oldest to newest)
    return Array.from(monthlyData.entries())
      .sort((a, b) => {
        if (a[1].year !== b[1].year) return a[1].year - b[1].year
        const monthA = new Date(Date.parse(`${a[1].month} 1, ${a[1].year}`))
        const monthB = new Date(Date.parse(`${b[1].month} 1, ${b[1].year}`))
        return monthA - monthB
      })
      .map(([_, monthData]) => ({
        month: monthData.month,
        monthLabel: monthData.monthLabel,
        year: monthData.year,
        image: monthData.image,
        video: monthData.video,
        text: monthData.text,
      }))
  }, [weeklyData])

  // Calculate max value and determine Y-axis scale
  // eslint-disable-next-line
  const monthlySums = useMemo(() => {
    // eslint-disable-next-line
    return graphData.map(monthData => {
      const monthTotal = Object.keys(monthData)
        .filter(key => key !== 'month' && key !== 'monthLabel' && key !== 'year') // Exclude labels
        .reduce((monthSum, key) => monthSum + (monthData[key] || 0), 0) // Sum all counts in the month

      return {
        month: monthData.monthLabel,
        total: monthTotal,
      }
    })
  }, [graphData])
  const highestMonth = useMemo(() => {
    if (monthlySums.length === 0) return null
    return monthlySums.reduce(
      (maxMonth, currentMonth) => (currentMonth.total > maxMonth.total ? currentMonth : maxMonth),
      monthlySums[0]
    ) // Start with the first month as initial max
  }, [monthlySums])
  const yAxisConfig = useMemo(() => {
    if (!graphData.length) return getYAxisConfig(0)
    return getYAxisConfig(highestMonth?.total)
  }, [graphData?.length, highestMonth?.total])

  const handleCheckboxChange = (value, checked) => {
    let newSelectedMediaType
    if (checked) {
      newSelectedMediaType = [...selectedMediaType, value]
    } else {
      newSelectedMediaType = selectedMediaType.filter(v => v !== value)
    }
    dispatch({ type: SELECTED_MEDIA_TYPE, payload: newSelectedMediaType })
    if (newSelectedMediaType?.length === 0) {
      dispatch({ type: FILTERS_CLICK_FLAG, payload: true })
      // You can also dispatch another action or call a function here
    }
  }
  useEffect(() => {
    if (activeTab !== 'mediaMix') {
      dispatch({ type: SELECTED_MEDIA_TYPE, payload: [] })
    }
    if (activeTab !== 'mediaMix') {
      dispatch({ type: 'selected_media_type', payload: [] })
    }
    // eslint-disable-next-line
  }, [activeTab, dispatch])
  const handleCopy = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard')
      })
      .catch(() => {
        message.error('Failed to copy')
      })
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={12}>
        <Col span={12}>
          <Card className={styles.mediaMixCard}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 50,
                  left: 20,
                  bottom: 10,
                }}
                barSize={15}
                layout="horizontal"
                reverseStackOrder={false}
              >
                <CartesianGrid vertical={false} stroke="#E5E5E5" />
                <XAxis
                  dataKey="monthLabel"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666666',
                    fontSize: 11,
                    angle: 45,
                    textAnchor: 'start',
                    dy: 8,
                    dx: 8,
                  }}
                  padding={{ left: 10, right: 10 }}
                  height={100}
                  tickMargin={5}
                  interval={0}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#666666', fontSize: 12 }}
                  width={60}
                  ticks={yAxisConfig.ticks}
                  domain={yAxisConfig.domain}
                  tickFormatter={value => Math.round(value)}
                />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const hasData = payload.some(entry => entry.value > 0)
                      if (!hasData) return null

                      const sortedPayload = [...payload]
                        .filter(entry => entry.value > 0)
                        .sort((a, b) => b.value - a.value)
                        .slice(0, 10)

                      return (
                        <div
                          style={{
                            padding: '12px',
                            background: 'white',
                            border: '1px solid #e8e8e8',
                            borderRadius: '4px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                            minWidth: '200px',
                            maxWidth: '300px',
                          }}
                        >
                          <div style={{ maxHeight: '200px', overflow: 'hidden' }}>
                            {sortedPayload.map((entry, index) => (
                              <div
                                key={entry.dataKey}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom: index < sortedPayload.length - 1 ? '8px' : '0',
                                  fontSize: '13px',
                                  color: '#333',
                                }}
                              >
                                <div
                                  style={{
                                    flex: '1 1 auto',
                                    paddingRight: '16px',
                                    color:
                                      mediaItems.find(item => item.type === entry.dataKey)?.color ||
                                      '#666666',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px',
                                  }}
                                  title={entry.dataKey}
                                >
                                  {entry.dataKey.charAt(0).toUpperCase() + entry.dataKey.slice(1)}
                                </div>
                                <div
                                  style={{
                                    flex: '0 0 auto',
                                    minWidth: '40px',
                                    textAlign: 'right',
                                    color:
                                      mediaItems.find(item => item.type === entry.dataKey)?.color ||
                                      '#666666',
                                    fontWeight: '500',
                                  }}
                                >
                                  {entry.value}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                    return null
                  }}
                />
                {mediaItems.map((item, index) => (
                  <Bar
                    key={item.type}
                    yAxisId="right"
                    dataKey={item.type}
                    stackId="stack"
                    fill={item.color}
                    radius={[0, 0, 0, 0]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col span={12}>
          <Card className={styles.mediaMixCard}>
            <div style={{ height: 400 }}>
              <div className={styles.mediaList}>
                {mediaItems
                  .slice() // Create a shallow copy to avoid modifying the original array
                  .sort((a, b) => (b.count || 0) - (a.count || 0)) // Sort in descending order
                  .map(item => (
                    // eslint-disable-next-line
                    <div
                      key={item.id}
                      className={styles.mediaItem}
                      onClick={e => {
                        if (
                          !e.target.closest(`.${styles.mediaIcon}`) &&
                          !e.target.closest(`.${styles.copyButton}`) &&
                          !e.target.closest(`.${styles.mediaLabel}`) &&
                          !e.target.closest(`.${styles.count}`) &&
                          !e.target.closest(`.${styles.colorDot}`)
                        ) {
                          handleCheckboxChange(item.type, !selectedMediaType.includes(item.type))
                        }
                      }}
                    >
                      <div className={styles.leftSection}>
                        <div className={styles.checkboxContainer}>
                          <div className={styles.checkbox}>
                            <input
                              type="checkbox"
                              checked={selectedMediaType.includes(item.type)}
                              onChange={e => {
                                handleCheckboxChange(item.type, e.target.checked)
                              }}
                              onClick={e => e.stopPropagation()}
                            />
                          </div>
                          <div
                            className={styles.colorDot}
                            style={{
                              backgroundColor: item.color,
                            }}
                          />
                        </div>
                        <div className={styles.mediaLabelContainer}>
                          <Text className={styles.mediaLabel} onClick={() => handleCopy(item.type)}>
                            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                          </Text>
                          <button
                            type="button"
                            className={styles.copyButton}
                            onClick={e => {
                              e.stopPropagation()
                              handleCopy(item.type)
                            }}
                          >
                            <img src={copyIcon} alt="copy" className={styles.copyIcon} />
                          </button>
                        </div>
                        <div className={styles.count}>{item.count.toLocaleString()}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MediaMix
