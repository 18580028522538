import React from 'react'
import { Card, Typography, Row, Col } from 'antd'
import AdexplorerIcon from '@images/HomePage/KeyFeatures/AdExplorer.png'
import DownloadAdsIcon from '@images/HomePage/KeyFeatures/DownloadAd.png'
import SaveAndShareIcon from '@images/HomePage/KeyFeatures/SaveAndShare.png'
import AdInsightsIcon from '@images/HomePage/KeyFeatures/AdInsights.png'
import FollowBrandsIcon from '@images/HomePage/KeyFeatures/FollowBrand.png'
import AdAlertsIcon from '@images/HomePage/KeyFeatures/AdAlerts.png'
import BenchmarksIcon from '@images/HomePage/KeyFeatures/Benchmarks.png'
import BudgetToolsIcon from '@images/HomePage/KeyFeatures/BudgetTools.png'
import styles from './KeyFeatureCard.module.css'

const { Text, Title } = Typography

// JSON data for cards
const cardData = [
  {
    height: 312,
    backgroundColor: '#FFEFEA',
    icon: AdexplorerIcon,
    title: 'Ad Explorer',
    description: 'Search for keyword, brand or product and see ads related to that.',
    alt: 'ad-explorer-icon',
  },
  {
    height: 312,
    backgroundColor: '#E9F0FC',
    icon: DownloadAdsIcon,
    title: 'Download Ads',
    description: 'Download the ad creatives and copy you like the most.',
    alt: 'download-ad-icon',
  },
  {
    height: 312,
    backgroundColor: '#FFEFEA',
    icon: SaveAndShareIcon,
    title: 'Save & Share',
    description: 'Save ads to your collection and share with your team.',
    alt: 'save-&-share-icon',
  },
  {
    height: 312,
    backgroundColor: '#E9F0FC',
    icon: AdInsightsIcon,
    title: 'Ad Insights',
    description: 'See strength score of the ad, for how long it is alive, and other insights.',
    alt: 'ad-insights-icon',
  },
  {
    height: 312,
    backgroundColor: '#E9F0FC',
    icon: FollowBrandsIcon,
    title: 'Follow Brands',
    description: 'Follow brands and see every new ad they publish.',
    alt: 'follow-brands-icon',
    top: 25,
  },
  {
    height: 312,
    backgroundColor: '#FFEFEA',
    icon: AdAlertsIcon,
    title: 'Ad Alerts',
    description: 'Receive alerts whenever a new ad is added from a brand.',
    alt: 'ad-alerts-icon',
    top: 25,
  },
  {
    height: 312,
    backgroundColor: '#E9F0FC',
    icon: BenchmarksIcon,
    title: 'Performance Benchmarks',
    description: 'PPC, CTR and Conversion rates benchmark by industry.',
    alt: 'performance-benchmarks-icon',
    top: 25,
  },
  {
    height: 312,
    backgroundColor: '#FFEFEA',
    icon: BudgetToolsIcon,
    title: 'Budget Tools',
    description: 'Budget calculator and learning center for smarter ad spend.',
    alt: 'budget-tools-icon',
    top: 25,
  },
]

// Component for Key Feature Card
const KeyFeatureCard = () => {
  const temp = 0
  return (
    <>
      <div className={styles.keyFeatures}>
        <span style={{ color: '#F16434' }}> Key</span> Features
      </div>
      <div className={styles.keyDetial}>
        See how our creative library can inspire and inform your success
      </div>

      <Row
        gutter={[32]}
        style={{ marginTop: 64, textAlign: 'center', padding: 30, marginLeft: 0, marginRight: 0 }}
      >
        {cardData.map((card, index) => (
          // eslint-disable-next-line
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card
              style={{
                height: card.height,
                backgroundColor: card.backgroundColor,
                marginTop: card.top,
                borderRadius: '8px',
              }}
            >
              <div
                style={{
                  background: '#FFFFFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 72,
                  height: 72,
                  borderRadius: '100%',
                  // margin: '0 auto', // To center the circular div horizontally in the card
                }}
              >
                <img src={card.icon} alt={card?.alt} width={40} height={40} />
              </div>
              <Title
                style={{ marginTop: 32, fontSize: 24, textAlign: 'start', fontWeight: 'bold' }}
              >
                {card?.title}
              </Title>
              <Text
                style={{
                  width: 250,
                  display: 'block',
                  textAlign: 'start',
                  marginTop: 10,
                  fontSize: 20,
                }}
              >
                {card?.description}
              </Text>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default KeyFeatureCard
