import React, { useEffect, useState } from 'react'
import { Text } from '@components'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { axiosInstance } from '@services/requests'
import { BRANDS_TAGS_DATA, BRANDS_TAGS_FOR_META, BRANDS_TAGS_FOR_GOOGLE } from '@utils/constants'
import BrandTags from '@pages/UnauthenticatedDiscoverAds/BrandTags'
import SearchBar from '../../../UnauthenticatedDiscoverAds/SearchBar'
import styles from './DriveSuccess.module.css'
import TopAdsInRegion from '../TopAdsInRegion'

const DriveSuccess = () => {
  const homePageBrands = useSelector(state => state?.discoverAds?.homePageBrands)

  const dispatch = useDispatch()
  const handleBrandTagsData = async () => {
    try {
      const response = await axiosInstance.get('/save/brand-tags/')
      dispatch({ type: BRANDS_TAGS_DATA, payload: response?.data })
      const filteredBrandsForMeta = response?.data?.discover_ads
        .filter(entry => entry.meta_page_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          meta_page_id: entry?.meta_page_id,
          page_profile_logo: entry?.page_profile_logo,
        }))

      const filteredBrandsForGoogle = response?.data?.discover_ads
        .filter(entry => entry.google_advertiser_id) // Check if meta_page_id exists
        .map(entry => ({
          brand_name: entry?.brand_name,
          google_advertiser_id: entry?.google_advertiser_id,
          page_profile_logo: entry?.page_profile_logo,
        }))
      dispatch({ type: BRANDS_TAGS_FOR_META, payload: filteredBrandsForMeta })
      dispatch({ type: BRANDS_TAGS_FOR_GOOGLE, payload: filteredBrandsForGoogle })
    } catch (error) {
      // console.log('🚀 ~ DriveSuccess ~ error:', error)
    }
  }
  useEffect(() => {
    if (homePageBrands.length <= 0) {
      handleBrandTagsData()
    }
    // eslint-disable-next-line
  }, [homePageBrands])
  return (
    <>
      <div style={{ background: '#F5F5F5' }}>
        <div style={{ paddingTop: 66 }}>
          <Text className={styles.discoverAdsText}>
            Discover Ads That <span style={{ color: '#F16434' }}>Drive Success</span>
          </Text>
        </div>

        <div className={styles.subHeading}>
          Find both <span style={{ fontWeight: 'bold' }}>active</span> and{' '}
          <span style={{ fontWeight: 'bold' }}>inactive competitor ads</span>
          &nbsp;and draw inspiration from millions of{' '}
          <span style={{ fontWeight: 'bold' }}>top-performing ads</span> by leading brands
          worldwide.
        </div>
        <div
          style={{
            marginTop: 48,
            textAlign: 'center',
            width: 1150,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <SearchBar />

          <div
            style={{
              marginTop: 32,
              padding: '20px',
              display: 'flex',
              gap: 16,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {homePageBrands?.landing_page?.map((data, index) => (
              <BrandTags
                image={data.page_profile_logo}
                text={data.brand_name}
                isFirstRow={index < 1}
                categryData={data}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: 106 }}>
          <TopAdsInRegion />
        </div>
      </div>
    </>
  )
}
export default DriveSuccess
